import React, { useRef, useState, useEffect } from "react";
import Resizer from 'react-image-file-resizer';

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getFileSizeFromBase64, toDataUrl } from "../../../utils";
import { updateUserActivityElements, updateUserActivity } from '../../../redux/slices/userActivity';
import { domain } from "../../../services/constants";
import axios from "axios";
import { useLocation } from "react-router-dom";

export default function Upload() {
  const inputRef = useRef(null);
  const { userActivities, selectedUserActivity, selectedComponent } = useSelector((state) => state.userActivity);
  const dispatch = useDispatch();
  const [image, setImage] = useState();
  const location = useLocation();
  const [paramsid, setparamsid] = useState("");
  const [errMess, seterrMess] = useState("");

  useEffect(() => {

    // Get the current pathname from the window location
    const pathname = location.pathname;

    // Split the pathname into an array of words
    const words = pathname.split('/');

    // Get the last word (assuming the URL ends with a word)
    const lastWord = words[words.length - 1];

    // Use a regular expression to match and capture the numeric part of the URL
    const matchResult = pathname.match(/\/design\/(\d+)/);

    // Check if there is a match and retrieve the captured number
    if (matchResult && matchResult[1]) {
      const extractedNumber = parseInt(matchResult[1]);
      // console.log('Extracted Number:', extractedNumber);
      setparamsid(extractedNumber);
    }
    // Log or use the last word as needed
    // console.log('Last word:', lastWord);

  }, [location.pathname]);

  const handleClick = () => {
    inputRef.current.value = null; // reset the value of the file input
    inputRef.current.click();
  };

  const updateActivityImage = async (e) => {
    const imageFile = e.target.files[0];

    if (imageFile) {
      const fileSize = imageFile.size; // size in bytes
      const fileSizeInKB = fileSize / 1024; // size in KB
      if (fileSizeInKB > 1024) {
        seterrMess("File size cannot exceed 1 MB.");
        setTimeout(() => {
          seterrMess("");
        }, 5000); // milliseconds
        return null;
      }
    }

    if (selectedComponent.name === "Image") {
      let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
      const elementAttrib = tempElement?.elementAttributes;

      if (imageFile.type === "image/png") {
        Resizer.imageFileResizer(
          imageFile,
          1000,
          1000,
          'PNG',
          90,
          0,
          uri => {
            const image_data = {
              duonut_id: paramsid,
              name: e.target.files[0].name.replace(/\s+/g, '_'),
              uri: uri,
            }
            axios({
              method: "POST",
              url: `${domain}/duonut/upload_files`,
              headers: {
                "Content-Type": "application/json"
              },
              data: JSON.stringify(image_data),
            }).then((res) => {
              //console.log(res.data.file_url);
              tempElement = {
                ...tempElement,
                elementAttributes: {
                  ...elementAttrib,
                  url_data: {},
                  url: res.data.file_url,
                },
              };
              dispatch(
                updateUserActivityElements({
                  selectedActivityIdx: selectedUserActivity,
                  selectedElementIdx: selectedComponent.target_id,
                  element: tempElement,
                })
              );
            }).catch((e) => {
              console.log(e.message, e);
            });

            getFileSizeFromBase64(uri)
          },
          'base64'
        );

      } else if (imageFile.type === "image/gif") {
        toDataUrl(e.target.files[0], (res) => {
          let result = res;

          const image_data = {
            duonut_id: paramsid,
            name: e.target.files[0].name.replace(/\s+/g, '_'),
            uri: result,
          }
          axios({
            method: "POST",
            url: `${domain}/duonut/upload_files`,
            headers: {
              "Content-Type": "application/json"
            },
            data: JSON.stringify(image_data),
          }).then((res) => {
            //console.log(res.data.file_url);
            tempElement = {
              ...tempElement,
              elementAttributes: {
                ...elementAttrib,
                url_data: {},
                url: res.data.file_url,
              },
            };
            dispatch(
              updateUserActivityElements({
                selectedActivityIdx: selectedUserActivity,
                selectedElementIdx: selectedComponent.target_id,
                element: tempElement,
              })
            );
          }).catch((e) => {
            console.log(e.message, e);
          });

        });
      } else {
        Resizer.imageFileResizer(
          imageFile,
          1000,
          1000,
          'JPEG',
          90,
          0,
          uri => {
            const image_data = {
              duonut_id: paramsid,
              name: e.target.files[0].name.replace(/\s+/g, '_'),
              uri: uri,
            }
            axios({
              method: "POST",
              url: `${domain}/duonut/upload_files`,
              headers: {
                "Content-Type": "application/json"
              },
              data: JSON.stringify(image_data),
            }).then((res) => {
              //console.log(res.data.file_url);
              tempElement = {
                ...tempElement,
                elementAttributes: {
                  ...elementAttrib,
                  url_data: {},
                  url: res.data.file_url,
                },
              };
              dispatch(
                updateUserActivityElements({
                  selectedActivityIdx: selectedUserActivity,
                  selectedElementIdx: selectedComponent.target_id,
                  element: tempElement,
                })
              );
            }).catch((e) => {
              console.log(e.message, e);
            });

            getFileSizeFromBase64(uri)
          },
          'base64'
        );
      }

    }
    if (selectedComponent.name === "Container") {
      if (imageFile.type === "image/png") {
        Resizer.imageFileResizer(
          imageFile,
          1000,
          1000,
          'PNG',
          90,
          0,
          uri => {
            const image_data = {
              duonut_id: paramsid,
              name: e.target.files[0].name.replace(/\s+/g, '_'),
              uri: uri,
            }
            axios({
              method: "POST",
              url: `${domain}/duonut/upload_files`,
              headers: {
                "Content-Type": "application/json"
              },
              data: JSON.stringify(image_data),
            }).then((res) => {
              //console.log(res.data.file_url);
              dispatch(
                updateUserActivity({
                  backgroundImage: res.data.file_url,
                })
              );
            }).catch((e) => {
              console.log(e.message, e);
            });

            getFileSizeFromBase64(uri)
          },
          'base64'
        );
      } else if (imageFile.type === "image/gif") {
        toDataUrl(e.target.files[0], (res) => {
          let result = res;
          const image_data = {
            duonut_id: paramsid,
            name: e.target.files[0].name.replace(/\s+/g, '_'),
            uri: result,
          }
          axios({
            method: "POST",
            url: `${domain}/duonut/upload_files`,
            headers: {
              "Content-Type": "application/json"
            },
            data: JSON.stringify(image_data),
          }).then((res) => {
            //console.log(res.data.file_url);
            dispatch(
              updateUserActivity({
                backgroundImage: res.data.file_url,
              })
            );
          }).catch((e) => {
            console.log(e.message, e);
          });

        });
      } else {
        Resizer.imageFileResizer(
          imageFile,
          1000,
          1000,
          'JPEG',
          90,
          0,
          uri => {
            const image_data = {
              duonut_id: paramsid,
              name: e.target.files[0].name.replace(/\s+/g, '_'),
              uri: uri,
            }
            axios({
              method: "POST",
              url: `${domain}/duonut/upload_files`,
              headers: {
                "Content-Type": "application/json"
              },
              data: JSON.stringify(image_data),
            }).then((res) => {
              //console.log(res.data.file_url);
              dispatch(
                updateUserActivity({
                  backgroundImage: res.data.file_url,
                })
              );
            }).catch((e) => {
              console.log(e.message, e);
            });
            getFileSizeFromBase64(uri)
          },
          'base64'
        );
      }

    }
    if (selectedComponent.name === "Button") {
      let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
      const elementAttrib = tempElement?.elementAttributes;

      if (imageFile.type === "image/png") {
        Resizer.imageFileResizer(
          imageFile,
          1000,
          1000,
          'PNG',
          90,
          0,
          uri => {
            const image_data = {
              duonut_id: paramsid,
              name: e.target.files[0].name.replace(/\s+/g, '_'),
              uri: uri,
            }
            axios({
              method: "POST",
              url: `${domain}/duonut/upload_files`,
              headers: {
                "Content-Type": "application/json"
              },
              data: JSON.stringify(image_data),
            }).then((res) => {
              //console.log(res.data.file_url);
              tempElement = {
                ...tempElement,
                elementAttributes: {
                  ...elementAttrib,
                  url_data: {},
                  url: res.data.file_url,
                },
              };
              dispatch(
                updateUserActivityElements({
                  selectedActivityIdx: selectedUserActivity,
                  selectedElementIdx: selectedComponent.target_id,
                  element: tempElement,
                })
              );
            }).catch((e) => {
              console.log(e.message, e);
            });

            getFileSizeFromBase64(uri)
          },
          'base64'
        );
      } else if (imageFile.type === "image/gif") {
        toDataUrl(e.target.files[0], (res) => {
          let result = res;
          const image_data = {
            duonut_id: paramsid,
            name: e.target.files[0].name.replace(/\s+/g, '_'),
            uri: result,
          }
          axios({
            method: "POST",
            url: `${domain}/duonut/upload_files`,
            headers: {
              "Content-Type": "application/json"
            },
            data: JSON.stringify(image_data),
          }).then((res) => {
            //console.log(res.data.file_url);
            tempElement = {
              ...tempElement,
              elementAttributes: {
                ...elementAttrib,
                url_data: {},
                url: res.data.file_url,
              },
            };
            dispatch(
              updateUserActivityElements({
                selectedActivityIdx: selectedUserActivity,
                selectedElementIdx: selectedComponent.target_id,
                element: tempElement,
              })
            );
          }).catch((e) => {
            console.log(e.message, e);
          });

        });
      } else {
        Resizer.imageFileResizer(
          imageFile,
          1000,
          1000,
          'JPEG',
          90,
          0,
          uri => {
            const image_data = {
              duonut_id: paramsid,
              name: e.target.files[0].name.replace(/\s+/g, '_'),
              uri: uri,
            }
            axios({
              method: "POST",
              url: `${domain}/duonut/upload_files`,
              headers: {
                "Content-Type": "application/json"
              },
              data: JSON.stringify(image_data),
            }).then((res) => {
              //console.log(res.data.file_url);
              tempElement = {
                ...tempElement,
                elementAttributes: {
                  ...elementAttrib,
                  url_data: {},
                  url: res.data.file_url,
                },
              };
              dispatch(
                updateUserActivityElements({
                  selectedActivityIdx: selectedUserActivity,
                  selectedElementIdx: selectedComponent.target_id,
                  element: tempElement,
                })
              );
            }).catch((e) => {
              console.log(e.message, e);
            });

            getFileSizeFromBase64(uri)
          },
          'base64'
        );
      }


    }

  };

  return (
    <div className="EditorRowContainer" style={{ margin: "6px 1rem", justifyContent: "center", flexDirection: "column" }}>
      <div className="EditorRowButton" onClick={handleClick}>
        <img src="/assets/icons/upload-image.svg" style={{ marginRight: "5px" }} />
        Upload
        <input type="file" ref={inputRef}
          onChange={(e) => updateActivityImage(e)}
          accept={"image/*"}
          onClick={(e) => { e.target.value = null }} name="postImages" style={{ display: "none" }} />
      </div>
      {errMess && <div style={{ padding: "10px 0px", color: "red" }}>{errMess}</div>}
    </div>
  );
}