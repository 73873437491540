import React, { useState, useEffect } from 'react';
import './Element.css';
// import { EditText, EditTextarea } from "react-edit-text";
import "react-edit-text/dist/index.css";
import { useSelector, useDispatch } from "react-redux";
import { updateGoToSlide, } from '../../redux/slices/userActivity';
import { useLocation, } from "react-router-dom";
import { showDuonutresponse, saveQuizAnsAsync, saveQuizResponse } from '../../redux/slices/DuonutReducer';
import { showduonutData } from '../../redux/slices/DuonutReducer';
import { getOpenEndedQuestionAsync } from '../../redux/slices/AiDuonutNewReducer';
import axios from "axios";
import { domain } from "../../services/constants";
import useWindowDimensions from "../../hooks/useWindowDimensions";


var aiCount = 0;
var aiConversation = [];
const Nps = ({
  id,
  elementAttr,
  elementHoverAttr,
  // conditions,
  language,
  slideId,
}) => {
  const dispatch = useDispatch();
  const { selectedSlide } = useSelector((state) => state.userActivity);
  const { fontFamily, fontSize, fontWeight, text, fontStyle, textDecoration, textTransform, textAlign, border, color,
    lineHeight, letterSpacing, margin, padding, borderRadius, maxLength, backgroundColor, gap,
    labelFontSize, labelFontWeight, labelColor, size, required,
    openEnded, openEndedObjective, openEndedFeedbackDesired,
  } = elementAttr;

  const save_ans_response = useSelector(showDuonutresponse);
  const { viewer_display_id, viewer_nick_name, viewer_url_params } = useSelector((state) => state.duonut);
  // const { viewType } = useSelector((state) => state.duonutSettings);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const p_type = queryParams.get('type');
  const { width } = useWindowDimensions();
  const { viewType } = useSelector((state) => state.duonutSettings);

  const duonutDataMain = useSelector(showduonutData);
  const duonutData = duonutDataMain[0];
  // console.log(duonutData)
  const [inputText, setInputText] = useState("");
  const [isHovered, setIsHovered] = useState(-1);
  const [clickedNum, setClickedNum] = useState(-1);
  const [textField, setTextField] = useState('');
  const [loading, setloading] = useState(false);

  useEffect(() => {
    aiCount = 0;
    aiConversation = [];

    if (location.pathname.includes("/duonut/") && slideId === `slide-${selectedSlide}` && language !== "en") {
      fetchText(text);
    } else {
      setInputText(text);
    }
  }, [text]);

  const fetchText = async (texts) => {
    const t_text = await translateText(texts, language);
    setInputText(t_text);
  };
  const translateText = async (texts, targetLanguage) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${domain}/duonut/language_translator`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          source: "en",
          target: targetLanguage,
          query: texts,
        },
      });

      if (response.status === 200) {
        // console.log("Translation:", response);
        return response?.data.translation; // return the translated text
      } else {
        // Handle unsuccessful status codes
        return "Error: Translation failed. Please try again.";
      }
    } catch (error) {
      console.error('Error in translation API:', error);
      return "Error translating text. Please try again."; // Handle API errors gracefully
    }
  };

  const handleButtonClick = (num) => {
    if (location.pathname.includes("/duonut/") && !openEnded) {
      setClickedNum(num);

      var save_ans = {
        display_id: viewer_display_id,
        watch_id: save_ans_response[0] ? save_ans_response[0].watch_id : "",
        result: 0,
        question: text ? text : "",
        answered_text: num,
        // end: lastQue ? "1" : "0",
        nickname: viewer_nick_name,
        step: selectedSlide + 1,
        type: "Nps",
        // country: location[0] ? location[0].address?.country : "",
        // city: location[0] ? location[0].address?.state_district : "",
        // location: location[0] ? location[0].display_name : "",
        url_params: viewer_url_params,
      }
      // console.log(save_ans);
      const formData = new FormData();
      Object.keys(save_ans).forEach(key => {
        const value = typeof save_ans[key] === "object" ? JSON.stringify(save_ans[key]) : save_ans[key];
        formData.append(key, value);
      });

      dispatch(saveQuizAnsAsync(formData));

      var save_response = {
        type: "Nps",
        step: selectedSlide + 1,
        question: text ? text : "",
        answered_text: num,
      }
      dispatch(saveQuizResponse(save_response));

      var nextPage = selectedSlide + 1;
      duonutData?.user_activity[selectedSlide]?.condition?.map(node => {
        if (node.condition.length === 2) {
          if ((node.condition[0] === "equal to" && num === parseInt(node.condition[1])) ||
            (node.condition[0] === "not equal to" && num !== parseInt(node.condition[1])) ||
            (node.condition[0] === "lower than" && num < parseInt(node.condition[1])) ||
            (node.condition[0] === "greater than" && num > parseInt(node.condition[1])) ||
            (node.condition[0] === "lower or equal to" && num <= parseInt(node.condition[1])) ||
            (node.condition[0] === "greater or equal to" && num >= parseInt(node.condition[1]))) {
            nextPage = node.target - 1;
          }
        } else if (node.always) {
          nextPage = node.target - 1;
        }
      })

      setTimeout(() => {
        if (p_type === "1") {
          var act_len = duonutData.user_activity.length;
          // console.log(act_len);
          dispatch(updateGoToSlide(act_len));
          return null;
        }
        dispatch(updateGoToSlide(nextPage));
      }, 1000);
    }
    else if (location.pathname.includes("/duonut/") && openEnded) {
      setClickedNum(num);

      var userIsPro = false;
      if (duonutData?.created_by.pro_user) {
        userIsPro = true;
      } else if (duonutData?.created_by.expire_date) {
        const currentDate = new Date();
        const dateToCompare = new Date(duonutData?.created_by.expire_date);
        const comparisonResult = currentDate < dateToCompare;
        if (comparisonResult) {
          userIsPro = true;
        } else {
          userIsPro = false;
        }
      }
      if ((!userIsPro && aiCount < parseInt(duonutData?.setting?.open_ended_conversation_limit)) || (userIsPro && aiCount < parseInt(duonutData?.setting?.open_ended_conversation_limit))) {

        setloading(true);

        aiConversation = [...aiConversation,
        { question: inputText, answer: textField },
        ];

        var get_ans = {
          question: inputText,
          answer: num,
          objective: openEndedObjective,
          feedbackDesired: openEndedFeedbackDesired,
          aiConversation: aiConversation,
          option_type: 3,
        }

        dispatch(getOpenEndedQuestionAsync(get_ans)).then((res) => {
          // console.log(res);
          if (res?.question) {
            // console.log(res?.question);
            setInputText(res?.question);
            if (document.getElementById(`qainput-${selectedSlide + 1}-ai${aiCount}`)) {
              document.getElementById(`qainput-${selectedSlide + 1}-ai${aiCount}`).value = "";
            }
            setloading(false);

            var save_ans = {
              display_id: viewer_display_id,
              watch_id: save_ans_response[0] ? save_ans_response[0].watch_id : "",
              result: 1,
              question: inputText ? inputText : "",
              answered_text: num,
              // end: lastQue ? "1" : "0",
              nickname: viewer_nick_name,
              step: aiCount === 0 ? selectedSlide + 1 : `${selectedSlide + 1}-ai${aiCount}`,
              type: aiCount === 0 ? "Nps" : "QuestionAnswer",
              // country: location[0] ? location[0].address?.country : "",
              // city: location[0] ? location[0].address?.state_district : "",
              // location: location[0] ? location[0].display_name : "",
              url_params: viewer_url_params,
            }
            // console.log(save_ans);
            const formData = new FormData();
            Object.keys(save_ans).forEach(key => {
              const value = typeof save_ans[key] === "object" ? JSON.stringify(save_ans[key]) : save_ans[key];
              formData.append(key, value);
            });

            dispatch(saveQuizAnsAsync(formData));

            var save_response = {
              type: aiCount === 0 ? "Nps" : "QuestionAnswer",
              step: aiCount === 0 ? selectedSlide + 1 : `${selectedSlide + 1}-ai${aiCount}`,
              question: inputText ? inputText : "",
              answered_text: num,
            }
            dispatch(saveQuizResponse(save_response));

            aiCount++;
            // if (document.getElementById(`element-${id}-${slideId}`)) {
            //   console.log("hello",id,slideId, document.getElementById(`element-${id}-${slideId}`));
            //   document.getElementById(`element-${id}-${slideId}`).style.top = 120;
            //   document.getElementById(`element-${id}-${slideId}`).style.left = 200;
            //   document.getElementById(`element-${id}-${slideId}`).style.padding = 20;

            // }

          }
        }).catch((e) => {
          console.log(e.message, e);
        });

      }
      else {
        var save_ans = {
          display_id: viewer_display_id,
          watch_id: save_ans_response[0] ? save_ans_response[0].watch_id : "",
          result: 1,
          question: inputText ? inputText : "",
          answered_text: num,
          // end: lastQue ? "1" : "0",
          nickname: viewer_nick_name,
          step: aiCount === 0 ? selectedSlide + 1 : `${selectedSlide + 1}-ai${aiCount}`,
          type: aiCount === 0 ? "Nps" : "QuestionAnswer",
          // country: location[0] ? location[0].address?.country : "",
          // city: location[0] ? location[0].address?.state_district : "",
          // location: location[0] ? location[0].display_name : "",
          url_params: viewer_url_params,
        }
        // console.log(save_ans);
        const formData = new FormData();
        Object.keys(save_ans).forEach(key => {
          const value = typeof save_ans[key] === "object" ? JSON.stringify(save_ans[key]) : save_ans[key];
          formData.append(key, value);
        });

        dispatch(saveQuizAnsAsync(formData));

        var save_response = {
          type: aiCount === 0 ? "Nps" : "QuestionAnswer",
          step: aiCount === 0 ? selectedSlide + 1 : `${selectedSlide + 1}-ai${aiCount}`,
          question: inputText ? inputText : "",
          answered_text: num,
        }
        dispatch(saveQuizResponse(save_response));

        var nextPage = selectedSlide + 1;

        setTimeout(() => {
          dispatch(updateGoToSlide(nextPage));
        }, 1000);
      }
    }
  }
  // useEffect(() => {
  //   const element = document.getElementById(`element-${id}-${slideId}`);
  //   if (element && aiCount>0) {
  //     console.log("okay");
  //     element.style.top = "120px";
  //     element.style.left = "200px";
  //   }
  // }, [aiCount]);
  const handleQuestionAnswerInputChange = (e) => {
    // console.log(e)
    if (location.pathname.includes("/duonut/")) {
      setTextField(e.target.value);
      if (e.keyCode === 13) {
        document.getElementById("AiSubmitBtn").click();
      }
    }
  }
  return (
    <>
      {aiCount === 0 && <div style={{
        display: "flex",
        justifyContent: "center", alignItems: "center",
        flexDirection: 'column',
        gap: viewType === 3 || width < 800 ? "80px" : gap, color, fontFamily, fontSize, fontWeight, fontStyle, textDecoration, textTransform,
        textAlign, lineHeight, letterSpacing,
      }} >
        <div
          style={{
            width: "100%", paddingBottom: '8px', color: labelColor, fontSize: labelFontSize, fontWeight: labelFontWeight
          }}>{inputText}</div>

        <div style={{
          width: "100%",
          display: "flex",
          justifyContent:
            textAlign === "center"
              ? "center"
              : textAlign === "right"
                ? "flex-end"
                : "flex-start", alignItems: "center",
          flexWrap: "wrap"
        }}>
          {Array.from({ length: maxLength }, (_, i) => {
            return <div key={i} id={`option-id${i + 1}`}
              style={{
                display: "flex",
                justifyContent: "center", alignItems: "center",
                border: border ? border : "0", margin, padding, borderRadius,
                width: `${size}px`, height: `${size}px`,
                backgroundColor: isHovered < (i + 1) ? clickedNum < (i + 1) ? backgroundColor : elementHoverAttr?.selectedBackgroundColor : elementHoverAttr?.backgroundColor
              }}
              onMouseEnter={() => setIsHovered(i + 1)}
              onMouseLeave={() => setIsHovered(-1)}
              onClick={() => handleButtonClick(i + 1)}>
              {i + 1}
            </div>
          })}
        </div>
      </div>}
      {openEnded && aiCount > 0 && <div style={{
        display: "flex", justifyContent: 'space-between', flexDirection: "column", height: width > 800 ? "200px" : "400px",
        width: width > 800 ? "450px" : "auto", margin: "auto"
      }}>
        <div
          style={{
            backgroundColor: "transparent",
            border: "0",
            outline: "none",
            // display: "flex",
            // alignItems: "center",
            width: "100%",
            color: "black", fontFamily, fontSize: "16px", fontWeight, fontStyle, textDecoration, textTransform,
            textAlign, lineHeight, letterSpacing
          }}
        ><b>Followup question:</b> {inputText}</div>
        <div style={{ display: "flex", alignItems: viewType === 3 || width < 800 ? "center" : "unset", flexDirection: viewType === 3 || width < 800 ? "column" : "unset" }}>
          <input type='text' key={`qainput-${selectedSlide + 1}-ai${aiCount}`}
            id={`qainput-${selectedSlide + 1}-ai${aiCount}`}
            style={{
              width: "100%", minHeight: "40px",
              color: "black", fontFamily, fontSize: fontSize, fontStyle, textDecoration, textTransform,
              textAlign: "left", lineHeight, letterSpacing, padding: "4px 10px", boxSizing: "border-box",
              backgroundColor: "transparent", border: '1px solid gray', margin: "0px 5px", borderRadius
            }}
            onKeyUp={(e) => handleQuestionAnswerInputChange(e)}
            readOnly={location.pathname.includes("/duonut/") ? false : true}
            required={required}
            placeholder={"Type your answer here.."}
          />
          <button id="AiSubmitBtn" onClick={() => handleButtonClick(textField)}
            style={{
              backgroundColor: "#4299e1", color: "white", borderRadius: "6px", padding: '1rem',
              cursor: "pointer", margin: viewType === 3 || width < 800 ? "1rem 0px" : "0px"
            }}>
            {loading ? <img alt="loader" src={require(`../../assets/Images/loader.gif`)} style={{ margin: "auto", height: "1rem" }} />
              : viewType === 3 || width < 800 ? <span style={{ fontSize: "1rem" }}> Next</span> : <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
              </svg>}
          </button>
        </div>
      </div>}
    </>
  );
}

export default Nps;
