import React, { useState, useEffect } from 'react';
import './Element.css';
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { saveQuizAnsAsync, saveQuizResponse, showDuonutresponse } from '../../redux/slices/DuonutReducer';
import imgPreview from '../../assets/Images/image-preview.png';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { updateGoToSlide } from '../../redux/slices/userActivity';
import { showduonutData } from '../../redux/slices/DuonutReducer';
import axios from "axios";
import { domain } from "../../services/constants";

const GenericConjoint = ({
    slideId,
    id,
    elementAttr: {
        text, color, fontFamily, fontSize, fontWeight, options, fontStyle, textDecoration, textTransform, textAlign,
        lineHeight, letterSpacing, backgroundColor, border, margin, padding, borderRadius, gap,
        labelFontSize, labelFontWeight, labelColor, imageWidth, imagePadding, showAttribute,
    },
    elementHoverAttr,
    conditions,
    language,
}) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { selectedSlide, } = useSelector((state) => state.userActivity);
    const { viewer_display_id, viewer_nick_name, } = useSelector((state) => state.duonut);
    const save_ans_response = useSelector(showDuonutresponse);
    const { viewType } = useSelector((state) => state.duonutSettings);
    const [selectedAnswer, setSelectedAnswer] = useState([]);
    const { width } = useWindowDimensions();
    const [hover, setHover] = useState(-1);

    const duonutDataMain = useSelector(showduonutData);
    // const duonutData = duonutDataMain[0];

    const [inputText, setInputText] = useState("");

    const [currentSlide, setCurrentSlide] = useState(0);
    const [choiceSets, setChoiceSets] = useState([]);
    const [currentOptions, setCurrentOptions] = useState([]);

    // Helper function to generate combinations
    const generateCombinations = (attributes) => {
        const levels = attributes.map((attr) => attr.levels);
        return levels.reduce(
            (acc, curr) => acc.flatMap((x) => curr.map((y) => [...x, y])),
            [[]]
        );
    };

    const randomSample = (array, size) => {
        const shuffled = [...array].sort(() => 0.5 - Math.random());
        return shuffled.slice(0, size);
    };

    useEffect(() => {
        if (location.pathname.includes("/duonut/") && slideId === `slide-${selectedSlide}` && language !== "en") {
            fetchText(text);
            // fetchOptionsText(shuffledOptions);
        } else {
            setInputText(text);
            // setoptionsText(shuffledOptions);
        }

        const combinations = generateCombinations(options);

        const numProfilesPerSet = 3; // Number of profiles per choice set
        const numChoiceSets = 10; // Number of choice sets to generate

        // Generate choice sets
        const sets = Array.from({ length: numChoiceSets }, () =>
            randomSample(combinations, numProfilesPerSet)
        );

        // console.log("sets", sets)
        setChoiceSets(sets);
        setCurrentOptions(sets[currentSlide]);

    }, [text, options]);
    // console.log(options);
    // console.log("choice", choiceSets);

    const fetchText = async (texts) => {
        const t_text = await translateText(texts, language);
        setInputText(t_text);
    };
    const translateText = async (texts, targetLanguage) => {
        try {
            const response = await axios({
                method: "POST",
                url: `${domain}/duonut/language_translator`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: {
                    source: "en",
                    target: targetLanguage,
                    query: texts,
                },
            });

            if (response.status === 200) {
                // console.log("Translation:", response);
                return response?.data.translation; // return the translated text
            } else {
                // Handle unsuccessful status codes
                return "Error: Translation failed. Please try again.";
            }
        } catch (error) {
            console.error('Error in translation API:', error);
            return "Error translating text. Please try again."; // Handle API errors gracefully
        }
    };


    const submitResponse = (item, index) => {
        // console.log(item);

        // Create the answer string
        const ans = item
            .map(({ label, image }) => (image ? `${label}-${image}` : label))
            .join(", ");
        // console.log(ans);
        if (location.pathname.includes("/duonut/")) {

            const save_ans = {
                display_id: viewer_display_id,
                watch_id: save_ans_response[0]?.watch_id || "",
                result: 0,
                question: text || "",
                answered_text: ans,
                nickname: viewer_nick_name,
                step: currentSlide === 0 ? selectedSlide + 1 : `${selectedSlide + 1}-ai${currentSlide}`,
                type: "GenericConjoint",
            };

            const formData = new FormData();
            Object.keys(save_ans).forEach(key => formData.append(key, save_ans[key]));
            dispatch(saveQuizAnsAsync(formData));

            const save_response = {
                id,
                type: "GenericConjoint",
                step: currentSlide === 0 ? selectedSlide + 1 : `${selectedSlide + 1}-ai${currentSlide}`,
                question: text || "",
                answered_text: ans
            };
            dispatch(saveQuizResponse(save_response));

            if (currentSlide < choiceSets.length - 1) {
                const divContainer = document.getElementById("myDivContainer");

                if (divContainer) {
                    // Decrease opacity to 0
                    divContainer.style.opacity = "0.2";

                    // Restore opacity to 1 after 500ms
                    setTimeout(() => {
                        divContainer.style.opacity = "1";
                    }, 500);
                }
                setCurrentOptions(choiceSets[currentSlide + 1]);
                setCurrentSlide(currentSlide + 1);

            } else {
                // console.log("Survey completed!");
                var nextPage = selectedSlide + 1;

                setTimeout(() => {
                    dispatch(updateGoToSlide(nextPage));
                }, 1000);
            }

        }
    }
    // console.log("current", currentOptions);
    return (<>
        <div style={{ display: "flex", flexDirection: "column", gap }} className='generic-conjoint'>
            <div style={{
                width: "100%", paddingBottom: '10px',
                color: labelColor, fontFamily, fontSize: labelFontSize, fontWeight: labelFontWeight, fontStyle, textDecoration, textTransform,
                textAlign, lineHeight, letterSpacing,
            }}>
                {inputText}
            </div>

            <div id="myDivContainer" style={{ overflowX: "auto" }}>
                <table style={{ width: "100%", borderCollapse: "collapse", textAlign: "center" }}>
                    <tbody>
                        {/* Attribute Rows */}
                        {options.map((item, i) => (
                            <tr key={i}>
                                {/* Attribute Name in the First Column */}
                                {showAttribute && <td style={{
                                    padding: "10px",
                                    textAlign: "center",
                                    backgroundColor: backgroundColor,
                                    border: border || "1px solid #ccc",
                                    fontWeight: "bold",
                                    minWidth: "5rem",
                                }}>
                                    {item.attribute}
                                </td>}

                                {/* Corresponding Attribute Values for Each Option */}
                                {/* Fetch corresponding attribute values from currentOptions */}
                                {currentOptions.map((optionSet, indx) => (
                                    <td key={indx} style={{
                                        padding: "10px",
                                        backgroundColor: backgroundColor,
                                        border: border || "1px solid #ccc",
                                        textAlign: "center",
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}>
                                            {/* Label */}
                                            {optionSet[i]?.label && <span>{optionSet[i].label}</span>}

                                            {/* Image */}
                                            {optionSet[i]?.image && (
                                                <img
                                                    src={optionSet[i].image || imgPreview}
                                                    style={{
                                                        width: imageWidth,
                                                        height: imageWidth,
                                                        objectFit: "contain",
                                                        padding: imagePadding,
                                                        boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.2)"
                                                    }}
                                                    alt=""
                                                />
                                            )}
                                        </div>
                                    </td>
                                ))}
                            </tr>
                        ))}

                        {/* Buttons Row */}
                        <tr>
                            {showAttribute && <td></td>} {/* Empty cell for alignment */}
                            {/* {currentOptions.map((option, indx) => ( */}
                            {options[0]?.levels.map((_, indx) => (
                                <td key={indx} style={{ padding: "10px", textAlign: "center", border: border || "1px solid #ccc", background: "#3498db" }}>
                                    <button
                                        onClick={() => submitResponse(options.map(opt => opt.levels[indx]), indx)}
                                        style={{
                                            padding: "6px 12px",
                                            fontSize,
                                            // backgroundColor: selectedAnswer.includes(option) ? "#4CAF50" : "#ccc",
                                            color: selectedAnswer.includes(options.map(opt => opt.levels[indx]?.label)) ? "#fff" : "#333",
                                            border: "none",
                                            borderRadius: "5px",
                                            cursor: "pointer",
                                            transition: "background-color 0.2s",
                                            width: "100%", background: "transparent", color: "white"
                                        }}
                                        onMouseEnter={(e) => {
                                            // e.target.style.background = "rgba(255, 255, 255, 0.2)";
                                            e.target.style.transform = "scale(1.05)";
                                        }}
                                        onMouseLeave={(e) => {
                                            // e.target.style.background = "transparent";
                                            e.target.style.transform = "scale(1)";
                                        }}
                                    >
                                        {selectedAnswer.includes(options.map(opt => opt.levels[indx]?.label)) ? "Selected" : "Choose"}
                                    </button>
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </div>

            {/* None of the Above Button */}
            <div style={{ width: "100%", textAlign: "right", marginTop: "10px" }}>
                <button style={{
                    fontSize,
                    backgroundColor: backgroundColor,
                    border: border || "0",
                    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.5)",
                    color: color,
                    borderRadius, fontFamily, fontSize, fontWeight, fontStyle, textDecoration,
                    textTransform, textAlign, letterSpacing, padding, margin, width: "14rem",
                    cursor: "pointer"
                }}
                    onMouseEnter={(e) => {
                        e.target.style.background = "#3498db";
                        e.target.style.color = "white";
                        e.target.style.transform = "scale(1.05)";
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.background = backgroundColor;
                        e.target.style.color = color;
                        e.target.style.transform = "scale(1)";
                    }}
                    onClick={() => { 
                        submitResponse([{ label: "None of the above", image: "" }], 0); 
                    }}
                    >NONE OF THE ABOVE</button>
            </div>
        </div>


    </>
    );
}

export default GenericConjoint;
