import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Route } from "react-router-dom";

export default function PrivateRoute(Component) {
  const { isLoggedIn} = useSelector((state) => state.auth);

  return (
    <Route
      path="/create"
      element={
        <RequireAuth isAuthenticated={isLoggedIn} >
          <Component />
        </RequireAuth>
      }
    />
  );
}

export function RequireAuth({ children, isLoggedIn, isOnboarded }) {
  if (!isLoggedIn) {
    return <Navigate to="/" />; // Redirect to login if not authenticated
  }

  if (!isOnboarded) {
    return <Navigate to="/onboarding" />; // Redirect to onboarding if not completed
  }

  return children;
}
