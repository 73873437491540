import React, { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { updateUserActivityElements } from '../../../redux/slices/userActivity';
import { EditText } from "react-edit-text";
import { getFileSizeFromBase64, toDataUrl } from "../../../utils";
import { domain } from "../../../services/constants";
import axios from "axios";
import Resizer from 'react-image-file-resizer';
import { useLocation } from "react-router-dom";
import LevelImageModal from "./LevelImageModal";

export default function AttributeLevelsOption({ totalOptions, item, idx }) {

    const { selectedUserActivity, userActivities, selectedComponent } = useSelector((state) => state.userActivity);
    const [option, setOption] = useState({});
    const dispatch = useDispatch()
    const inputRef = useRef(null);
    const location = useLocation();
    const [paramsid, setparamsid] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [levelIndx, setLevelIndx] = useState(-1);

    // console.log("item", item, option);
    useEffect(() => {

        // Get the current pathname from the window location
        const pathname = location.pathname;

        // Split the pathname into an array of words
        const words = pathname.split('/');

        // Get the last word (assuming the URL ends with a word)
        const lastWord = words[words.length - 1];

        // Use a regular expression to match and capture the numeric part of the URL
        const matchResult = pathname.match(/\/design\/(\d+)/);

        // Check if there is a match and retrieve the captured number
        if (matchResult && matchResult[1]) {
            const extractedNumber = parseInt(matchResult[1]);
            // console.log('Extracted Number:', extractedNumber);
            setparamsid(extractedNumber);
        }
        // Log or use the last word as needed
        // console.log('Last word:', lastWord);

    }, [location.pathname]);


    useEffect(() => {
        setOption(item);
    }, [item]);

    const handleOptionTextChange = (e, indx) => {
        // console.log(e.target.value);
        const newText = e.target.value;

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;

            // Ensure 'elementAttrib.options[idx].levels' exists before proceeding
            if (elementAttrib?.options?.[idx]?.levels) {

                // Make a copy of the levels array and update the text of the option at index 'indx'
                const updatedLevels = elementAttrib.options[idx].levels.map((level, levelIndex) => {
                    if (levelIndex === indx) {
                        // return newText;
                        return {
                            ...level,   // Preserve the existing structure of the level object
                            label: newText,  // Only update the 'label' field
                        };
                    }
                    return level;
                });

                // Update the specific option with the updated levels
                const updatedOptions = elementAttrib.options.map((option, optionIndex) => {
                    if (optionIndex === idx) {
                        return {
                            ...option,
                            levels: updatedLevels, // Replace only the levels
                        };
                    }
                    return option;
                });

                tempElement = {
                    ...tempElement,
                    elementAttributes: {
                        ...elementAttrib,
                        options: updatedOptions,
                    },
                };

                dispatch(
                    updateUserActivityElements({
                        selectedActivityIdx: selectedUserActivity,
                        selectedElementIdx: selectedComponent.target_id,
                        element: tempElement,
                    })
                );
            } else {
                console.error("Levels array not found in options at the specified index.");
            }

        }
    }

    const removeOption = (indx) => {
        if (indx === -1) return null;

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;

            // Make a copy of options array and remove the option at index 'indx'
            const updatedOptions = elementAttrib.options.filter((option, index) => index !== indx);

            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    options: updatedOptions,
                },
            };
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }

    const handleClick = (i) => {
        inputRef.current.value = null; // reset the value of the file input
        inputRef.current.click();
        setLevelIndx(i);
    };


    const updateActivityImage = (e) => {
        const imageFile = e.target.files[0];

        let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
        const elementAttrib = tempElement?.elementAttributes;

        if (imageFile.type === "image/png") {
            Resizer.imageFileResizer(
                imageFile,
                1000,
                1000,
                'PNG',
                90,
                0,
                uri => {
                    const image_data = {
                        duonut_id: paramsid,
                        name: e.target.files[0].name,
                        uri: uri,
                    }
                    axios({
                        method: "POST",
                        url: `${domain}/duonut/upload_files`,
                        headers: {
                            "Content-Type": "application/json"
                        },
                        data: JSON.stringify(image_data),
                    }).then((res) => {
                        //console.log(res.data.file_url);

                        if (elementAttrib?.options?.[idx]?.levels) {

                            // Make a copy of the levels array and update the text of the option at index 'indx'
                            const updatedLevels = elementAttrib.options[idx].levels.map((level, levelIndex) => {
                                if (levelIndex === levelIndx) {
                                    // return res.data.file_url;
                                    return { ...level, image: res.data.file_url };
                                }
                                return level;
                            });

                            // Update the specific option with the updated levels
                            const updatedOptions = elementAttrib.options.map((option, optionIndex) => {
                                if (optionIndex === idx) {
                                    return {
                                        ...option,
                                        levels: updatedLevels, // Replace only the levels
                                    };
                                }
                                return option;
                            });

                            tempElement = {
                                ...tempElement,
                                elementAttributes: {
                                    ...elementAttrib,
                                    options: updatedOptions,
                                },
                            };

                            dispatch(
                                updateUserActivityElements({
                                    selectedActivityIdx: selectedUserActivity,
                                    selectedElementIdx: selectedComponent.target_id,
                                    element: tempElement,
                                })
                            );
                        } else {
                            console.error("Levels array not found in options at the specified index.");
                        }
                    }).catch((e) => {
                        console.log(e.message, e);
                    });

                    getFileSizeFromBase64(uri)
                },
                'base64'
            );

        } else if (imageFile.type === "image/gif") {
            toDataUrl(e.target.files[0], (res) => {
                let result = res;

                const image_data = {
                    duonut_id: paramsid,
                    name: e.target.files[0].name,
                    uri: result,
                }
                axios({
                    method: "POST",
                    url: `${domain}/duonut/upload_files`,
                    headers: {
                        "Content-Type": "application/json"
                    },
                    data: JSON.stringify(image_data),
                }).then((res) => {
                    //console.log(res.data.file_url);
                    if (elementAttrib?.options?.[idx]?.levels) {

                        // Make a copy of the levels array and update the text of the option at index 'indx'
                        const updatedLevels = elementAttrib.options[idx].levels.map((level, levelIndex) => {
                            if (levelIndex === levelIndx) {
                                // return res.data.file_url;
                                return { ...level, image: res.data.file_url };
                            }
                            return level;
                        });

                        // Update the specific option with the updated levels
                        const updatedOptions = elementAttrib.options.map((option, optionIndex) => {
                            if (optionIndex === idx) {
                                return {
                                    ...option,
                                    levels: updatedLevels, // Replace only the levels
                                };
                            }
                            return option;
                        });

                        tempElement = {
                            ...tempElement,
                            elementAttributes: {
                                ...elementAttrib,
                                options: updatedOptions,
                            },
                        };

                        dispatch(
                            updateUserActivityElements({
                                selectedActivityIdx: selectedUserActivity,
                                selectedElementIdx: selectedComponent.target_id,
                                element: tempElement,
                            })
                        );
                    } else {
                        console.error("Levels array not found in options at the specified index.");
                    }
                }).catch((e) => {
                    console.log(e.message, e);
                });

            });
        } else {
            Resizer.imageFileResizer(
                imageFile,
                1000,
                1000,
                'JPEG',
                90,
                0,
                uri => {
                    const image_data = {
                        duonut_id: paramsid,
                        name: e.target.files[0].name,
                        uri: uri,
                    }
                    axios({
                        method: "POST",
                        url: `${domain}/duonut/upload_files`,
                        headers: {
                            "Content-Type": "application/json"
                        },
                        data: JSON.stringify(image_data),
                    }).then((res) => {
                        //console.log(res.data.file_url);
                        if (elementAttrib?.options?.[idx]?.levels) {

                            // Make a copy of the levels array and update the text of the option at index 'indx'
                            const updatedLevels = elementAttrib.options[idx].levels.map((level, levelIndex) => {
                                if (levelIndex === levelIndx) {
                                    // return res.data.file_url;
                                    return { ...level, image: res.data.file_url };
                                }
                                return level;
                            });

                            // Update the specific option with the updated levels
                            const updatedOptions = elementAttrib.options.map((option, optionIndex) => {
                                if (optionIndex === idx) {
                                    return {
                                        ...option,
                                        levels: updatedLevels, // Replace only the levels
                                    };
                                }
                                return option;
                            });

                            tempElement = {
                                ...tempElement,
                                elementAttributes: {
                                    ...elementAttrib,
                                    options: updatedOptions,
                                },
                            };

                            dispatch(
                                updateUserActivityElements({
                                    selectedActivityIdx: selectedUserActivity,
                                    selectedElementIdx: selectedComponent.target_id,
                                    element: tempElement,
                                })
                            );
                        } else {
                            console.error("Levels array not found in options at the specified index.");
                        }
                    }).catch((e) => {
                        console.log(e.message, e);
                    });

                    getFileSizeFromBase64(uri)
                },
                'base64'
            );
        }


    }

    const handleClick1 = (i) => {
        setIsModalOpen(true);
        setLevelIndx(i);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleAttributeChange = (indx, event) => {
        const newText = event.target.value;

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;

            const updatedOptions = elementAttrib.options.map((option, index) => {
                if (index === indx) {
                    return { attribute: newText, levels: [...option.levels] };
                }
                return option;
            });

            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    options: updatedOptions,
                },
            };
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    };

    const removeLevels = (indx) => {
        if (indx === -1) return null;

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;

            /// Ensure 'elementAttrib.options[idx].levels' exists before proceeding
            if (elementAttrib?.options?.[idx]?.levels) {
                // Create a new levels array without the item at 'indx'
                const updatedLevels = elementAttrib.options[idx].levels.filter((_, levelIndex) => levelIndex !== indx);

                // Create a new options array, updating only the 'levels' for the specific option
                const updatedOptions = elementAttrib.options.map((option, optionIndex) => {
                    if (optionIndex === idx) {
                        return {
                            ...option,
                            levels: updatedLevels, // Replace only the levels
                        };
                    }
                    return option;
                });

                tempElement = {
                    ...tempElement,
                    elementAttributes: {    
                        ...elementAttrib,
                        options: updatedOptions,
                    },
                };
                dispatch(
                    updateUserActivityElements({
                        selectedActivityIdx: selectedUserActivity,
                        selectedElementIdx: selectedComponent.target_id,
                        element: tempElement,
                    })
                );
            } else {
                console.error("Levels array not found in options at the specified index.");
            }
        }
    }

    const addLevel = () => {
        if (userActivities[selectedUserActivity]?.elements?.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;

            // Ensure 'elementAttrib.options[idx].levels' exists before proceeding
            if (elementAttrib?.options?.[idx]?.levels) {
                // Add the new level to the levels array
                const updatedLevels = [...elementAttrib.options[idx].levels, ""];

                // Create a new options array, updating only the 'levels' for the specific option
                const updatedOptions = elementAttrib.options.map((option, optionIndex) => {
                    if (optionIndex === idx) {
                        return {
                            ...option,
                            levels: updatedLevels, // Replace with updated levels
                        };
                    }
                    return option;
                });

                // Update the element with the modified options
                tempElement = {
                    ...tempElement,
                    elementAttributes: {
                        ...elementAttrib,
                        options: updatedOptions,
                    },
                };

                // Dispatch the updated element
                dispatch(
                    updateUserActivityElements({
                        selectedActivityIdx: selectedUserActivity,
                        selectedElementIdx: selectedComponent.target_id,
                        element: tempElement,
                    })
                );
            } else {
                console.error("Levels array not found in options at the specified index.");
            }
        } else {
            console.error("No elements found for the selected user activity.");
        }
    };

    return (<>
        {isModalOpen && <LevelImageModal handleClose={handleCloseModal} idx={idx} indx={levelIndx} />}

        <div style={{
            display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", width: "100%"
        }} key={idx}  >
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                fontSize: '14px',
                margin: '3px 3px 10px',
                width: '100%',
                flexDirection: 'column',
                gap: '5px'
            }}>
                < label style={{ marginRight: "1rem" }}>Attribute</label>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%'
                }}>
                    <input
                        type="text"
                        value={option.attribute}
                        style={{
                            border: "1px solid #11111333", borderRadius: "5px", backgroundColor: "white",
                            display: "flex", justifyContent: "flex-start", alignItems: "center",
                            width: "80%", height: "1.8rem", padding: "5px"
                        }}
                        onChange={(e) => handleAttributeChange(idx, e)}
                    />
                    <i className="fa fa-trash-o" aria-hidden="true"
                        style={{ marginLeft: "0.8rem", fontSize: "1rem", color: totalOptions?.length > 2 ? "#DB615C" : "rgb(219, 97, 92, 0.4)", cursor: totalOptions?.length > 2 ? "pointer" : "auto", textAlign: "center", width: "9%" }}
                        onClick={() => totalOptions?.length > 2 ? removeOption(idx) : removeOption(-1)}></i>
                </div>
            </div >
            <div style={{
                display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"
            }} >
                {option?.levels?.map((value, i) => (<div 
                key={`level-${idx}-${i}`}
                style={{
                    display: "flex", justifyContent: "center", alignItems: "center", fontSize: "14px",flexDirection:"column",margin:"5%"
                }} >
                    <label style={{ marginRight: "1rem" }}>Level</label>
                    <div  style={{display:"flex",flexDirection:"row",justifyItems:"center",width:"100%",alignItems:"center"}}>
                        <label style={{ marginRight: "1rem" }}>Label </label>
                        <div style={{display:"flex",flexDirection:"row",}}>
                            <EditText
                                id={`option-input-${idx}-${i}`}
                                value={value.label}
                                style={{
                                    border: "1px solid #11111333", borderRadius: "5px", backgroundColor: "white",
                                    display: "flex", justifyContent: "flex-start", alignItems: "center",
                                    width: "15rem", height: "1.8rem", padding: "5px"
                                }}
                                onEditMode={() => { setTimeout(() => document.querySelector(`#option-input-${idx}-${i}`).select(), 100); }}
                                // onEditMode={() => { setTimeout(() => document.querySelector(`#level-label-${idx}-${i}`).select(), 100); }}
                               
                                onChange={(e) => (e.target.value.length < 0 ? null : handleOptionTextChange(e, i))}
                            />
                            
                        </div>
                    </div>
                    <div style={{display:"flex",flexDirection:"row",justifyItems:"center",width:"100%",alignItems:"center"}}>
                    <label style={{ marginRight: "1rem" }}>Image</label>
                        <div>
                            <EditText
                                id={`option-image-${idx}-${i}`}
                                value={value.image}
                                style={{
                                    border: "1px solid #11111333", borderRadius: "5px", backgroundColor: "white",
                                    display: "flex", justifyContent: "flex-start", alignItems: "center",
                                    width: "10rem", height: "1.8rem", padding: "5px"
                                }}
                                // onEditMode={() => { setTimeout(() => document.querySelector(`#option-image-${idx}-${i}`).select(), 100); }}
                                onChange={(e) => (e.target.value.length < 0 ? null : handleOptionTextChange(e, i))}
                            />
                        </div>
                    <img src="/assets/icons/upload-image.svg" onClick={() => handleClick(i)} style={{ marginLeft: "0.5rem", cursor: "pointer" }} />
                    <input type="file" ref={inputRef}
                        onChange={(e) => updateActivityImage(e)}
                        accept={"image/*"}
                        onClick={(e) => { e.target.value = null }} name="postImages" style={{ display: "none" }} />

                    <i className="fa fa-picture-o" aria-hidden="true" onClick={() => handleClick1(i)}
                        style={{ marginLeft: "0.6rem", fontSize: "1rem", opacity: "0.8", cursor: "pointer" }}></i>

                    <i className="fa fa-trash-o" aria-hidden="true"
                        style={{ marginLeft: "0.8rem", fontSize: "1rem", color: option?.levels?.length > 2 ? "#DB615C" : "rgb(219, 97, 92, 0.4)", cursor: option?.levels?.length > 2 ? "pointer" : "auto" }}
                        onClick={() => { option?.levels?.length > 2 ? removeLevels(i) : removeLevels(-1) }}></i>
                        </div>
                </div>))}
            </div>
            <button type='submit' className='addoptionbtn' onClick={() => addLevel()} >Add Level</button>

        </div >
    </>
    )
}

