import { createSlice } from "@reduxjs/toolkit";
import { domain } from "../../services/constants";
import axios from 'axios';

// const axios = require("axios");

const getAuthHeaders = () => {
    const token = localStorage.getItem("access");
    let header = {
        headers: { Authorization: `Bearer ${token}` },
    };
    // console.log("token", token);
    return header;
};


export const profileSlide = createSlice({
    name: "profile",
    initialState: {
        response: []
    },
    reducers: {
        saveProfile: (state, action) => {
            state.response = [action.payload];
        }
    }
});


export const editUserNameAsync = (data) => async (dispatch) => {
    try {
        // console.log(data);
        const header = getAuthHeaders();
        const API_URL = `${domain}/account/updateusername`;
        const response = await axios.post(API_URL, data, header);
        // console.log(response);
    } catch (err) {
        throw new Error(err);
    }
};

export const editProfileImageAsync = (data) => async (dispatch) => {
    try {
        // console.log(data);
        const header = getAuthHeaders();
        const API_URL = `${domain}/account/updateuserprofileimage`;
        const response = await axios.post(API_URL, data, header);
        // console.log(response);
    } catch (err) {
        throw new Error(err);
    }
};

export const editUserDomainAsync = (data) => async (dispatch) => {
    try {
        // console.log(data);
        const header = getAuthHeaders();
        const API_URL = `${domain}/account/updateuserdomain`;
        const response = await axios.post(API_URL, data, header);
        return response.data;
        // console.log(response);
    } catch (err) {
        throw new Error(err);
    }
};

export const deleteUserAsync = (data) => async (dispatch) => {
    try {
        // console.log(data);
        const header = getAuthHeaders();
        const API_URL = `${domain}/account/deleteUser`;
        const response = await axios.post(API_URL, data, header);
        return response.data;
        // console.log(response);
    } catch (err) {
        throw new Error(err);
    }
};

export const { saveProfile } = profileSlide.actions;
export const showProfileresponse = (state) => state.profile.response;
export default profileSlide.reducer;
