import React, { useState, useEffect, useRef } from 'react';
import './Element.css';
import { EditText, } from "react-edit-text";
import "react-edit-text/dist/index.css";
import { useSelector, useDispatch } from "react-redux";
import { updateUserActivityElements, updateGoToSlide, updateElementInputFocus, setTextFormData } from '../../redux/slices/userActivity';
import { useLocation, } from "react-router-dom";
import { showDuonutresponse, saveQuizAnsAsync, saveQuizResponse, } from '../../redux/slices/DuonutReducer';
import { getOpenEndedQuestionAsync } from "../../redux/slices/AiDuonutNewReducer";
import { showduonutData } from '../../redux/slices/DuonutReducer';
import axios from "axios";
import { domain } from "../../services/constants";
import { useSpeech } from "react-text-to-speech";
import useWindowDimensions from '../../hooks/useWindowDimensions';

var aiCount = 0;
var aiConversation = [];
const QuestionAnswer = ({
  id,
  elementAttr: {
    color, fontFamily, fontSize, fontWeight, text, fontStyle, textDecoration, textTransform, textAlign,
    lineHeight, letterSpacing, backgroundColor, border, margin, padding, borderRadius,
    minLength, maxLength, required, placeholder, borderBottom, height, openEnded, openEndedObjective,
    openEndedFeedbackDesired,
  },
  // conditions,
  language,
  slideId,
}) => {
  const dispatch = useDispatch();
  const { userActivities, selectedUserActivity, selectedComponent, selectedSlide, } = useSelector((state) => state.userActivity);
  const location = useLocation();
  const [textField, setTextField] = useState('');
  const save_ans_response = useSelector(showDuonutresponse);
  const { viewer_display_id, viewer_nick_name, viewer_url_params } = useSelector((state) => state.duonut);
  const { viewType } = useSelector((state) => state.duonutSettings);
  const [inputText, setInputText] = useState("");
  const [loading, setloading] = useState(false);
  const [placeholderText, setplaceholderText] = useState('');
  const { width } = useWindowDimensions();

  const duonutDataMain = useSelector(showduonutData);
  const duonutData = duonutDataMain[0];
  const questionRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const {
    Text, // Component that returns the modified text property
    speechStatus, // String that stores current speech status
    isInQueue, // Boolean that stores whether a speech utterance is either being spoken or present in queue
    start, // Function to start the speech or put it in queue
    pause, // Function to pause the speech
    stop, // Function to stop the speech or remove it from queue
  } = useSpeech({ text: inputText });

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.5 } // 50% of the div must be visible
    );

    if (questionRef.current) {
      observer.observe(questionRef.current);
    }

    return () => {
      if (questionRef.current) {
        observer.unobserve(questionRef.current);
      }
    };
  }, [inputText]);

  useEffect(() => {
    if (isVisible && duonutData?.setting?.question_speech && location.pathname.includes("/duonut/") && slideId === `slide-${selectedSlide}`) {
      start();
    } else {
      stop();
    }
  }, [isVisible, inputText]);


  useEffect(() => {
    if (location.pathname.includes("/duonut/") && slideId === `slide-${selectedSlide}` && language !== "en") {
      fetchText(text);
      fetchplaceholderText(placeholder);
    } else {
      setInputText(text);
      setplaceholderText(placeholder);
    }
    var formData = {};
    formData[text] = id;
    // console.log(formData);
    dispatch(setTextFormData(formData));

    aiCount = 0;
    aiConversation = [];
  }, [text]);

  const fetchText = async (texts) => {
    const t_text = await translateText(texts, language);
    setInputText(t_text);
  };
  const fetchplaceholderText = async (texts) => {
    const t_text = await translateText(texts, language);
    setplaceholderText(t_text);
  };

  const translateText = async (texts, targetLanguage) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${domain}/duonut/language_translator`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          source: "en",
          target: targetLanguage,
          query: texts,
        },
      });

      if (response.status === 200) {
        // console.log("Translation:", response);
        return response?.data.translation; // return the translated text
      } else {
        // Handle unsuccessful status codes
        return "Error: Translation failed. Please try again.";
      }
    } catch (error) {
      console.error('Error in translation API:', error);
      return "Error translating text. Please try again."; // Handle API errors gracefully
    }
  };

  const handleQuestionAnswerTextChange = (e) => {
    // console.log(e.target.value);
    setInputText(e.target.value);

    if (userActivities[selectedUserActivity].elements.length >= 1) {
      let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
      const elementAttrib = tempElement?.elementAttributes;
      tempElement = {
        ...tempElement,
        elementAttributes: {
          ...elementAttrib,
          text: e.target.value,
        },
      };
      // console.log("tempElement", tempElement)
      dispatch(
        updateUserActivityElements({
          selectedActivityIdx: selectedUserActivity,
          selectedElementIdx: selectedComponent.target_id,
          element: tempElement,
        })
      );
    }
  }

  const QuestionAnswerTimeout = useRef(null);

  const handleQuestionAnswerInputChange = (e) => {
    if (location.pathname.includes("/duonut/")) {
      setTextField(e.target.value);
      if (e.keyCode === 13) {
        document.getElementById("AiSubmitBtn").click();
      }
      // clearTimeout(QuestionAnswerTimeout.current);
      // QuestionAnswerTimeout.current = setTimeout(() => {

      //   var formData1 = {};
      //   formData1[text] = e.target.value;
      //   // console.log(formData);
      //   dispatch(saveFormData(formData1));

      //   var save_ans = {
      //     display_id: viewer_display_id,
      //     watch_id: save_ans_response[0] ? save_ans_response[0].watch_id : "",
      //     result: e.target.value ? 1 : 0,
      //     question: inputText,
      //     answered_text: e.target.value,
      //     // end: lastQue ? "1" : "0",
      //     nickname: viewer_nick_name,
      //     step: selectedSlide + 1,
      //     type: "QuestionAnswer",
      //     // country: location[0] ? location[0].address?.country : "",
      //     // city: location[0] ? location[0].address?.state_district : "",
      //     // location: location[0] ? location[0].display_name : "",
      //     // url_params: props.details.queryParamsObject
      //   }
      //   // console.log(save_ans);
      //   const formData = new FormData();
      //   Object.keys(save_ans).forEach(key => formData.append(key, save_ans[key]));

      //   dispatch(saveQuizAnsAsync(formData));

      //   var save_response = {
      //     id: id,
      //     type: "QuestionAnswer",
      //     step: selectedSlide + 1,
      //     question: inputText,
      //     answered_text: e.target.value,
      //   }
      //   dispatch(saveQuizResponse(save_response));

      // }, 1000);

    }
  }

  const handleInputFocus = () => {
    dispatch(updateElementInputFocus(true));
  }
  const handleInputBlur = () => {
    dispatch(updateElementInputFocus(false));
  }

  const submitResponse = () => {
    setIsVisible(false);

    if (location.pathname.includes("/duonut/") && !openEnded) {
      var save_ans = {
        display_id: viewer_display_id,
        watch_id: save_ans_response[0] ? save_ans_response[0].watch_id : "",
        result: 1,
        question: inputText ? inputText : "",
        answered_text: textField,
        // end: lastQue ? "1" : "0",
        nickname: viewer_nick_name,
        step: selectedSlide + 1,
        type: "QuestionAnswer",
        // country: location[0] ? location[0].address?.country : "",
        // city: location[0] ? location[0].address?.state_district : "",
        // location: location[0] ? location[0].display_name : "",
        url_params: viewer_url_params,
      }
      // console.log(save_ans);
      const formData = new FormData();
      Object.keys(save_ans).forEach(key => {
        const value = typeof save_ans[key] === "object" ? JSON.stringify(save_ans[key]) : save_ans[key];
        formData.append(key, value);
      });

      dispatch(saveQuizAnsAsync(formData));

      var save_response = {
        type: "QuestionAnswer",
        step: selectedSlide + 1,
        question: inputText ? inputText : "",
        answered_text: textField,
      }
      dispatch(saveQuizResponse(save_response));

      var nextPage = selectedSlide + 1;

      setTimeout(() => {
        dispatch(updateGoToSlide(nextPage));
      }, 1000);

    } else if (location.pathname.includes("/duonut/") && openEnded) {
      // console.log("question", inputText, textField);

      var userIsPro = false;
      if (duonutData?.created_by.pro_user) {
        userIsPro = true;
      } else if (duonutData?.created_by.expire_date) {
        const currentDate = new Date();
        const dateToCompare = new Date(duonutData?.created_by.expire_date);
        const comparisonResult = currentDate < dateToCompare;
        if (comparisonResult) {
          userIsPro = true;
        } else {
          userIsPro = false;
        }
      }
      if ((!userIsPro && aiCount < parseInt(duonutData?.setting?.open_ended_conversation_limit)) || (userIsPro && aiCount < parseInt(duonutData?.setting?.open_ended_conversation_limit))) {

        setloading(true);

        aiConversation = [...aiConversation,
        { question: inputText, answer: textField },
        ];

        var get_ans = {
          question: inputText,
          answer: textField,
          objective: openEndedObjective,
          feedbackDesired: openEndedFeedbackDesired,
          aiConversation: aiConversation,
          option_type: 2,
        }

        dispatch(getOpenEndedQuestionAsync(get_ans)).then((res) => {
          // console.log(res);
          if (res?.question) {
            // console.log(res?.question);
            // setInputText(res?.question)
            if (location.pathname.includes("/duonut/") && slideId === `slide-${selectedSlide}` && language !== "en") {
              fetchText(res?.question);
            } else {
              setInputText(res?.question);
            }
            document.getElementById(`qainput-${selectedSlide + 1}-ai${aiCount}`).value = "";
            setloading(false);

            var save_ans = {
              display_id: viewer_display_id,
              watch_id: save_ans_response[0] ? save_ans_response[0].watch_id : "",
              result: 1,
              question: inputText ? inputText : "",
              answered_text: textField,
              // end: lastQue ? "1" : "0",
              nickname: viewer_nick_name,
              step: aiCount === 0 ? selectedSlide + 1 : `${selectedSlide + 1}-ai${aiCount}`,
              type: "QuestionAnswer",
              // country: location[0] ? location[0].address?.country : "",
              // city: location[0] ? location[0].address?.state_district : "",
              // location: location[0] ? location[0].display_name : "",
              url_params: viewer_url_params,
            }
            // console.log(save_ans);
            const formData = new FormData();
            Object.keys(save_ans).forEach(key => {
              const value = typeof save_ans[key] === "object" ? JSON.stringify(save_ans[key]) : save_ans[key];
              formData.append(key, value);
            });

            dispatch(saveQuizAnsAsync(formData));

            var save_response = {
              type: "QuestionAnswer",
              step: aiCount === 0 ? selectedSlide + 1 : `${selectedSlide + 1}-ai${aiCount}`,
              question: inputText ? inputText : "",
              answered_text: textField,
            }
            dispatch(saveQuizResponse(save_response));

            aiCount++;

          }
        }).catch((e) => {
          console.log(e.message, e);
        });

      } else {
        var save_ans = {
          display_id: viewer_display_id,
          watch_id: save_ans_response[0] ? save_ans_response[0].watch_id : "",
          result: 1,
          question: inputText ? inputText : "",
          answered_text: textField,
          // end: lastQue ? "1" : "0",
          nickname: viewer_nick_name,
          step: aiCount === 0 ? selectedSlide + 1 : `${selectedSlide + 1}-ai${aiCount}`,
          type: "QuestionAnswer",
          // country: location[0] ? location[0].address?.country : "",
          // city: location[0] ? location[0].address?.state_district : "",
          // location: location[0] ? location[0].display_name : "",
          url_params: viewer_url_params,
        }
        // console.log(save_ans);
        const formData = new FormData();
        Object.keys(save_ans).forEach(key => {
          const value = typeof save_ans[key] === "object" ? JSON.stringify(save_ans[key]) : save_ans[key];
          formData.append(key, value);
        });

        dispatch(saveQuizAnsAsync(formData));

        var save_response = {
          type: "QuestionAnswer",
          step: aiCount === 0 ? selectedSlide + 1 : `${selectedSlide + 1}-ai${aiCount}`,
          question: inputText ? inputText : "",
          answered_text: textField,
        }
        dispatch(saveQuizResponse(save_response));

        var nextPage = selectedSlide + 1;

        setTimeout(() => {
          dispatch(updateGoToSlide(nextPage));
        }, 1000);
      }

    }

  }

  return (
    <div ref={questionRef} style={{ display: "flex", justifyContent: 'space-between', flexDirection: "column", height: width > 800 ? height : "400px", }}>
      <EditText
        id={`text-field-input-${id}`}
        value={required && location.pathname.includes("/duonut/") ? aiCount > 0 ? "Followup question: " + inputText + " *" : inputText + " *" : aiCount > 0 ? "Followup question: " + inputText : inputText}
        className='edit-input-text'
        style={{
          backgroundColor: "transparent",
          border: "0",
          outline: "none",
          // display: "flex",
          // alignItems: "center",
          width: "100%",
          color, fontFamily, fontSize: fontSize, fontWeight, fontStyle, textDecoration, textTransform,
          textAlign, lineHeight, letterSpacing, padding
        }}
        onEditMode={() => {
          handleInputFocus();
          // setTimeout(() => document.querySelector(`#text-field-input-${id}`).select(), 100); 
        }}
        onChange={(e) => handleQuestionAnswerTextChange(e)}
        onBlur={handleInputBlur}
        readonly={(location.pathname).includes("/duonut/") || viewType === 3 ? true : false}
      />
      <div style={{ display: "flex", alignItems: viewType === 3 || width < 800 ? "center" : "unset", flexDirection: (viewType === 3 || width < 800) ? "column" : "unset" }}>
        <input type='text' key={`qainput-${selectedSlide + 1}-ai${aiCount}`}
          id={`qainput-${selectedSlide + 1}-ai${aiCount}`}
          style={{
            width: "100%", minHeight: "40px",
            color, fontFamily, fontSize: fontSize, fontStyle, textDecoration, textTransform,
            textAlign, lineHeight, letterSpacing, padding: "4px 10px", boxSizing: "border-box",
            backgroundColor, border: borderBottom ? "0" : border, margin, borderRadius, borderBottom: border
          }}
          onKeyUp={(e) => handleQuestionAnswerInputChange(e)}
          readOnly={location.pathname.includes("/duonut/") ? false : true}
          required={required}
          // maxLength={maxLength}
          // minLength={minLength}
          placeholder={placeholderText}
        />
        <button id="AiSubmitBtn" onClick={() => submitResponse()}
          style={{
            backgroundColor: "#4299e1", color: "white", borderRadius: "6px",
            padding: '1rem', cursor: "pointer", margin: viewType === 3 || width < 800 ? "1rem 0px" : "0px"
          }}>
          {loading ? <img alt="loader" src={require(`../../assets/Images/loader.gif`)} style={{ margin: "auto", height: "1rem" }} />
            : viewType === 3 || width < 800 ? <span style={{ fontSize: "1rem" }}> Next</span> : <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
              <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
            </svg>}
        </button>
      </div>
    </div>
  );
}

export default QuestionAnswer;
