import React, { useState, useEffect, useRef } from 'react';
import './Element.css';
// import { EditText, EditTextarea } from "react-edit-text";
import "react-edit-text/dist/index.css";
import { useSelector, useDispatch } from "react-redux";
import { updateUserActivityElements, updateElementInputFocus } from '../../redux/slices/userActivity';
import { useLocation, } from "react-router-dom";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
import axios from "axios";
import { domain } from "../../services/constants";

const Text = ({
  id,
  elementAttr: {
    color, fontFamily, fontSize, fontWeight, text, formattedText, fontStyle, textDecoration, textTransform, textAlign,
    lineHeight, letterSpacing, backgroundColor, border, margin, padding, borderRadius
  },
  language,
  slideId,
}) => {
  const dispatch = useDispatch();
  const { userActivities, selectedUserActivity, selectedComponent, selectedSlide, textFormData } = useSelector((state) => state.userActivity);
  const { viewType } = useSelector((state) => state.duonutSettings);
  const { save_form_data } = useSelector((state) => state.duonut);
  const location = useLocation();
  const [inputText, setInputText] = useState("");
  const [hyperlinkText, setHyperlinkText] = useState(null);
  const [hyperlinkUrl, setHyperlinkUrl] = useState(null);
  const [linkInputActive, setlinkInputActive] = useState(false);
  const [processedText, setProcessedText] = useState('');

  const [suggestedVar, setSuggestedVar] = useState([]);
  const [popUpPositionX, setPopUpPositionX] = useState(null)
  const [popUpPositionY, setPopUpPositionY] = useState(null)
  const suggestionRef = useRef(null)


  const optionsRef = useRef(null)
  const handleClose = () => {
    setlinkInputActive(false);
    setHyperlinkText(null);
    setHyperlinkUrl(null);
  }
  useOutsideAlerter(optionsRef, handleClose);

  useEffect(() => {
    if (location.pathname.includes("/duonut/") && slideId === `slide-${selectedSlide}` && language !== "en") {
      fetchText(text);
    } else {
      setInputText(text);
    }
  }, [text]);

  const fetchText = async (texts) => {
    const t_text = await translateText(texts, language);
    setInputText(t_text);
  };
  const translateText = async (texts, targetLanguage) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${domain}/duonut/language_translator`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          source: "en",
          target: targetLanguage,
          query: texts,
        },
      });

      if (response.status === 200) {
        // console.log("Translation:", response);
        return response?.data.translation; // return the translated text
      } else {
        // Handle unsuccessful status codes
        return "Error: Translation failed. Please try again.";
      }
    } catch (error) {
      console.error('Error in translation API:', error);
      return "Error translating text. Please try again."; // Handle API errors gracefully
    }
  };

  useEffect(() => {
    let requiredFormate = false;
    const updateTextWithFormatting = (inputText, formattedText) => {
      let updatedText = inputText;
      // console.log(inputText, formattedText);
      if (formattedText?.length > 0) {
        for (const format of formattedText) {
          const { insert, attributes } = format;
          const { font_link, font_color, font_underline } = attributes;
          const formattedInsert = font_underline ? 'underline' : 'none';

          const styledInsert = `<a href=${font_link} target="_blank" style="color: ${font_color}; text-decoration: ${formattedInsert};">${insert}</a>`;

          updatedText = updatedText.replace(insert, styledInsert);
          requiredFormate = true;
        }
      }
      // console.log(updatedText);
      return updatedText;
    };

    var newText = updateTextWithFormatting(text, formattedText);

    if (location.pathname.includes("/duonut/")) {
      newText = newText?.replace(/{{(.*?)}}/g, (match, captured) => {
        const lowerCaptured = captured.toLowerCase();
        // Convert keys in save_form_data to lowercase for case-insensitive check
        const lowerSaveFormData = Object.fromEntries(
          Object.entries(save_form_data).map(([key, value]) => [key.toLowerCase(), value])
        );
        if (lowerSaveFormData.hasOwnProperty(lowerCaptured)) {
          return lowerSaveFormData[lowerCaptured];
        } else {
          return match; // if no replacement found, keep the original placeholder
        }
      })
    }

    if (requiredFormate) {
      setProcessedText(newText);
    } else {
      setProcessedText(inputText);
    }
  }, [inputText, formattedText]);


  const handleInputFocus = () => {
    // console.log("aaaa")
    dispatch(updateElementInputFocus(true));
  }
  const handleInputBlur = (e) => {
    // console.log("vvvv", e, e.target.innerHTML)

    if (userActivities[selectedUserActivity].elements.length >= 1) {
      let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
      const elementAttrib = tempElement?.elementAttributes;
      tempElement = {
        ...tempElement,
        elementAttributes: {
          ...elementAttrib,
          text: e.target.innerHTML,
        },
      };
      // console.log("tempElement", tempElement)
      dispatch(
        updateUserActivityElements({
          selectedActivityIdx: selectedUserActivity,
          selectedElementIdx: selectedComponent.target_id,
          element: tempElement,
        })
      );
    }

    dispatch(updateElementInputFocus(false));

  }

  const handleSelectedText = () => {
    if (!location.pathname.includes("/duonut/")) {
      const selection = window.getSelection();
      // console.log("selection", selection, selection.toString());
      if (selection.rangeCount > 0) {
        const selectedText = selection.toString();
        setHyperlinkText(selectedText);
        const formattedLink = formattedText?.find(format => format.insert === selectedText);
        if (formattedLink) {
          setHyperlinkUrl(formattedLink.attributes.font_link);
        } else {
          setHyperlinkUrl(null);
        }
      } else {
        setHyperlinkText(null);
      }
    }
  }

  const handleHyperlinkUrlChange = (e) => {
    // console.log("dfgf", e);
    setHyperlinkUrl(e.target.value);

    const formattedLink = {
      insert: hyperlinkText,
      attributes: {
        font_link: e.target.value,
        font_color: "inherit",
        font_underline: false
      }
    };

    if (userActivities[selectedUserActivity].elements.length >= 1) {
      let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
      const elementAttrib = tempElement?.elementAttributes;
      const existingFormattedText = elementAttrib?.formattedText;

      if (existingFormattedText?.length > 0) {
        // Check if formattedText already contains an element with the same insert value
        const existingIndex = existingFormattedText?.findIndex(item => item.insert === hyperlinkText);

        if (existingIndex !== -1) {
          // If found, update the existing element
          const updatedFormattedText = [...existingFormattedText];
          updatedFormattedText[existingIndex] = formattedLink;

          tempElement = {
            ...tempElement,
            elementAttributes: {
              ...elementAttrib,
              formattedText: updatedFormattedText,
            },
          };
        } else {
          // If not found, add the new element
          tempElement = {
            ...tempElement,
            elementAttributes: {
              ...elementAttrib,
              formattedText: [...existingFormattedText, formattedLink],
            },
          };
        }

      } else {
        tempElement = {
          ...tempElement,
          elementAttributes: {
            ...elementAttrib,
            formattedText: [formattedLink],
          },
        };
      }

      // console.log("tempElement", tempElement)
      dispatch(
        updateUserActivityElements({
          selectedActivityIdx: selectedUserActivity,
          selectedElementIdx: selectedComponent.target_id,
          element: tempElement,
        })
      );
    }
  }

  const handelLinkIconClick = () => {
    setlinkInputActive(true);
  }

  function selectText(elementId) {
    // console.log("elementId", elementId)
    const element = document.getElementById(elementId);
    const range = document.createRange();
    range.selectNodeContents(element);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  }

  const handlePaste = (e) => {
    // console.log("paste")
    e.preventDefault();
    const text = (e.clipboardData || window.clipboardData).getData('text');
    document.execCommand('insertText', false, text);
  };

  const handleTextChange = (e) => {
    // console.log(e);

    const inputValue = e.target.textContent;

    // Check if "@" is entered
    const atIndex = inputValue.indexOf("@");
    // console.log(atIndex, textFormData)
    if (atIndex !== -1 && Object.keys(textFormData).length > 0) {
      // Extract the text after "@" until the cursor position
      const textAfterAt = inputValue.substring(atIndex + 1, e.target.selectionStart);

      // Filter formData keys that match the entered text
      const matchingKeys = Object.keys(textFormData).filter((key) =>
        key.toLowerCase().includes(textAfterAt.toLowerCase())
      );

      const cursorRect = e.target.getBoundingClientRect();
      // console.log(e, cursorRect)

      setPopUpPositionX(cursorRect.width);
      setPopUpPositionY(cursorRect.height);

      // Display suggestions
      // console.log(matchingKeys)
      setSuggestedVar(matchingKeys);
    } else {
      // Clear suggestions if "@" is not present
      setSuggestedVar([]);
    }

  };

  const handleChooseSuggestion = (selectedSuggestion) => {
    // console.log("selectedSuggestion", selectedSuggestion)
    // Get the current input value
    const currentValue = processedText;
    // console.log("currentValue", currentValue)

    // Find the position of "@" in the current value
    const atIndex = currentValue.indexOf("@");

    // Replace the text after "@" until the cursor position with the selected suggestion
    const updatedValue = currentValue.substring(0, atIndex) + `{{${selectedSuggestion}}}` + currentValue.substring(atIndex + 1);

    // console.log("selectedSuggestion", updatedValue)

    setProcessedText(updatedValue);

    if (userActivities[selectedUserActivity].elements.length >= 1) {
      let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
      const elementAttrib = tempElement?.elementAttributes;
      tempElement = {
        ...tempElement,
        elementAttributes: {
          ...elementAttrib,
          text: updatedValue,
        },
      };
      // console.log("tempElement", tempElement)
      dispatch(
        updateUserActivityElements({
          selectedActivityIdx: selectedUserActivity,
          selectedElementIdx: selectedComponent.target_id,
          element: tempElement,
        })
      );
    }

    // Clear suggestions
    setSuggestedVar([]);

  };


  return (
    <>
      <div
        id={`text-input-${id}`}
        className='edit-input-text'
        style={{
          backgroundColor: "transparent",
          border: "0",
          outline: "none",
          color, fontFamily, fontSize: fontSize, fontWeight, fontStyle, textDecoration, textTransform,
          textAlign, lineHeight, letterSpacing,
          backgroundColor: backgroundColor ? backgroundColor : "transparent",
          border: border ? border : "0", margin, padding,
          borderRadius,
        }}
        onClick={handleSelectedText}
        onFocus={() => { handleInputFocus(); setTimeout(() => selectText(`text-input-${id}`), 100); }}
        // onDoubleClickCapture={() => { handleInputFocus(); setTimeout(() => selectText(`text-input-${id}`), 100); }}
        contentEditable={(location.pathname).includes("/duonut/") || viewType === 3 ? false : true}
        onInput={handleTextChange}
        onBlur={handleInputBlur}
        onPaste={handlePaste}
        suppressContentEditableWarning={true}
        dangerouslySetInnerHTML={{ __html: processedText }}
      >
        {/* {inputText} */}
      </div>
      {hyperlinkText && <div><i className="fa fa-link" aria-hidden="true" ref={optionsRef}
        style={{
          position: "absolute",
          left: "50px",
          right: 0,
          margin: "auto",
          width: "20px",
          padding: "8px",
          top: "-45px",
          fontSize: "1.5rem",
          cursor: "pointer",
          backgroundColor: "white",
          boxShadow: "rgba(0, 0, 0, 0.08) 0px 2px 4px",
          borderRadius: "5px"
        }}
        onClick={handelLinkIconClick}></i></div>}
      {linkInputActive && <input ref={optionsRef}
        type="text"
        placeholder="Enter URL"
        value={hyperlinkUrl}
        onChange={handleHyperlinkUrlChange}
        autoFocus
        style={{
          position: "absolute",
          left: 0,
          right: 0,
          margin: "auto",
          width: "250px",
          padding: "12px",
          top: "-45px",
          fontSize: "1rem",
          backgroundColor: "white",
          boxShadow: "rgba(0, 0, 0, 0.08) 0px 2px 4px",
          borderRadius: "5px"
        }}
      />}
      {suggestedVar.length > 0 && (
        <div ref={suggestionRef} style={{
          position: "absolute", backgroundColor: "white",
          // transform: `translate(${popUpPositionX / 2 - 100}px, ${popUpPositionY}px)`,
          padding: "10px", boxShadow: "rgba(0, 0, 0, 0.08) 0px 2px 4px", zIndex: "999",
          left: "0",
          right: 0,
          margin: "auto",
          width: "15rem",
        }}>
          <div>Recall information from...</div>
          {suggestedVar.map((item, i) => {
            return <div key={i}
              style={{ padding: "4px", paddingLeft: "10px", backgroundColor: "#e7e4e4", borderRadius: "4px", margin: "4px", cursor: "pointer" }}
              onClick={() => handleChooseSuggestion(item)}>{item}</div>
          })}
        </div>
      )}
    </>
  );
}

export default Text;
