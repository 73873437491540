import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { updateUserActivityElements } from '../../../redux/slices/userActivity';
import AttributeLevelsOption from './AttributeLevelsOption';

export default function AttributeOptionsProperty() {

    const { selectedUserActivity, userActivities, selectedComponent } = useSelector((state) => state.userActivity);
    const [options, setOptions] = useState([]);
    const dispatch = useDispatch()

    useEffect(() => {
        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            setOptions(tempElement?.elementAttributes?.options);
        }
    }, [selectedUserActivity, userActivities[selectedUserActivity], selectedComponent.target_id]);
    // console.log(options);
    const addOption = () => {
        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            const elementAttrib = tempElement?.elementAttributes;
            tempElement = {
                ...tempElement,
                elementAttributes: {
                    ...elementAttrib,
                    options: [...elementAttrib.options, { attribute: '', levels: [{ label: "" }, { image: "" }] }],
                },
            };
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }


    return (
        <div className="EditorRowContainer" style={{ flexDirection: "column", alignItems: "flex-start" }}>
            <hr style={{ border: '0.5px solid #9e9e9e33', margin: '10px 0px 10px 0px', width: '100%' }} />
            <div style={{ margin: "0.5rem 0rem", fontWeight: "700" }}>Attributes and Levels</div>
            {options?.map((item, i) => {
                return <><AttributeLevelsOption totalOptions={options} item={item} idx={i} />
                    <hr style={{ width: "100%", margin: "10px 0px" }} /></>
            })}
            <button type='submit' className='addoptionbtn' onClick={() => addOption()} >Add Attribute</button>
        </div>
    )
}

