import React, { useEffect, useState, useRef } from "react";
import Element from "../Elements/Element";
import "./Editors.css";
import Moveable from 'react-moveable';
import { useSelector, useDispatch } from "react-redux";
import { updateSelectedComponent, updateUserActivityElements, deleteUserActivityElements, addElementInUserActivity, updateThumbnailImg, updateUserActivity } from '../../redux/slices/userActivity';
import { updateViewType } from "../../redux/slices/duonutSettings";
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
import VerticalBars1 from '../../assets/icons/myduonut/vertical-bars-1.svg'
import { toggleCopySetting, updateCopyStyle, updateCopyType, updateCopyHoverStyle } from "../../redux/slices/CopyStyleReducer";
import html2canvas from "html2canvas";
import { domain } from "../../services/constants";
import axios from "axios";
import { useLocation } from "react-router-dom";
import ElementModal from "../Modals/ElementModal/ElementModal";
import "intro.js/introjs.css";

var firstTime = true;

const Editors = ({ master }) => {
    const elementref = useRef(null);
    const elementref1 = useRef(null);

    const dispatch = useDispatch();
    const { userActivities, selectedUserActivity, selectedComponent, elementInputFocus } = useSelector((state) => state.userActivity);
    const { viewType } = useSelector((state) => state.duonutSettings);
    const { active: isCopyStyle, copyType, copyStyle, copyHoverStyle } = useSelector((state) => state.CopySetting.copyStyleSetting);
    const [masterData, setmasterData] = useState(master?.elements);

    const [showOptions, setShowOptions] = useState(false);
    const [clickedTarget, setclickedTarget] = useState(null);
    const [parentTarget, setparentTarget] = useState(null);
    const location = useLocation();
    const [paramsid, setparamsid] = useState("");
    const [idNum, setidNum] = useState(0);
    const [showElementModal, setShowElementModal] = useState(false);
    useEffect(() => {
        const activity = userActivities[selectedUserActivity];
        if (activity?.elements.length > 0) {
            var largestId = activity?.elements.reduce((max, item) => item.id > max ? item.id : max, activity?.elements[0].id);
            setidNum(largestId);
            // console.log("The largest id is: " + largestId);
        }
    }, [userActivities, selectedUserActivity]);

    // useEffect(() => {

    //     const target = document.getElementById(`element-${0}`);
    //     // console.log("target", target, masterData[0]);

    //     if (target) {
    //         dispatch(
    //             updateSelectedComponent({
    //                 type: masterData[0].elementType,
    //                 name: masterData[0].elementType,
    //                 target: target,
    //                 target_id: 0,
    //             })
    //         );
    //     }
    // }, [selectedUserActivity,document.getElementById(`element-${0}`)]);

    useEffect(() => {

        // Get the current pathname from the window location
        const pathname = location.pathname;

        // Split the pathname into an array of words
        const words = pathname.split('/');

        // Get the last word (assuming the URL ends with a word)
        const lastWord = words[words.length - 1];

        // Use a regular expression to match and capture the numeric part of the URL
        const matchResult = pathname.match(/\/design\/(\d+)/);

        // Check if there is a match and retrieve the captured number
        if (matchResult && matchResult[1]) {
            const extractedNumber = parseInt(matchResult[1]);
            // console.log('Extracted Number:', extractedNumber);
            setparamsid(extractedNumber);
        }
        // Log or use the last word as needed
        // console.log('Last word:', lastWord);

    }, [location.pathname]);

    const optionsRef = useRef(null)
    const handleClose = () => setShowOptions(false)
    useOutsideAlerter(optionsRef, handleClose);

    const activityRef = useRef()
    let timeout;


    // console.log("editor", userActivities, selectedUserActivity, master, selectedComponent);


    const update = () => {
        // console.log("update thumbnail", selectedUserActivity)

        if (paramsid) {
            html2canvas(activityRef.current, {
                allowTaint: true,
                useCORS: true,
            }).then(function (canvas) {
                canvas.getContext("2d");
                var imgData = canvas.toDataURL("image/jpeg", 1.0);
                const image_data = {
                    duonut_id: paramsid,
                    name: `duonut_thumbnail_${selectedUserActivity}`,
                    uri: imgData,
                }
                axios({
                    method: "POST",
                    url: `${domain}/duonut/upload_files`,
                    headers: {
                        "Content-Type": "application/json"
                    },
                    data: JSON.stringify(image_data),
                }).then((res) => {
                    //console.log(res.data.file_url);
                    dispatch(
                        updateThumbnailImg({
                            selectedActivityIdx: selectedUserActivity,
                            thumbnail: res.data.file_url,
                        })
                    );
                }).catch((e) => {
                    console.log(e.message, e);
                });

                clearTimeout(timeout);
            });
        }
    };

    useEffect(() => {
        if (activityRef.current === null) return;
        if (!master) return;

        clearTimeout(timeout);
        timeout = setTimeout(() => {
            update();
        }, 1000);
        return () => {
            clearTimeout(timeout);
        };
    }, [master?.elements, userActivities[selectedUserActivity]?.backgroundColor, userActivities[selectedUserActivity]?.backgroundImage, paramsid]);

    useEffect(() => {
        const handleClick = (event) => {
            // Get the target element
            const target = event.target;
            // Get the parent element of the target
            const parentTarget = target.parentNode;

            // console.log('Clicked element:', target);
            // console.log('Parent element:', parentTarget);
            setclickedTarget(target);
            setparentTarget(parentTarget);
        };

        // Add event listener when component mounts
        document.addEventListener('mousedown', handleClick);

        // Clean up by removing the event listener when component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, []);

    useEffect(() => {
        const handleKeyDown = (event) => {

            // console.log("1111", clickedTarget, parentTarget)
            // console.log("222", !clickedTarget?.closest('.designMainContainer'), !parentTarget?.closest('.designMainContainer'),)

            if (!clickedTarget?.closest('.designMainContainer') && !parentTarget?.closest('.designMainContainer')) {
                // If the active element is not within the designContainer, return
                // console.log("333")
                return;
            }

            // console.log("del", event, selectedComponent, elementInputFocus)
            if ((event.keyCode === 46 || event.key === 'Delete' || event.keyCode === 8 || event.key === 'Backspace') && selectedComponent.target_id >= 0 && !elementInputFocus) {
                handelElementDelete();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [selectedComponent, elementInputFocus, clickedTarget, parentTarget]);

    const handelSelectElement = (element, id) => {
        const target = document.getElementById(`element-${id}`);
        // console.log("target", target.clientWidth, element)
        dispatch(
            updateSelectedComponent({
                type: element.elementType,
                name: element.elementType,
                target: target,
                target_id: id,
            })
        );
    }

    useEffect(() => {
        // console.log("11")
        firstTime = true;

        var elements = userActivities[selectedUserActivity]?.elements;
        if (elements?.length > 0) {
            setTimeout(() => {
                const target = document.getElementById(`element-${0}`);
                // console.log("target", target, elements.length, elements)
                dispatch(
                    updateSelectedComponent({
                        type: elements[0].elementType,
                        name: elements[0].elementType,
                        target: target,
                        target_id: 0,
                    })
                );
            }, 500); //miliseconds
        } else {
            dispatch(
                updateSelectedComponent({
                    type: "Slide",
                    name: "Container",
                    target: "",
                    target_id: null,
                })
            );
        }

    }, [selectedUserActivity]);

    useEffect(() => {
        // console.log("22", firstTime)
        var elements = userActivities[selectedUserActivity]?.elements;
        if (!firstTime && elements?.length > 0) {
            setTimeout(() => {
                const target = document.getElementById(`element-${elements?.length - 1}`);
                // console.log("target", target, elements.length, elements)
                dispatch(
                    updateSelectedComponent({
                        type: elements[elements.length - 1].elementType,
                        name: elements[elements.length - 1].elementType,
                        target: target,
                        target_id: elements?.length - 1,
                    })
                );
            }, 500); //miliseconds
        } else {
            dispatch(
                updateSelectedComponent({
                    type: "Slide",
                    name: "Container",
                    target: "",
                    target_id: null,
                })
            );
        }

        firstTime = false;

    }, [userActivities[selectedUserActivity]?.elements?.length]);

    useEffect(() => {
        if (viewType === 3) {
            setmasterData(master?.elements?.slice().sort((a, b) => a.posY - b.posY))
        } else {
            setmasterData(master?.elements)
        }
    }, [master, viewType]);

    const handleClickMainContainer = (e) => {
        if (e.target.classList.contains("elementsContainer")) {
            dispatch(
                updateSelectedComponent({
                    type: "Slide",
                    name: "Container",
                    target: "",
                    target_id: null,
                })
            );
        }
    };


    useEffect(() => {
        dispatch(
            updateSelectedComponent({
                type: "Slide",
                name: "Container",
                target: "",
                target_id: null,
            })
        );
    }, [selectedUserActivity, viewType]);

    const handleDragStyleChange = (data) => {
        // console.log(data);
        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            // console.log("tempelement", tempElement)
            tempElement = {
                ...tempElement,
                posX: data.left,
                posY: data.top,
            };
            // console.log("tempElement", tempElement)
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }

    const handleResizeStyleChange = (data) => {
        // console.log(data);
        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            if (selectedComponent.type === "Image" || selectedComponent.type === "Video" || selectedComponent.type === "Audio" || selectedComponent.type === "Iframe") {
                tempElement = {
                    ...tempElement,
                    width: data.width,
                    height: data.height,
                    // transform: data.transform,
                };
                // console.log("tempElement", tempElement)
                dispatch(
                    updateUserActivityElements({
                        selectedActivityIdx: selectedUserActivity,
                        selectedElementIdx: selectedComponent.target_id,
                        element: tempElement,
                    })
                );
            } else {
                tempElement = {
                    ...tempElement,
                    width: data.width,
                    // transform: data.transform,
                };
                // console.log("tempElement", tempElement)
                dispatch(
                    updateUserActivityElements({
                        selectedActivityIdx: selectedUserActivity,
                        selectedElementIdx: selectedComponent.target_id,
                        element: tempElement,
                    })
                );
            }
        }

    }

    const handleScaleStyleChange = (data) => {
        // console.log(data);

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            tempElement = {
                ...tempElement,
                transform: data.transform,
            };
            // console.log("tempElement", tempElement)
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }

    const handleRotateStyleChange = (data) => {
        // console.log(data);
        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            tempElement = {
                ...tempElement,
                transform: data.transform
            };
            // console.log("tempElement", tempElement)
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    }

    const handelElementDelete = () => {
        dispatch(
            deleteUserActivityElements({
                selectedActivityIdx: selectedUserActivity,
                selectedElementIdx: selectedComponent.target_id,
            })
        );
        dispatch(
            updateSelectedComponent({
                type: "Slide",
                name: "Container",
                target: "",
                target_id: null,
            })
        );
    }

    const handelElementDuplicate = (element, index) => {
        dispatch(addElementInUserActivity({
            activity: { ...element, posY: element.posY + 10, posX: element.posX + 10, id: idNum + 1 },
            selectedActivityIdx: selectedUserActivity
        }));
        setShowOptions(false);
    }

    const handelElementMove = (event) => {
        // Calculate the distance moved by the mouse
        const { clientX, clientY } = event;

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];
            // console.log("tempelement", tempElement)
            tempElement = {
                ...tempElement,
                posX: clientX - tempElement.posX,
                posY: clientY - tempElement.posY,
            };
            // console.log("tempElement", tempElement)
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }
    };


    const popUpHandler = (e) => {
        setShowOptions(!showOptions);

    }
    const handleCopyStyle = (element) => {
        dispatch(toggleCopySetting(true));
        dispatch(updateCopyType(selectedComponent.type));
        setShowOptions(false);

        if (selectedComponent.type === "Image" || selectedComponent.type === "Video" || selectedComponent.type === "Audio" || selectedComponent.type === "Iframe") {
            dispatch(updateCopyStyle(element.elementAttributes));
        } else if (selectedComponent.type === "Button") {
            dispatch(updateCopyStyle(element.elementAttributes));
            dispatch(updateCopyHoverStyle(element.elementHoverAttributes));
        } else {
            dispatch(updateCopyStyle(element.elementAttributes));
        }
    };

    const handlePasteStyle = () => {

        if (userActivities[selectedUserActivity].elements.length >= 1) {
            let tempElement = userActivities[selectedUserActivity].elements[selectedComponent.target_id];

            if (copyType === "Image") {
                const elementAttrib = tempElement?.elementAttributes;
                tempElement = {
                    ...tempElement,
                    elementAttributes: {
                        ...elementAttrib,
                    },
                };
            } else if (copyType === "Button") {
                tempElement = {
                    ...tempElement,
                    elementAttributes: {
                        ...copyStyle,
                        text: tempElement?.elementAttributes?.text,
                    },
                    elementHoverAttributes: {
                        ...copyHoverStyle,
                        text: tempElement?.elementHoverAttributes?.text,
                    },
                };
            } else {
                tempElement = {
                    ...tempElement,
                    elementAttributes: {
                        ...copyStyle,
                        text: tempElement?.elementAttributes?.text,
                    },
                };
            }
            // console.log("tempElement", tempElement)
            dispatch(
                updateUserActivityElements({
                    selectedActivityIdx: selectedUserActivity,
                    selectedElementIdx: selectedComponent.target_id,
                    element: tempElement,
                })
            );
        }

        dispatch(toggleCopySetting(false));
        dispatch(updateCopyType(""));
        setShowOptions(false);

    };


    const dragItem = useRef(null);
    const dragOverItem = useRef(null);

    const dragStart = (e, position) => {
        // console.log('start');
        dragItem.current = position;
    };

    const dragEnter = (e, position) => {
        dragOverItem.current = position;
    };

    const drop = (e) => {
        if (viewType === 3) {
            const dragItemIndex = dragItem.current;
            const dragOverItemIndex = dragOverItem.current;

            const updatedElements = [...masterData];
            const draggedItem = updatedElements[dragItemIndex];

            // console.log(updatedElements)

            // Remove the dragged item
            updatedElements.splice(dragItemIndex, 1);

            // Insert the dragged item at the new position
            updatedElements.splice(dragOverItemIndex, 0, draggedItem);

            // Update the order property
            const reorderedElements = updatedElements.map((el, index) => ({
                ...el,
                order: index + 1,
            }));

            // setElements(reorderedElements);
            dispatch(
                updateUserActivity({
                    elements: reorderedElements,
                })
            );

            dragItem.current = null;
            dragOverItem.current = null;

        }
    };
    const handelElementModal = () => {
        // console.log("element Modal");
        setShowElementModal(true);
    }
    const closeModal = () => {
        // Close the modal programmatically by setting display to 'none'
        // if (elementref.current) {
        //     elementref.current.style.display = 'none';
        // }
        elementref1.current.click();
    };
    return (
        <>
            <div key={selectedComponent}
                className="designMainContainer"
                onClick={(e) => handleClickMainContainer(e)}
            >
                <div className="designBarContainer" data-intro="You can check how will your Duonut look in the mobile view.">
                    {/* <div className={viewType === 1 ? "selectedDesignBarIcon" : "designBarIcon"}
                        onClick={() => dispatch(updateViewType(1))}>
                        <i className="fa fa-arrows-alt" aria-hidden="true"></i>
                    </div> */}
                    <div className={viewType === 2 ? "selectedDesignBarIcon" : "designBarIcon"}
                        onClick={() => dispatch(updateViewType(2))}>
                        <i className="fa fa-desktop" aria-hidden="true" ></i>
                    </div>
                    <div className={viewType === 3 ? "selectedDesignBarIcon" : "designBarIcon"}
                        style={{ marginRight: "2rem" }}
                        onClick={() => dispatch(updateViewType(3))}>
                        <i className="fa fa-mobile" style={{ fontSize: "1.6rem" }} aria-hidden="true" ></i>
                    </div>
                </div>
                <div className="elementsContainer" id="noElement" ref={activityRef} data-intro="This is where the magic happens, you can add elements and arrange your canvas as per your needs."
                    style={viewType === 3 ? {
                        backgroundColor: userActivities[selectedUserActivity]?.backgroundColor ? userActivities[selectedUserActivity].backgroundColor : "white",
                        backgroundImage: userActivities[selectedUserActivity]?.backgroundImage ? `linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(${userActivities[selectedUserActivity]?.backgroundImage})` : "none",
                        width: "478px", overflow: "auto", border: selectedComponent.type === "Slide" ? "2px solid #3184c9" : "none"
                    }
                        : {
                            backgroundColor: userActivities[selectedUserActivity]?.backgroundColor ? userActivities[selectedUserActivity].backgroundColor : "white",
                            backgroundImage: userActivities[selectedUserActivity]?.backgroundImage ? `linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(${userActivities[selectedUserActivity]?.backgroundImage})` : "none",
                            border: selectedComponent.type === "Slide" ? "2px solid #3184c9" : "none"
                        }}>
                    <Moveable
                        target={viewType === 3 ? null : selectedComponent.target}
                        container={null}
                        origin={false}

                        bounds={{ left: 0, top: 0, width: "1024px", height: "575px" }}

                        /* Resize event edges */
                        edge={false}
                        edgeDraggable={true}
                        // edge={["s", "e"]}

                        /* draggable */
                        draggable={true}
                        throttleDrag={0}
                        onDragStart={({ target, clientX, clientY }) => {
                            // console.log("onDragStart", target);
                        }}
                        onDrag={({
                            target, beforeDelta, beforeDist, left, top, right, bottom, delta, dist, transform, clientX, clientY,
                        }) => {

                            const container = document.querySelector('.elementsContainer');
                            const containerRect = container.getBoundingClientRect();
                            const targetRect = target.getBoundingClientRect();

                            // Calculate the boundaries considering the container's transformation
                            const minX = 0;
                            const minY = 0;
                            const maxX = (containerRect.width / 0.80) - (targetRect.width / 0.80); // Adjusted for scale
                            const maxY = (containerRect.height / 0.80) - (targetRect.height / 0.80); // Adjusted for scale

                            // Ensure the target stays within the boundaries
                            const boundedLeft = Math.min(Math.max(left, minX), maxX);
                            const boundedTop = Math.min(Math.max(top, minY), maxY);

                            // Update the position
                            target.style.left = `${boundedLeft}px`;
                            target.style.top = `${boundedTop}px`;

                            // Call your handler function with the updated position
                            const dragData = {
                                left: boundedLeft,
                                top: boundedTop
                            };
                            handleDragStyleChange(dragData);
                        }}
                        onDragEnd={({ target, isDrag, clientX, clientY }) => {
                            // console.log("onDragEnd", target, isDrag);
                        }}

                        /* When resize or scale, keeps a ratio of the width, height. */
                        keepRatio={false}

                        /* resizable*/
                        /* Only one of resizable, scalable, warpable can be used. */
                        resizable={true}
                        // resizable={{
                        //   // edge: ["e", "w"],
                        //   renderDirections: ["e", "w", "n", "s"],
                        // }}
                        throttleResize={0}
                        onResizeStart={({ target, clientX, clientY }) => {
                            // console.log("onResizeStart", target);
                        }}
                        onResize={({
                            target, width, height, dist, delta, direction, clientX, clientY, transform, drag,
                        }) => {
                            console.log("onResize", target, dist, delta, direction, clientX, clientY, transform);
                            delta[0] && (target.style.width = `${width}px`);
                            // console.log("image", selectedComponent.targetName);
                            if (selectedComponent.type === "Image" || selectedComponent.type === "Video" || selectedComponent.type === "Audio" || selectedComponent.type === "Iframe") {

                                // console.log("image");
                                delta[1] && (target.style.height = `${height}px`);
                            }
                            // target.style.transform = drag.transform;
                            // Increase font size proportional to the increase in width or height- 100 is original width
                            // target.style.fontSize = `${16 * (width / 500)}px`;
                            const resizeData = {
                                width: width,
                                height: height,
                                fontSize: (16 * (width / 200)),
                                // transform: drag.transform,
                            }
                            handleResizeStyleChange(resizeData);
                        }}
                        onResizeEnd={({ target, isDrag, clientX, clientY }) => {
                            // console.log("onResizeEnd", target, isDrag);
                        }}

                        /* scalable */
                        /* Only one of resizable, scalable, warpable can be used. */
                        scalable={false}
                        // triggerAblesSimultaneously={true}
                        // scalable={{
                        //   edge: [],
                        //   renderDirections: ["nw", "ne", "se", "sw"],
                        // }}
                        throttleScale={0}
                        onScaleStart={({ target, clientX, clientY }) => {
                            // console.log("onScaleStart", target);
                        }}
                        onScale={({
                            target, scale, dist, delta, transform, clientX, clientY, drag
                        }) => {
                            // console.log("onScale scale", scale);
                            target.style.transform = drag.transform;
                            const scaleData = {
                                transform: drag.transform,
                            }
                            handleScaleStyleChange(scaleData);
                        }}
                        onScaleEnd={({ target, isDrag, clientX, clientY }) => {
                            // console.log("onScaleEnd", target, isDrag);
                        }}

                        /* rotatable */
                        rotatable={true}
                        throttleRotate={0}
                        onRotateStart={({ target, clientX, clientY }) => {
                            // console.log("onRotateStart", target);
                        }}
                        onRotate={({
                            target, delta, dist, transform, clientX, clientY,
                        }) => {
                            // console.log("onRotate", dist);
                            target.style.transform = transform;
                            const rotateData = {
                                transform: transform,
                            }
                            handleRotateStyleChange(rotateData);
                        }}
                        onRotateEnd={({ target, isDrag, clientX, clientY }) => {
                            // console.log("onRotateEnd", target, isDrag);
                        }}

                        ///////////snap////////////////
                        snappable={true}
                        verticalGuidelines={[512]}
                        horizontalGuidelines={[287]}

                        isDisplaySnapDigit={true}
                        isDisplayInnerSnapDigit={false}
                        snapGap={true}
                        snapDirections={{ "top": true, "left": true, "bottom": true, "right": true, "center": true, "middle": true }}
                        elementSnapDirections={{ "top": true, "left": true, "bottom": true, "right": true, "center": true, "middle": true }}
                        snapThreshold={5}
                        maxSnapElementGuidelineDistance={Infinity}
                        elementGuidelines={master?.elements.map((element, index) => `.element-${index}`).filter((element, index) => index !== selectedComponent?.target_id)}
                    />
                    {masterData?.map((element, index) => {
                        return (<>
                            <div
                                key={element.id}
                                id={`element-${index}`}
                                className={`element-${index} ${element?.animation}`}
                                style={viewType === 3 ?
                                    element.width < 350 ?
                                        {
                                            top: "unset", left: "unset",
                                            width: element.width, height: element.height,
                                            position: "unset", transform: "unset",
                                            margin: "1rem auto",
                                            order: element?.order ? element?.order : index + 1,
                                            border: selectedComponent.target_id === index ? "1px solid rgb(49, 132, 201)" : "none",
                                            cursor: 'all-scroll', padding: "15px"
                                        }
                                        : {
                                            top: "unset", left: "unset",
                                            width: "auto", height: element.elementType === "Iframe" ? "100%" : "auto",
                                            position: "unset", transform: "unset",
                                            margin: "5px",
                                            order: element?.order ? element?.order : index + 1,
                                            border: selectedComponent.target_id === index ? "1px solid rgb(49, 132, 201)" : "none",
                                            cursor: 'all-scroll', padding: "15px"
                                        }
                                    : {
                                        position: 'absolute',
                                        zIndex: element.zIndex,  //selectedComponent.target_id === index ? "99999" : element.zIndex,
                                        left: element.posX,
                                        top: element.posY,
                                        height: element.height,
                                        width: element.width,
                                        transform: element.transform,
                                        cursor: 'all-scroll'
                                    }

                                }
                                onClick={(e) => { handelSelectElement(element, index) }}
                                tabIndex={0}
                                // onMouseEnter={(e) => { handelSelectElement(element, index) }}
                                draggable
                                onDragStart={(e) => dragStart(e, index)}
                                onTouchStart={(e) => dragStart(e, index)}
                                onDragOver={(e) => dragEnter(e, index)}
                                onTouchMove={(e) => dragEnter(e, index)}
                                onDragEnd={drop}
                                onTouchEnd={drop}
                            >
                                {selectedComponent.target_id === index && <div style={{
                                    position: 'absolute',
                                    top: element.posY > 40 ? "-40px" : element.posY + selectedComponent.target.clientHeight < 530 ? selectedComponent.target.clientHeight + 5 : selectedComponent.target.clientHeight - 50,
                                    right: "0",
                                    backgroundColor: "white",
                                    borderRadius: "20px",
                                    padding: "6px 4px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: "1.2rem",
                                    color: "#0085f3",
                                    boxShadow: "rgba(61, 59, 59, 0.1) 0px 2px 10px",
                                    zIndex: "999999"
                                }}>
                                    <i class="fa fa-plus" aria-hidden="true"
                                        style={{ margin: "0px 8px", cursor: "pointer" }} onClick={() => { elementref.current.click(); handelElementModal() }}></i>
                                    {/* <p style={{ margin: "0px 8px", cursor: "pointer" }} onClick={() => { elementref.current.click(); handelElementModal() }}>
                                        Insert
                                    </p> */}
                                    {/* <i className="fa fa-arrows" aria-hidden="true"
                                        style={{ margin: "0px 8px", cursor: "pointer" }} onDrag={(e) => handelElementMove(e)}></i> */}
                                    {/* <i className="fa fa-arrows-alt" aria-hidden="true"
                                        style={{ margin: "0px 8px", cursor: "pointer" }} onDrag={(e) => handelElementMove(e)}></i> */}
                                    <i className="fa fa-trash-o" aria-hidden="true"
                                        style={{ margin: "0px 8px", cursor: "pointer" }} onClick={() => handelElementDelete()}></i>
                                    <div style={{ margin: "0px 6px", padding: "0px 4px", cursor: "pointer" }}
                                        onClick={e => popUpHandler(e)}>
                                        <img src={VerticalBars1} loading="lazy"
                                            style={{ cursor: 'pointer', height: "1rem", paddingTop: "3px" }}
                                        />
                                    </div>

                                </div>}
                                <Element element={element} slideId={`slide-${selectedUserActivity}`} />
                            </div>

                            {(showOptions && selectedComponent.target_id === index) && <div ref={optionsRef}
                                className="PMenuOptions-1"
                                style={{
                                    top: element.posY > 40 ? (element.posY + selectedComponent.target.clientHeight) < 530 ? element.posY : element.posY - 70 : (element.posY + selectedComponent.target.clientHeight) < 530 ? element.posY + selectedComponent.target.clientHeight : element.posY + selectedComponent.target.clientHeight - 100,
                                    left: element.posX + selectedComponent.target.clientWidth - 150,
                                }}>
                                <div className="mainpopup">
                                    <ul>
                                        {selectedComponent.type !== "Image" && <li className='item' onClick={() => handleCopyStyle(element)}>
                                            <span style={{ fontSize: "18px" }}>Copy Style</span>
                                        </li>}
                                        {(selectedComponent.type === copyType && isCopyStyle) && <li className='item'
                                            onClick={() => handlePasteStyle(element)}>
                                            <span style={{ fontSize: "18px" }}>Paste Style</span>
                                        </li>}
                                        <li className='item' onClick={() => handelElementDuplicate(element, index)}>
                                            <span style={{ fontSize: "18px" }}>Duplicate</span>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                            }
                        </>
                        );
                    })}
                </div>
                {/* {showElementModal &&  */}
                <>
                    <button ref={elementref} style={{ display: "none" }} type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#element"></button>
                    <div className="modal fade" style={{ backgroundColor: "#1e1d1d6b", zIndex: "99999" }} id="element" tabIndex="-1" aria-labelledby="elementLabel" aria-hidden="true">
                        <div className="modal-dialog" style={{ maxWidth: "100%", maxHeight: "100%" }}>
                            <div className="modal-content" style={{ margin: "auto", backgroundColor: "white", color: "black", border: "2px solid gray", borderRadius: "15px", width: "50vw" }}>
                                <div className="modal-body row">
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        borderBottom: "1px solid #ccc", paddingBottom: "10px"
                                    }}>
                                        <h4>Elements</h4>
                                        <button ref={elementref1} style={{ width: "5rem", height: "2rem", display: "flex", alignItems: "center", justifyContent: "center" }} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        {/* <h2 style={{ margin: "2vh 0vh", borderBottom: "1px solid grey" }}> Panel</h2> */}

                                    </div>
                                    <ElementModal closeModal={closeModal} />
                                </div>
                            </div>
                        </div>
                    </div>

                </>
                {/* } */}
            </div >
        </>
    );
}

export default Editors;