import React, { useState } from "react";
import axios from "axios";

import { domain } from "../services/constants";
import { useDispatch } from "react-redux";
import { getUserDetails } from "../services/auth";
import { updateIsLoggedIn, updateUserAccountType, updateUserProAccess, updateUserTrialDays, updateTotalCredit, updateCredit } from "../redux/slices/user";
import { closeModal } from "../redux/slices/formDetails";
import { useMsal } from '@azure/msal-react';
import { useLocation } from "react-router-dom";
import { defaultDuonut } from "../services/activity";
import { saveAiNewAsync1 } from "../redux/slices/AiNewReducer";
import { getMyDuonuts } from "../services/duonut";

export default function MicrosoftButton({ text, setisLogIn }) {
    const dispatch = useDispatch();
    // const navigate = useNavigate();
    const location = useLocation();

   const { instance } = useMsal();

    const handleMicrosoftLogin = () => {
        instance.loginPopup({
            scopes: ['openid', 'profile', 'User.Read']
        }).then(result => {
            // console.log(result)
            let data = {
                access_token: result.accessToken,
            };
            axios.post(`${domain}/account/microsoft_auth_callback`, data).then(res => {
                // console.log(res)
                let response_data = res.data;
                localStorage.setItem("access", response_data.access_token);
                localStorage.setItem("refresh", response_data.refresh_token);
                localStorage.setItem("withMicrosoft", true);

                setTimeout(() => {
                    dispatch(closeModal());
                    setisLogIn(true);
                    getUserDetails((err, userDetails) => {
                        if (err) {
                            setisLogIn(false);
                            return
                        };

                        const token = localStorage.getItem("access");
                        const state = sessionStorage.getItem('state');
                        const canvaId = sessionStorage.getItem('canvaId');

                        localStorage.setItem("userName", userDetails.data.name);
                        localStorage.setItem("userEmail", userDetails.data.email);
                        localStorage.setItem("userId", userDetails.data.id);
                        localStorage.setItem("appId", userDetails.data.app_id);
                        localStorage.setItem("subDomain", userDetails.data.sub_domain);
                        localStorage.setItem("proUser", userDetails.data.pro_user);
                        localStorage.setItem("userProfilePicture", userDetails.data.profile_picture);
                        localStorage.setItem("userType", userDetails.data.user_type);
                        localStorage.setItem("expireDate", userDetails.data.expire_date);

                        dispatch(updateTotalCredit(userDetails.data.total_credit));
                        dispatch(updateCredit(userDetails.data.credit));
                        dispatch(updateIsLoggedIn(true));
                        dispatch(updateUserAccountType(userDetails.data.user_type ? userDetails.data.user_type : 'free'));
                        if (String(userDetails.data.pro_user) === "true") {
                            dispatch(updateUserProAccess(true));
                            dispatch(updateUserAccountType('pro'));
                        } else if (userDetails.data.expire_date) {
                            const currentDate = new Date();
                            const dateToCompare = new Date(userDetails.data.expire_date);
                            const comparisonResult = currentDate < dateToCompare;
                            if (comparisonResult) {
                                dispatch(updateUserProAccess(true));
                            } else {
                                dispatch(updateUserProAccess(false));
                                dispatch(updateUserAccountType('free'));
                            }
                        }

                        // if (canvaId && state) {
                        //   let update_canva_data = {
                        //     canvaId: canvaId,
                        //     id: userDetails.data.id,
                        //   };
                        //   axios({
                        //     method: "POST",
                        //     url: `${domain}/account/updatecanvaid`,
                        //     data: update_canva_data,
                        //     headers: {
                        //       "Content-Type": "application/json",
                        //       Authorization: `Bearer ${token}`
                        //     }
                        //   })
                        //     .then((res) => {
                        //       console.log(res);
                        //       if (canvaId && state) {
                        //         window.location.replace(`https://www.canva.com/apps/configured?success=true&state=${state}`)
                        //       }
                        //     })
                        //     .catch((e) => {
                        //       console.log(e.message, e);
                        //     });
                        // }
                        // const token = localStorage.getItem("access");
                        
                        if (userDetails.data.date_joined) {
                            // console.log(userDetails.data.date_joined)
                            // Get the current date and time
                            const currentDate = new Date();

                            // minus 50 seconds to the current date
                            const currentDateMinus50Sec = new Date(currentDate.getTime() - 50000);


                            // Define the date to compare with
                            const dateToCompare = new Date(userDetails.data.date_joined);

                            // Compare the dates
                            const comparisonResult = currentDateMinus50Sec < dateToCompare;
                            // console.log(currentDate, currentDateMinus50Sec, dateToCompare, comparisonResult)

                            if (comparisonResult) {
                                let emaildata = {
                                    id: userDetails.data.id,
                                };
                                axios.post(`${domain}/account/welcomeemail`, emaildata).then(res => {
                                    // console.log(res)

                                }).catch(error => {
                                    console.error('Error during Microsoft authentication:', error);
                                });

                                let reqBody = {}
                                defaultDuonut(reqBody, (err, res) => {
                                    if (err) {
                                        console.log(err.response);
                                        return;
                                    }
                                    // console.log(res);
                                });

                                // window.location = "/home";
                                window.location = "/onboarding";
                                //   navigate('/onboarding');
                            }
                            else if (location.pathname.substring(0, 7) !== "/design") {
                                // navigate("/myduonuts");
                                // window.location.reload();
                                // window.location = "/myduonuts";
                                // window.location = "/home";
                                const saveAiNewPromise = dispatch(saveAiNewAsync1())
                                    .then((res) => {
                                        // console.log("Save Response:", res);
                                        return res?.empty_message ? 0 : res?.length > 0 ? 1 : 0;
                                    })
                                    .catch((e) => {
                                        console.log("Save Error:", e.message, e);
                                        return 0; // Treat errors as 0
                                    });

                                const getMyDuonutsPromise = new Promise((resolve) => {
                                    getMyDuonuts((err, res) => {
                                        if (err) {
                                            console.log("Duonuts Error:", err.response);
                                            resolve(0); // Treat errors as 0
                                        } else {
                                            // console.log("Duonuts Response:", res);
                                            resolve(res?.data?.length > 0 ? 1 : 0); // Return 1 if length > 0, else 0
                                        }
                                    });
                                });
                                // Call both APIs simultaneously
                                Promise.all([saveAiNewPromise, getMyDuonutsPromise]).then(([saveResStatus, getDuonutsLength]) => {
                                    // console.log("Save Res Status:", saveResStatus, "Duonuts Length:", getDuonutsLength);
                                    if (saveResStatus === 0 && getDuonutsLength === 0) {
                                        window.location = "/home";
                                    } else {
                                        window.location = "/myduonuts";
                                        // window.location = "/home";
                                    }
                                });


                            }
                        }

                        if (userDetails.data.date_joined) {
                            // console.log(userDetails.data.date_joined)
                            // Get the current date and time
                            const currentDate = new Date();

                            // Subtract 7 days (7 days * 24 hours/day * 60 minutes/hour * 60 seconds/minute * 1000 milliseconds/second)
                            const currentDateMinus7Days = new Date(currentDate.getTime() - (7 * 24 * 60 * 60 * 1000));

                            // Define the date to compare with
                            const dateToCompare = new Date(userDetails.data.date_joined);

                            // Calculate the difference in milliseconds
                            const diffInMilliseconds = Math.abs(dateToCompare - currentDateMinus7Days);

                            // Convert milliseconds to days
                            const diffInDays = Math.round(diffInMilliseconds / (1000 * 60 * 60 * 24));

                            // console.log(`Difference in days: ${diffInDays}`);

                            // Compare the dates
                            const comparisonResult = currentDateMinus7Days < dateToCompare;
                            // console.log(currentDate, currentDateMinus7Days, dateToCompare, comparisonResult)

                            dispatch(updateUserTrialDays(diffInDays));
                            //   if (comparisonResult) {
                            //   dispatch(updateUserProAccess(true));
                            // }
                        }
                    });
                }, 1000); //miliseconds

            }).catch(error => {
                console.error('Error during Microsoft authentication:', error);
            });
        }).catch(err => {
            console.log(err.errName, err)
        });
    };

    return (
        <div onClick={handleMicrosoftLogin} className="form_modal__btn-secondary">
            <img src="/assets/icons/microsoft_icon.png" className="google-icon" alt="" />
            <p> {text}</p>
        </div>
    );
}
