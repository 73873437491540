import React, { useEffect, useState } from 'react';
import './Templates.css';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { fetchDuonutCategories } from "../../../services/activity";
import ShowTemplates from './ShowTemplates';
import { getAdminDuonuts } from '../../../services/duonut';
import Loader from '../../../assets/Images/loader.gif';
import { motion, AnimatePresence } from "framer-motion";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { toggleTemplateModal } from '../../../redux/slices/CreateFromScratchReducer';
import TemplateDetailsPageModal from './TemplateDetailsPageModal';

const TemplatesModal = () => {
    const dispatch = useDispatch();
    const paramsid = useParams();
    const [catName, setcatName] = useState("all");
    const navigate = useNavigate();
    const { width } = useWindowDimensions();
    const [isMobile, setisMobile] = useState(false);
    const [selectedCatId, setselectedCatId] = useState(0);
    const [categoryData, setcategoryData] = useState([]);
    const [allData, setallData] = useState([]);
    const [headerName, setheaderName] = useState("");
    const [selectedData, setselectedData] = useState([]);
    const [searchActive, setsearchActive] = useState(false);
    const [searchText, setsearchText] = useState("");
    const { templateModal } = useSelector((state) => state.createFromScratch);
    const [idName, setIdName] = useState("");
    const [categoryNameCopy, setcategoryNameCopy] = useState("");

    // console.log("templates");
    useEffect(() => {

        if (catName) {
            fetchDuonutCategories((err, res) => {
                if (err) return console.log(err)
                //console.log("categories", res)
                let tempCategories = res.data.map((category) => {
                    let c = { ...category };
                    c.value = category.name;
                    delete c.name;
                    return c;
                });
                //console.log("tempCategories", tempCategories)
                setcategoryData(tempCategories);

                tempCategories.map(item => {
                    let categoryName = item.value.toLowerCase().replace(/ /g, "_");

                    if (catName === categoryName) {
                        // setcategoryNameCopy(categoryName);
                        // console.log(categoryName)
                        setheaderName(item.value);
                        setselectedCatId(item.id);
                        // console.log(item.id);
                    }
                    return;
                })
            });
        }
        if (catName === "all" && !searchActive) {
            setheaderName("All");
            getAdminDuonuts((err, res) => {
                if (err) console.log(err.response)
                else {
                    if (res.data.length === 0) return
                    setallData(res.data.sort((a, b) => a.updated_at < b.updated_at ? 1 : -1));
                    //console.log("AdminDuonut", res.data.sort((a, b) => a.updated_at < b.updated_at ? 1 : -1))

                    var allCategoryData = res.data.sort((a, b) => a.updated_at < b.updated_at ? 1 : -1);

                    setselectedData(allCategoryData);
                }
            })
        }
    }, [catName]);

    useEffect(() => {
        // console.log(selectedCatId)
        if (selectedCatId <= 0) return;
        setallData([]);
        getAdminDuonuts((err, res) => {
            if (err) console.log(err.response)
            else {
                if (res.data.length === 0) return
                setallData(res.data.sort((a, b) => a.updated_at < b.updated_at ? 1 : -1));
                // console.log("AdminDuonut", res.data.sort((a, b) => a.updated_at < b.updated_at ? 1 : -1))

                var allCategoryData = res.data.sort((a, b) => a.updated_at < b.updated_at ? 1 : -1);
                var tempData = [];
                allCategoryData.map((itemData, i) => {
                    if (selectedCatId === itemData.duonut_category_id.id) {
                        tempData.push(itemData);
                    }
                    // console.log(selectedCatId);
                    return null;
                });
                // console.log(tempData);
                setselectedData(tempData);
            }
        })
        // console.log("inside");

    }, [selectedCatId]);

    useEffect(() => {
        if (width >= 600) return;
        setisMobile(true);
    }, [width]);

    const handleSearchBox = event => {
        // console.log(event);
        if (event.keyCode === 13) {
            // console.log("enter", searchText);
            setsearchActive(true);
            searchDuonut();
            return;
        }
        setsearchText(event.target.value);
    };

    const handleClearSearch = () => {
        setsearchText("");
        document.getElementById("searchInput").value = "";
        setsearchActive(false);
        setheaderName("All");
        setselectedData(allData);
        // navigate('/templates/all');
        setcatName("all");

    };

    const searchDuonut = () => {
        var searchedDuonut = [];
        // console.log("allData", allData)

        const searchWords = searchText.split(" ");
        const searchRegexes = searchWords.map((word) => new RegExp(word, "i"));

        allData.map(duonut_data => {
            if (searchRegexes.every((regex) => duonut_data.name.match(regex)) || searchRegexes.every((regex) => duonut_data.setting?.keywords?.some(keyword => keyword.match(regex)))) {
                searchedDuonut.push(duonut_data);
            }
        })
        if (searchedDuonut.length > 0) {
            setheaderName(`search results for '${searchText}'`);
            setselectedData(searchedDuonut);
        } else {
            setheaderName("No result found");
            setselectedData([]);
        }

        // console.log("searchedDuonut", searchedDuonut)
    };

    const onClickCat = (categname) => {
        let categoryName = categname.toLowerCase().replace(/ /g, "_");

        // window.open(`/ design ? selectedDuonutId = ${ duonut_id }`);
        //   window.open('/duonut/' + key);
        // navigate('/templates/' + categoryName);
        setcatName(categoryName);
        setheaderName(categname);
        setsearchText("");
        document.getElementById("searchInput").value = "";
        setsearchActive(false);
        // window.location.reload();
        // if (categoryName === catName) {
        //     window.location.reload();
        // }

    }
    const handleClose = () => {
        dispatch(toggleTemplateModal(false));
        setIdName("");
        setcategoryNameCopy("");
    };
    return (
        <AnimatePresence>
            {templateModal && (
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.7, ease: "backOut" }} className="modal_container">
                    <motion.div initial={{ y: -20 }} animate={{ y: 0 }} exit={{ y: -30 }} transition={{ duration: 0.7, ease: "backOut" }} className="CFS1modal row"
                        style={{
                            position: 'relative',
                            transform: 'none',
                            width: '75%',
                            height: '53rem', overflow: "hidden"
                        }}>
                        {templateModal && idName === "" && categoryNameCopy === "" ? (
                            // <>hello</>
                            <div className='CATTP1App' style={{ margin: "0" }}>
                                <div className="CATTP1templateblur">
                                    <div className='CATTP1leftContainer'>
                                        <div className='CATTP1activitytype' onClick={() => onClickCat("All")}>
                                            <div style={headerName === "All" ? { fontWeight: "800", color: "rgba(11, 35, 48, 1)" } : {}}>All</div>
                                        </div>
                                        {categoryData?.map((type, i) => {
                                            if (type.visibility === false) return null;
                                            return (
                                                <div className='CATTP1activitytype' key={i} onClick={() => onClickCat(type.value)}>
                                                    <div style={headerName === type.value ? { fontWeight: "800", color: "rgba(11, 35, 48, 1)" } : {}}>{type.value}</div>
                                                </div>
                                            );
                                        })}
                                    </div>

                                    <div className='CATTP1rightContainer' id="scrollableDiv">

                                        <h2 style={{ paddingTop: "2rem" }}>What you can create with Duonut</h2>
                                        <div style={{ display: "flex", position: "relative", margin: "2rem auto", maxWidth: "40rem", border: "1px solid rgb(192, 194, 204)", borderRadius: "6px" }}>
                                            <input style={{ fontSize: "14px", padding: "8px 30px", flex: "1 1" }} id="searchInput" type="text" placeholder="Search for a template"
                                                onKeyUp={e => handleSearchBox(e)} />
                                            <img style={{ position: "absolute", top: "3px", left: "5px" }} src="/assets/icons/search-icon.svg" loading="lazy" />
                                            {searchActive && <img src="/assets/icons/close.svg" loading="lazy" style={{ cursor: "pointer", marginRight: "1rem" }}
                                                onClick={() => handleClearSearch()} />}
                                        </div>

                                        <h2 style={{ paddingTop: "1rem", paddingLeft: "2rem", textAlign: "left" }}>{headerName}</h2>

                                        {selectedData.length > 0 ? <ShowTemplates data={selectedData} setcategoryNameCopy={setcategoryNameCopy} setIdName={setIdName} />
                                            : !searchActive ? <div style={{ padding: "4rem" }}>
                                                <img alt="loader" src={Loader} height={50} width={50} loading="lazy" />
                                            </div> : <></>}
                                    </div>
                                </div>
                                {/* <Footer /> */}
                            </div>) : (
                            <>
                                <TemplateDetailsPageModal idName={idName} categoryNameCopy={categoryNameCopy} setcategoryNameCopy={setcategoryNameCopy} setIdName={setIdName} />
                            </>
                        )
                        }
                        <div className="close-icon icon-button CFS1close" onClick={handleClose}>
                            <img src="/assets/icons/close.svg" />
                        </div>
                    </motion.div>
                    <div className="modal-overlay"></div>
                </motion.div>
            )}
        </AnimatePresence >
    );

}

export default TemplatesModal;