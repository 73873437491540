import React, { useState, useEffect } from 'react';
import './Element.css';
import { useSelector, useDispatch } from "react-redux";
import { updateGoToSlide, updateElementInputFocus } from '../../redux/slices/userActivity';
import { useLocation } from "react-router-dom";
import { showDuonutresponse, saveQuizAnsAsync, saveQuizResponse, showduonutData } from '../../redux/slices/DuonutReducer';
import axios from "axios";
import { domain } from "../../services/constants";
import useWindowDimensions from '../../hooks/useWindowDimensions';

const Checkbox = ({
    id,
    elementAttr: {
        text, color, fontFamily, fontSize, fontWeight, options, fontStyle, textDecoration, textTransform, textAlign,
        lineHeight, letterSpacing, backgroundColor, border, margin, padding, borderRadius, other, maxLength, gap,
        labelFontSize, labelFontWeight, labelColor, required, fieldPosition,
    },
    elementHoverAttr,
    language,
    slideId,
}) => {
    const dispatch = useDispatch();
    const { selectedSlide } = useSelector((state) => state.userActivity);
    const location = useLocation();
    const save_ans_response = useSelector(showDuonutresponse);
    const { viewer_display_id, viewer_nick_name, viewer_url_params } = useSelector((state) => state.duonut);
    const [selectedAnswer, setSelectedAnswer] = useState([]);
    const [otherText, setOtherText] = useState('');
    const [hover, setHover] = useState(-1);
    const [inputText, setInputText] = useState("");
    const [optionsText, setOptionsText] = useState([]);
    const duonutDataMain = useSelector(showduonutData);
    const duonutData = duonutDataMain[0];
    const { viewType } = useSelector((state) => state.duonutSettings);
    const { width } = useWindowDimensions();
    const flexDirection = (viewType === 3 || width < 800) ? "column" : fieldPosition;
    useEffect(() => {
        setSelectedAnswer([]);
        if (location.pathname.includes("/duonut/") && slideId === `slide-${selectedSlide}` && language !== "en") {
            fetchText(text);
            fetchOptionsText(options);
        } else {
            setInputText(text);
            setOptionsText(options);
        }
    }, [text, options]);

    const fetchOptionsText = async (texts) => {
        const translatedTexts = [];
        const delimiter = "[[SEP]]";
        const delimiterRegex = /\[\[SEP\]\]|\[SEP\]/;
        const textToTranslate = texts.join(delimiter);
        const translated = await translateText(textToTranslate, language);
        const translatedArray = translated.split(delimiterRegex);
        translatedTexts.push(...translatedArray);
        setOptionsText(translatedTexts);
    };

    const fetchText = async (texts) => {
        const t_text = await translateText(texts, language);
        setInputText(t_text);
    };

    const translateText = async (texts, targetLanguage) => {
        try {
            const response = await axios({
                method: "POST",
                url: `${domain}/duonut/language_translator`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: {
                    source: "en",
                    target: targetLanguage,
                    query: texts,
                },
            });

            if (response.status === 200) {
                return response?.data.translation;
            } else {
                return "Error: Translation failed. Please try again.";
            }
        } catch (error) {
            console.error('Error in translation API:', error);
            return "Error translating text. Please try again.";
        }
    };

    const handleAnswerChange = (item) => {
        let selectedAnswerCopy = [...selectedAnswer];
        if (!selectedAnswerCopy.includes(item)) {
            selectedAnswerCopy.push(item);
        } else {
            selectedAnswerCopy = selectedAnswerCopy.filter(answer => answer !== item);
        }
        setSelectedAnswer(selectedAnswerCopy);
        submitResponse(selectedAnswerCopy);
    };

    const submitResponse = (selectedAnswers) => {
        const ans_other = selectedAnswers.map(selectedans =>
            selectedans === "other" ? `${selectedans} - ${otherText}` : selectedans
        );

        let ans = ans_other.join("; ");

        if (location.pathname.includes("/duonut/")) {
            var save_ans = {
                display_id: viewer_display_id,
                watch_id: save_ans_response[0] ? save_ans_response[0].watch_id : "",
                result: 0,
                question: text ? text : "",
                answered_text: ans,
                nickname: viewer_nick_name,
                step: selectedSlide + 1,
                type: "CheckboxGroup",
                url_params: viewer_url_params,
            };

            const formData = new FormData();
            Object.keys(save_ans).forEach(key => {
                const value = typeof save_ans[key] === "object" ? JSON.stringify(save_ans[key]) : save_ans[key];
                formData.append(key, value);
            });

            dispatch(saveQuizAnsAsync(formData));

            var save_response = {
                id: id,
                type: "CheckboxGroup",
                step: selectedSlide + 1,
                question: text ? text : "",
                answered_text: ans,
            };
            dispatch(saveQuizResponse(save_response));
        }
    };

    const handleOtherInputChange = (e) => {
        if (location.pathname.includes("/duonut/")) {
            setOtherText(e.target.value);
            submitResponse(selectedAnswer);
        }
    };

    const handleInputFocus = () => {
        dispatch(updateElementInputFocus(true));
    };

    const handleInputBlur = () => {
        dispatch(updateElementInputFocus(false));
    };

    return (
        <>
            <div style={{ display: "flex", flexDirection: "column", gap: gap }}>
                <div style={{
                    width: "100%", paddingBottom: '10px',
                    color: labelColor, fontFamily, fontSize: labelFontSize, fontWeight: labelFontWeight, fontStyle, textDecoration, textTransform,
                    textAlign, lineHeight, letterSpacing
                }}>{inputText}</div>
                <div style={{
                    display: "flex",
                    justifyContent:
                        fieldPosition === "row"
                            ? "space-around"
                            : textAlign === "justify"
                                ? "flex-start"
                                : textAlign,
                    alignItems: 'flex-start',
                    textAlign,
                    flexDirection
                }}>
                    {options.map((item, i) => {
                        return <div style={{
                            display: "flex", justifyContent: "flex-start", alignItems: "center", lineHeight, width: flexDirection === "column" ? "100%" : "auto",
                        }} key={i} >
                            <input type="checkbox" id={"checkbox_option" + i} name="checkbox_option"
                                style={{
                                    width: fontSize, height: fontSize, minWidth: fontSize, minHeight: fontSize, margin: "2px",
                                    //  accentColor: `${color}`
                                }}
                                checked={selectedAnswer.includes(item) ? true : false}
                                disabled={(maxLength !== 0 && maxLength === selectedAnswer.length && !selectedAnswer.includes(item)) ? true : false}
                                onChange={() => handleAnswerChange(item)}
                            />
                            <div style={{
                                backgroundColor: hover === i ? elementHoverAttr?.backgroundColor : backgroundColor,
                                border: hover === i ? elementHoverAttr?.border : border ? border : "0",
                                color: hover === i ? elementHoverAttr?.color : color,
                                borderRadius, fontFamily, fontSize, fontWeight, fontStyle, textDecoration,
                                textTransform, textAlign, letterSpacing, padding, margin,
                                cursor: (location.pathname).includes("/duonut/") ? "pointer" : "default",
                                width: "100%", height: "100%"
                            }}
                                onMouseEnter={() => setHover(i)}
                                onMouseLeave={() => setHover(-1)}
                                onClick={() => handleAnswerChange(item)}
                            >
                                <div>{optionsText[i]}</div>
                            </div>
                        </div>
                    })}
                    {other && <div style={{
                        display: "flex", justifyContent: "flex-start", alignItems: "center", lineHeight
                    }}>
                        <input type="checkbox" id={"checkbox_option_other"} name="checkbox_option"
                            style={{
                                width: fontSize, height: fontSize, minWidth: fontSize, minHeight: fontSize, margin: "2px",
                                // accentColor: `${color}`
                            }}
                            checked={selectedAnswer.includes("other") ? true : false}
                            disabled={(maxLength !== 0 && maxLength === selectedAnswer.length && !selectedAnswer.includes("other")) ? true : false}
                            onChange={() => handleAnswerChange("other")}
                        />
                        <div style={{
                            backgroundColor: hover === options.length ? elementHoverAttr?.backgroundColor : backgroundColor,
                            border: hover === options.length ? elementHoverAttr?.border : border ? border : "0",
                            color: hover === options.length ? elementHoverAttr?.color : color,
                            borderRadius, fontFamily, fontSize, fontWeight, fontStyle, textDecoration,
                            textTransform, textAlign, letterSpacing, padding, margin,
                            cursor: (location.pathname).includes("/duonut/") ? "pointer" : "default",
                            width: "100%", height: "100%"
                        }}
                            onMouseEnter={() => setHover(options.length)}
                            onMouseLeave={() => setHover(-1)}
                            onClick={() => handleAnswerChange("other")}>
                            <div>{language === "hi" ? "अन्य" :
                                language === "es" ? "Otra" :
                                    language === "ja" ? "他の" :
                                        language === "ar" ? "آخر" :
                                            language === "fr" ? "Autre" :
                                                language === "nl" ? "Ander" :
                                                    language === "pt" ? "Outra" : "Other"}</div>
                        </div>
                        {(location.pathname.includes("/duonut/") && selectedAnswer.includes("other")) &&
                            <>
                                <input type='text' style={{
                                    minWidth: "2rem", margin: "0px 6px", padding: "10px", width: "100%",
                                    color, fontFamily, fontSize: fontSize, fontWeight, fontStyle, textDecoration, textTransform,
                                    textAlign, lineHeight, letterSpacing,
                                    backgroundColor, borderBottom: "1px solid gray", borderRadius,
                                }}
                                    onChange={(e) => handleOtherInputChange(e)}
                                    readOnly={(location.pathname.includes("/duonut/") && selectedAnswer.includes("other")) ? false : true}
                                />
                                <button onClick={() => submitResponse(selectedAnswer)}
                                    style={{ backgroundColor: "#4299e1", color: "white", borderRadius: "6px", padding: '1rem', cursor: "pointer" }}>
                                    <i className="fa fa-arrow-right" aria-hidden="true"></i></button>
                            </>
                        }
                    </div>}
                </div>
            </div>
        </>);
}

export default Checkbox;