import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import RouterConfig from "./navigation/RouterConfig";
import { updateIsLoggedIn, updateUserAccountType, updateUserProAccess, updateSlackKey, updateUserTrialDays, updateGmailKey, updateTotalCredit, updateCredit, updateIsOnboarded } from "./redux/slices/user";
import { getUserDetails, refreshAcessToken, refreshGoogleToken } from "./services/auth";
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import { domain } from './services/constants';
import axios from 'axios';

var fTime = true;
introJs().start();
const App = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isOnboarded, setIsOnboarded] = useState(false);

  useEffect(() => {
    // console.log("inside useeffect");
    if (fTime) {
      fTime = false;
      // console.log("inside ftime", fTime);
      const refresh = localStorage.getItem("refresh");
      if (refresh) {
        const withGoogle = localStorage.getItem("withGoogle");
        if (withGoogle === 'true') {
          let body = {
            client_id: process.env.REACT_APP_CLIENT_ID,
            client_secret: process.env.REACT_APP_CLIENT_SECRET,
            grant_type: "refresh_token",
            refresh_token: refresh,
          };

          refreshGoogleToken(body)
            .then(res => {
              localStorage.setItem("refresh", res.data.refresh_token);
              localStorage.setItem("access", res.data.access_token);

              // Proceed after refreshing token
              handleUserDetails(); // Function to handle user details
            })
            .catch(err => {
              handleTokenRefreshError(err); // Function to handle token refresh error
            });
        } else {
          refreshAcessToken(refresh, (err, res) => {
            if (err || !res.data) {
              handleTokenRefreshError(err); // Function to handle token refresh error
              return;
            }
            localStorage.setItem("access", res.data.access);

            // Proceed after refreshing token
            handleUserDetails(); // Function to handle user details
          });
        }
      } else {
        dispatch(updateIsLoggedIn(false));
        setIsLoading(false);
      }

    }
  }, []);

  const handleUserDetails = () => {
    getUserDetails((err, userDetails) => {
      if (err) {
        // Handle error if needed
        localStorage.removeItem("access");
        localStorage.removeItem("refresh");
        localStorage.removeItem("userName");
        localStorage.removeItem("userEmail");
        localStorage.removeItem("userId");
        localStorage.removeItem("withGoogle");
        localStorage.removeItem("withMicrosoft");
        localStorage.removeItem("appId");
        localStorage.removeItem("subDomain");
        localStorage.removeItem("proUser");
        localStorage.removeItem("userProfilePicture");
        localStorage.removeItem("userType");
        localStorage.removeItem("expireDate");

        window.location = "/";
        return;
      }
      // Set user details in localStorage
      localStorage.setItem("userName", userDetails.data.name);
      localStorage.setItem("userEmail", userDetails.data.email);
      localStorage.setItem("userId", userDetails.data.id);
      localStorage.setItem("appId", userDetails.data.app_id);
      localStorage.setItem("subDomain", userDetails.data.sub_domain);
      localStorage.setItem("proUser", userDetails.data.pro_user);
      localStorage.setItem("userProfilePicture", userDetails.data.profile_picture);
      localStorage.setItem("userType", userDetails.data.user_type);
      localStorage.setItem("expireDate", userDetails.data.expire_date);

      // Update state accordingly
      dispatch(updateSlackKey(userDetails.data.slack_key));
      dispatch(updateGmailKey(userDetails.data.gmail_key));
      dispatch(updateTotalCredit(userDetails.data.total_credit));
      dispatch(updateCredit(userDetails.data.credit));

      dispatch(updateUserAccountType(userDetails.data.user_type ? userDetails.data.user_type : 'free'));
      if (String(userDetails.data.pro_user) === "true") {
        dispatch(updateUserProAccess(true));
        dispatch(updateUserAccountType('pro'));
      } else if (userDetails.data.expire_date) {
        const currentDate = new Date();
        const dateToCompare = new Date(userDetails.data.expire_date);
        const comparisonResult = currentDate < dateToCompare;
        if (comparisonResult) {
          dispatch(updateUserProAccess(true));
        } else {
          dispatch(updateUserProAccess(false));
          dispatch(updateUserAccountType('free'));
        }
      }

      if (userDetails.data.date_joined) {
        // console.log(userDetails.data.date_joined)
        // Get the current date and time
        const currentDate = new Date();

        // Subtract 7 days (7 days * 24 hours/day * 60 minutes/hour * 60 seconds/minute * 1000 milliseconds/second)
        const currentDateMinus7Days = new Date(currentDate.getTime() - (7 * 24 * 60 * 60 * 1000));

        // Define the date to compare with
        const dateToCompare = new Date(userDetails.data.date_joined);

        // Calculate the difference in milliseconds
        const diffInMilliseconds = Math.abs(dateToCompare - currentDateMinus7Days);

        // Convert milliseconds to days
        const diffInDays = Math.round(diffInMilliseconds / (1000 * 60 * 60 * 24));

        // console.log(`Difference in days: ${diffInDays}`, dateToCompare, diffInMilliseconds);

        // Compare the dates
        const comparisonResult = currentDateMinus7Days < dateToCompare;
        console.log(currentDate, currentDateMinus7Days, dateToCompare, comparisonResult)

        dispatch(updateUserTrialDays(diffInDays));
        if (comparisonResult) {
          dispatch(updateUserProAccess(true));
        } else {
          dispatch(updateUserTrialDays(0));

        }
      }

      dispatch(updateIsLoggedIn(true));
      // setTimeout(() => {
      setIsLoading(false);
      // }, 5000); //miliseconds


      const token = localStorage.getItem("access");
      axios({
        method: "GET",
        url: `${domain}/duonut/get_user_detail`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }).then(response => {
        // console.log('User Details:', response.data[0]);
        if (!response.data || response.data.length === 0) {
          // Stay on onboarding page
          // window.location = "/onboarding";
          dispatch(updateIsOnboarded(false));
        } else {
          dispatch(updateIsOnboarded(true));
          // window.location = "/home";
        }
      }).catch(error => {
        console.error('Error fetching data:', error);
      });

    });
  };

  const handleTokenRefreshError = (err) => {
    console.log('Token refresh error', err.response);
    dispatch(updateIsLoggedIn(false));
    setIsLoading(false);
    // Remove relevant items from localStorage
    const localStorageItemsToRemove = [
      "access",
      "refresh",
      "userName",
      "userEmail",
      "userId",
      "userProfilePicture",
      "userType",
      "expireDate",
      "appId",
      "subDomain",
      "proUser"
    ];
    localStorageItemsToRemove.forEach(item => {
      localStorage.removeItem(item);
    });
  };


  if (isLoading) return <div></div>;
  return (
    <>
      <RouterConfig />
    </>
  );
};

export default App;
