import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";

import "./style.css";
import { updateDeleteUserConfirmModal } from "../../../redux/slices/formDetails";
import { deleteUserAsync } from "../../../redux/slices/ProfileReducer";

export default function ConfirmDeleteUser() {
  const dispatch = useDispatch();
  const { active: modalActive, userId } = useSelector((state) => state.formDetails.deleteUserConfirmModal);

  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    dispatch(updateDeleteUserConfirmModal({ active: false, userId: null }));
  };

  const handleDelete = () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('id', userId);
    dispatch(deleteUserAsync(formData)).then((response) => {
      console.log(response);
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      localStorage.removeItem("userName");
      localStorage.removeItem("userEmail");
      localStorage.removeItem("userId");
      localStorage.removeItem("withGoogle");
      localStorage.removeItem("withMicrosoft");
      localStorage.removeItem("appId");
      localStorage.removeItem("subDomain");
      localStorage.removeItem("proUser");
      localStorage.removeItem("userProfilePicture");
      localStorage.removeItem("userType");
      localStorage.removeItem("expireDate");

      window.location = "/";
    }).catch(error => {
      console.log(error);

    });


  };

  return (
    <AnimatePresence>
      {modalActive && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.4, type: "tween", ease: "anticipate" }}
          className="modal_container confirm_replace_modal "
          onClick={handleClose}
        >
          <motion.div
            initial={{ scale: 0.7 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0.7 }}
            transition={{ duration: 0.4, type: "tween", ease: "anticipate" }}
            className="modal_basic"
            onClick={(e) => e.stopPropagation()}
          >
            <div>
              <div className="modal_header">Confirm delete my account?</div>
              <div className="modal_text">This will permanently delete your entire account.
                All your duonuts and workspaces will be deleted.</div>
              <div className="action-buttons">
                <button className="btn btn-primary cancel-btn" onClick={handleClose}>
                  Cancel
                </button>
                <button disabled={isLoading} className="btn btn-primary" onClick={handleDelete}>
                  {isLoading ? "Deleting..." : "Confirm delete my account"}
                </button>
              </div>
            </div>
            <div className="close-icon icon-button" onClick={handleClose}>
              <img src="/assets/icons/close.svg" alt="" />
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
