import React from "react";
import Shape from './Shape';
import Text from './Text';
import Image from './Image';
import Button from './Button';
import TextField from './TextField';
import './Element.css';
import TextArea from "./TextArea";
import Email from "./Email";
import Password from "./Password";
import Phone from "./Phone";
import NumberField from "./NumberField";
import CheckboxGroup from "./CheckboxGroup";
import RadioGroup from "./RadioGroup";
import RadioImage from "./RadioImage";
import Rating from "./Rating";
import Mcq from "./Mcq";
import Video from "./Video";
import Dropdown from "./Dropdown";
import DropdownMultiselect from "./DropdownMultiselect";
import DateField from "./DateField";
import Audio from "./Audio";
import FileUpload from "./FileUpload";
import Nps from "./Nps";
import CheckboxImage from "./CheckboxImage";
import McqImage from "./McqImage";
import Reorder from "./Reorder";
import ReorderImage from "./ReorderImage";
import FlipCard from "./FlipCard";
import Matrix from "./Matrix";
import Iframe from "./Iframe";
import QuestionAnswer from "./QuestionAnswer";
import RadioMultiImage from "./RadioMultiImage";
import GenericConjoint from "./GenericConjoint";
import BestWorstConjoint from "./BestWorstConjoint";
import Checkbox from "./Checkbox";

const components = {
    Shape,
    Text,
    Image,
    Button,
    TextField,
    TextArea,
    Email,
    Password,
    Phone,
    NumberField,
    Checkbox,
    CheckboxGroup,
    CheckboxImage,
    RadioGroup,
    RadioImage,
    Matrix,
    Rating,
    Mcq,
    McqImage,
    Video,
    Dropdown,
    DropdownMultiselect,
    DateField,
    Audio,
    FileUpload,
    Nps,
    Reorder,
    ReorderImage,
    FlipCard,
    Iframe,
    QuestionAnswer,
    RadioMultiImage,
    GenericConjoint,
    BestWorstConjoint,
};

const Element = ({ element, slideId, setShowModel, setCorrectAnswer, language }) => {

    return (
        <>
            {React.createElement(
                components[element.elementType],
                {
                    slideId: slideId, id: element.id, elementAttr: element.elementAttributes,
                    elementHoverAttr: element.elementHoverAttributes, conditions: element.conditions,
                    setShowModel: setShowModel, setCorrectAnswer: setCorrectAnswer, language: language
                },
                null,
            )}
        </>
    );
}

export default Element;