import React, { useEffect, useState } from 'react';
import './OnBoarding.css';
import { saveOnboardingAsync } from '../../redux/slices/OnboardingReducer';
import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
import SelectedImg from "../../assets/icons/selected.svg";
import Select from 'react-select';
import { editUserNameAsync } from '../../redux/slices/ProfileReducer';
import { domain } from '../../services/constants';
import axios from 'axios';
// import { toggleCreateFromScratchModalDuonut } from "../../redux/slices/CreateFromScratchReducer";
import { useSelector } from "react-redux";

const OnBoarding = () => {
    const dispatch = useDispatch();
    const userId = localStorage.getItem("userId");
    const { isLoggedIn } = useSelector((state) => state.user);

    // const navigate = useNavigate();
    const [useType, setuseType] = useState("");
    const [clickContinue, setclickContinue] = useState(false);
    const [invitePage, setinvitePage] = useState(false);
    const [work, setwork] = useState('');
    // const [role, setrole] = useState('');
    const [companySize, setcompanySize] = useState('');
    const [planInDuonut, setplanInDuonut] = useState([]);
    const [planInDuonutValues, setplanInDuonutValues] = useState([]);
    const [email1, setemail1] = useState('');
    const [email2, setemail2] = useState('');
    const [email3, setemail3] = useState('');
    const [onboarding, setOnboarding] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [role, setRole] = useState('');
    const [isFirstNameEmpty, setIsFirstNameEmpty] = useState(false);
    const [aboutUs, setAboutUs] = useState('');

    const planInDuonutOptions = [
        { value: 'Website Feedback', label: 'Website Feedback' },
        { value: 'NPS', label: 'NPS' },
        { value: 'Conjoint Research', label: 'Conjoint Research' },
        { value: 'Survey', label: 'Survey' },
        { value: 'Landing pages', label: 'Landing pages' },
        { value: 'Others', label: 'Others' }
    ]
    const [userDetail_onboarding, setUserDetailOnboarding] = useState([]);

    const [loading, setloading] = useState(false);
    useEffect(() => {
        if (!isLoggedIn) {
            window.location = '/';
        }
        const token = localStorage.getItem("access");
        setloading(true);
        axios({
            method: "GET",
            url: `${domain}/duonut/get_user_detail`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        }).then(response => {
            // console.log('User Details:', response.data[0]);
            if (!response.data || response.data.length === 0) {
                // Stay on onboarding page
                setloading(false);
            } else {
                setUserDetailOnboarding(response.data[0]); // Keep this if you still want to set state
                setloading(false);
                window.location = "/home";
            }
        })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const selectBtn = (item) => {
        // console.log(item);
        setuseType(item);
    }
    const continueBtn = () => {
        // console.log("continueBtn");
        setclickContinue(true);
    }
    const gotoInvite = () => {
        // console.log("gotoInvite");
        setclickContinue(false);
        setinvitePage(true);
    }

    const handleWorkChange = event => {
        setwork(event.target.value);
        // console.log(event.target.value);
    };

    // const handleRoleChange = event => {
    //     setrole(event.target.value);
    //     // console.log(event.target.value);
    // };

    const handleCompanySizeChange = event => {
        setcompanySize(event.target.value);
        // console.log(event.target.value);
    };
    const handleAboutUs = event => {
        setAboutUs(event.target.value);
        // console.log(event.target.value);
    };
    const handlePlanInDuonutChange = planInDuonutValues => {
        // console.log(planInDuonutValues);
        setplanInDuonutValues(planInDuonutValues);

        var planInDuonutArray = planInDuonutValues.map(item => item.value)
        // console.log(planInDuonutArray);


    };

    const handleEmail1Change = event => {
        setemail1(event.target.value);
        // console.log(event.target.value);
    }; const handleEmail2Change = event => {
        setemail2(event.target.value);
        // console.log(event.target.value);
    }; const handleEmail3Change = event => {
        setemail3(event.target.value);
        // console.log(event.target.value);
    };

    const submit = (e) => {
        e.preventDefault();
        var referalEmail = [];
        if (email1) {
            referalEmail.push(email1)
        }
        if (email2) {
            referalEmail.push(email2)
        }
        if (email3) {
            referalEmail.push(email3)
        }

        var onboarding_data = {
            use_type: useType,
            work: work,
            role: role,
            company_name: companyName,
            company_size: companySize,
            plans_in_duonut: planInDuonut,
            reference_email: referalEmail,
            how_did_you_hear_about_us: aboutUs,
        }
        // console.log(onboarding_data)
        dispatch(saveOnboardingAsync(onboarding_data));

        setTimeout(() => {
            // if (work === "Educator" || work === "Student") {
            //     navigate('/templates/education_&_learning');
            // } else if (work === "Marketing" || work === "Sales" || work === "Finance") {
            //     navigate('/templates/sales_&_marketing');
            // } else {
            //     navigate('/templates');
            // }
            // navigate('/createworkflow');
            window.location = "/home";
            // dispatch(toggleCreateFromScratchModalDuonut(true));

            // window.location.reload();
        }, 100); //miliseconds

    }

    const skip = () => {
        var referalEmail = [];

        var onboarding_data = {
            use_type: useType,
            work: work,
            role: role,
            company_name: companyName,
            company_size: companySize,
            plans_in_duonut: planInDuonut,
            reference_email: referalEmail,
            how_did_you_hear_about_us: aboutUs,
        }
        // console.log(onboarding_data)
        dispatch(saveOnboardingAsync(onboarding_data));

        setTimeout(() => {
            // navigate('/templates');
            // window.location.reload();
            // navigate('/createworkflow');
            window.location = "/home";
            // dispatch(toggleCreateFromScratchModalDuonut(true));
        }, 100); //miliseconds

    }
    const handleChange = (e) => {
        const { name, value } = e.target; // Get the name and value of the input field

        if (name === "firstName") {
            setFirstName(value); // Update firstName state
        } else if (name === "lastName") {
            setLastName(value); // Update lastName state
        } else if (name === "companyName") {
            setCompanyName(value); // Update company state
        } else if (name === "role") {
            setRole(value);
        }

    };

    const submitForm = () => {
        if (firstName.trim() === "") {
            setIsFirstNameEmpty(true); // Highlight firstName if empty
            return;
        }
        const fullName = `${firstName.trim()} ${lastName.trim()}`;
        const formData = new FormData();
        formData.append('id', userId);
        formData.append('name', fullName);
        dispatch(editUserNameAsync(formData));
        localStorage.setItem("userName", fullName);
        setOnboarding(true);
    };
    return (
        <>
            {loading ? (<div style={{ position: "absolute", display: "flex", top: "0", width: "100%", height: "100%", zIndex: "999999999" }}>
                <img alt="loader" src={require(`../../assets/Images/loader.gif`)} style={{ margin: "auto", height: "5rem" }} />
            </div>) : (<>
                { isLoggedIn &&
                <div className='OB1App'>
                    {(!clickContinue && !invitePage && !onboarding) && <div className='OB1useTypeTemplate'>
                        <h1>Welcome!</h1>
                        <h4>Please provide some information to get started.</h4>
                        <div className="OB1container">
                            <form>
                                <div className="form-group">
                                    <label htmlFor="firstName">First Name</label>
                                    <input
                                        type="text"
                                        id="firstName"
                                        name="firstName"
                                        placeholder={isFirstNameEmpty ? "First name is required" : "Enter your first name"}
                                        className="form-control"
                                        required
                                        value={firstName}
                                        onChange={handleChange}
                                        style={{
                                            border: isFirstNameEmpty ? "1px solid red" : "1px solid #ccc",
                                        }}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="lastName">Last Name</label>
                                    <input
                                        type="text"
                                        id="lastName"
                                        name="lastName"
                                        placeholder="Enter your last name"
                                        className="form-control"
                                        value={lastName}
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="company">Company</label>
                                    <input
                                        type="text"
                                        id="companyName"
                                        name="companyName"
                                        placeholder="Enter your company name/NA"
                                        className="form-control"
                                        value={companyName}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="role">What is your role?</label>
                                    <input
                                        type="text"
                                        id="role"
                                        name="role"
                                        placeholder="Enter your company name/NA"
                                        className="form-control"
                                        value={role}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className='OB1input_container'>
                                    <label style={{ margin: "5px 0px" }}>How did you hear about us?</label>
                                    <select id="about_us" required className='OB1input_container_select' value={aboutUs} onChange={e => handleAboutUs(e)}
                                        style={{ margin: "5px 0px" }}>
                                        <option value="" disabled selected style={{ display: "none", color: "#44474d" }}>Select response*</option>
                                        <option value="Search Engine">Search Engine</option>
                                        <option value="Social Media">Social Media</option>
                                        <option value="Referral">Referral</option>
                                        <option value="Email marketing">Email marketing</option>
                                        <option value="Linkedin Post">Linkedin Post</option>
                                        <option value="Industry event">Industry event</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>
                            </form>
                        </div>
                        <button className='OB1button' onClick={() => { submitForm(); }}>Continue</button>
                        {/* <div style={{ cursor: "pointer", margin: "5px auto" }} onClick={() => skip()}>Skip</div> */}
                    </div>
                    }
                    {(onboarding && !clickContinue && !invitePage) && <div className='OB1useTypeTemplate'>
                        <h1>How are you planning to use Duonut?</h1>
                        <h4>We'll streamline your setup experience accordingly.</h4>
                        <div className="OB1container">
                            <div className='OB1useType' onClick={() => selectBtn("Team")}>
                                {useType === "Team" ? <img className="OB1Icon" src={SelectedImg} />
                                    : <i className="fa fa-circle-thin OB1Icon" aria-hidden="true"></i>}
                                <img src="/assets/team.png" className="OB1Image" />
                                <h3>For my team</h3>
                                {/* <div>Collaborate on your docs. </div><div>projects and wikis.</div> */}
                            </div>
                            <div className='OB1useType' onClick={() => selectBtn("Personal")}>
                                {useType === "Personal" ? <img className="OB1Icon" src={SelectedImg} />
                                    : <i className="fa fa-circle-thin OB1Icon" aria-hidden="true"></i>}
                                <img src="/assets/personal.png" className="OB1Image" />
                                <h3>For personal use</h3>
                                {/* <div>Write better. Think more. </div><div>clearly. Stay organized</div> */}
                            </div>
                            {/* <div className='OB1useType' onClick={() => selectBtn("School")}>
                        {useType === "School" ? <img className="OB1Icon" src={SelectedImg} />
                            : <i className="fa fa-circle-thin OB1Icon" aria-hidden="true"></i>}
                        <img src="/assets/school.png" className="OB1Image" />
                        <h3>For school</h3>
                        {/* <div>Keep your notes, research and </div><div>tasks all in one place.</div> 
                    </div> */}
                        </div>
                        <button className='OB1button' disabled={useType ? false : true} onClick={() => continueBtn()}>Continue</button>
                        {/* <div style={{ cursor: "pointer", margin: "5px auto" }} onClick={() => skip()}>Skip</div> */}
                    </div>}

                    {(useType === "Team" && clickContinue) && <div className='OB1useTypeTemplate'>
                        <h1>Tell us about yourself</h1>
                        <h4>We'll customize your Duonut experience based on your choice.</h4>
                        <div className="OB1TeamContainer">
                            <form id="demoForm" onSubmit={gotoInvite}>
                                <div className='OB1input_container'>
                                    <label>What kind of work do you do?</label>
                                    <select id="work" required className='OB1input_container_select' value={work} onChange={e => handleWorkChange(e)}>
                                        <option value="" disabled selected style={{ display: "none", color: "#44474d" }}>Select response*</option>
                                        <option value="Marketing">Marketing</option>
                                        <option value="Engineering">Engineering</option>
                                        <option value="IT">IT</option>
                                        <option value="Design">Design</option>
                                        <option value="Human Resources">Human Resources</option>
                                        <option value="Sales">Sales</option>
                                        <option value="Finance">Finance</option>
                                        <option value="Operations">Operations</option>
                                        <option value="Educator">Educator</option>
                                        <option value="Product Management">Product Management</option>
                                        <option value="Student">Student</option>
                                        <option value="Customer Service">Customer Service</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>
                                {/* <div className='OB1input_container'>
                            <label>What is your role?</label>
                            <select id="role" required className='OB1input_container_select' value={role} onChange={e => handleRoleChange(e)}>
                                <option value="" disabled selected style={{ display: "none", color: "#44474d" }}>Select response*</option>
                                <option value="Executive">Executive</option>
                                <option value="Department Lead">Department Lead</option>
                                <option value="Team Manager">Team Manager</option>
                                <option value="Team Member">Team Member</option>
                                <option value="Solo-preneur / Freelancer">Solo-preneur / Freelancer</option>
                                <option value="Using Duonut just for myself">Using Duonut just for myself</option>
                            </select>
                        </div> */}
                                <div className='OB1input_container'>
                                    <label>What is the size of your company?</label>
                                    <select id="companySize" required className='OB1input_container_select' value={companySize} onChange={e => handleCompanySizeChange(e)}>
                                        <option value="" disabled selected style={{ display: "none", color: "#44474d" }}>Select response*</option>
                                        <option value="1-49">1-49</option>
                                        <option value="50-99">50-99</option>
                                        <option value="100-299">100-299</option>
                                        <option value="00-999">300-999</option>
                                        <option value="1000-5000">1000-5000</option>
                                        <option value="5000+">5000+</option>
                                    </select>
                                </div>
                                <div className='OB1input_container'>
                                    <label>What are you planning to do in Duonut?</label>
                                    <Select
                                        className='OB1input_container_select'
                                        isMulti
                                        options={planInDuonutOptions}
                                        value={planInDuonutValues}
                                        onChange={handlePlanInDuonutChange}
                                    />
                                </div>

                                <button type='submit' className='OB1button'>Continue</button>
                            </form>
                            {/* <div style={{ cursor: "pointer", margin: "5px auto" }} onClick={() => skip()}>Skip</div> */}
                        </div>
                    </div>}
                    {(invitePage && !clickContinue) && <div className='OB1useTypeTemplate'>
                        <h1>Invite Teammates</h1>
                        <h4>Get the most out of Duonut by inviting your teammates.</h4>
                        <div className="OB1TeamContainer">
                            <form id="demoForm" onSubmit={submit}>
                                <div className='OB1input_container'>
                                    <div>Send Invites</div>
                                    <input className='OB1input_container_select'
                                        placeholder="Email address"
                                        value={email1}
                                        onChange={e => handleEmail1Change(e)}
                                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                        required
                                    />
                                </div>
                                <div className='OB1input_container'>
                                    <input className='OB1input_container_select'
                                        placeholder="Email address"
                                        value={email2}
                                        onChange={e => handleEmail2Change(e)}
                                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                    />
                                </div>
                                <div className='OB1input_container'>
                                    <input className='OB1input_container_select'
                                        placeholder="Email address"
                                        value={email3}
                                        onChange={e => handleEmail3Change(e)}
                                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                    />
                                </div>

                                <button type='submit' className='OB1button'>Send Invite</button>
                            </form>
                            <div style={{ cursor: "pointer", margin: "1vh auto" }} onClick={(e) => submit(e)}>Skip</div>

                        </div>
                    </div>}

                    {(useType === "Personal" && clickContinue) && <div className='OB1useTypeTemplate'>
                        <h1>Tell us about yourself</h1>
                        <h4>We'll customize your Duonut experience based on your choice.</h4>
                        <div className="OB1TeamContainer" style={{ paddingTop: "2vh" }}>
                            <form id="demoForm" onSubmit={submit}>
                                <div className='OB1input_container'>
                                    <label>What kind of work do you do?</label>
                                    <select id="work" required className='OB1input_container_select' value={work} onChange={e => handleWorkChange(e)}>
                                        <option value="" disabled selected style={{ display: "none", color: "#44474d" }}>Select response*</option>
                                        <option value="Marketing">Marketing</option>
                                        <option value="Engineering">Engineering</option>
                                        <option value="IT">IT</option>
                                        <option value="Design">Design</option>
                                        <option value="Human Resources">Human Resources</option>
                                        <option value="Sales">Sales</option>
                                        <option value="Finance">Finance</option>
                                        <option value="Operations">Operations</option>
                                        <option value="Educator">Educator</option>
                                        <option value="Product Management">Product Management</option>
                                        <option value="Student">Student</option>
                                        <option value="Customer Service">Customer Service</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>
                                {/* <div className='OB1input_container'>
                            <label>What is your role?</label>
                            <select id="role" required className='OB1input_container_select' value={role} onChange={e => handleRoleChange(e)}>
                                <option value="" disabled selected style={{ display: "none", color: "#44474d" }}>Select response*</option>
                                <option value="Executive">Executive</option>
                                <option value="Department Lead">Department Lead</option>
                                <option value="Team Manager">Team Manager</option>
                                <option value="Team Member">Team Member</option>
                                <option value="Solo-preneur / Freelancer">Solo-preneur / Freelancer</option>
                                <option value="Using Duonut just for myself">Using Duonut just for myself</option>
                            </select>
                        </div> */}
                                <div className='OB1input_container'>
                                    <label>What are you planning to do in Duonut?</label>
                                    <Select
                                        className='OB1input_container_select'
                                        isMulti
                                        options={planInDuonutOptions}
                                        value={planInDuonutValues}
                                        onChange={handlePlanInDuonutChange}
                                    />
                                </div>

                                <button type='submit' className='OB1button'>Continue</button>
                            </form>
                            {/* <div style={{ cursor: "pointer", margin: "5px auto" }} onClick={() => skip()}>Skip</div> */}
                        </div>
                    </div>}

                    {(useType === "School" && clickContinue) && <div className='OB1useTypeTemplate'>
                        <h1>Tell us about yourself</h1>
                        <h4>We'll customize your Duonut experience based on your choice.</h4>
                        <div className="OB1TeamContainer" style={{ paddingTop: "2vh" }}>
                            <form id="demoForm" onSubmit={submit}>
                                <div className='OB1input_container'>
                                    <label>What kind of work do you do?</label>
                                    <select id="work" required className='OB1input_container_select' value={work} onChange={e => handleWorkChange(e)}>
                                        <option value="" disabled selected style={{ display: "none", color: "#44474d" }}>Select response*</option>
                                        <option value="Student">Student</option>
                                        <option value="Educator">Educator</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>
                                <div className='OB1input_container'>
                                    <label>What are you planning to do in Duonut?</label>
                                    <Select
                                        className='OB1input_container_select'
                                        isMulti
                                        options={planInDuonutOptions}
                                        value={planInDuonutValues}
                                        onChange={handlePlanInDuonutChange}
                                    />
                                </div>

                                <button type='submit' className='OB1button'>Continue</button>
                            </form>
                            {/* <div style={{ cursor: "pointer", margin: "5px auto" }} onClick={() => skip()}>Skip</div> */}
                        </div>
                    </div>}

                </div>}</>
            )}</>
    );

}

export default OnBoarding;
