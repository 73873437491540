import React, { useEffect, useState } from 'react';
import './TemplateDetailsPage.css';
import { frontendUrl } from "../../../services/constants";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from 'react-router-dom';
import { getAdminDuonuts } from '../../../services/duonut'
// import HomePageFooter from '../../components/HomePageFooter/HomePageFooter';
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { toggleChangeDuonutNameModal, updateDuonutDefaultName, updateDuonutNavigateUrl } from "../../../redux/slices/DuonutNameReducer";
// import { Navigation, Pagination, Scrollbar, Mousewheel } from 'swiper/modules';
import { Swiper, SwiperSlide } from "swiper/react";
import { toggleTemplateModal } from '../../../redux/slices/CreateFromScratchReducer';

const TemplateDetailsPageModal = ({ idName, categoryNameCopy, setcategoryNameCopy, setIdName }) => {
    const dispatch = useDispatch();
    const paramsid = useParams();
    // const duonut_name = paramsid.anotherId;
    // const duonut_category = paramsid.id;
    const duonut_name = idName;
    const duonut_category = categoryNameCopy;
    const navigate = useNavigate();
    const { width } = useWindowDimensions();
    const [selectedData, setselectedData] = useState("");
    const [selectedItem, setselectedItem] = useState(1);
    const [selectedThumbnailIndex, setSelectedThumbnailIndex] = useState(0);

    // console.log("paramsid", paramsid);

    // console.log(duonut_name);
    const searchWords = duonut_name.split("-");
    const searchRegexes = searchWords.map((word) => new RegExp(word, "i"));

    // console.log(searchWords,searchRegexes);
    window.scrollTo(0, 0);

    useEffect(() => {
        getAdminDuonuts((err, res) => {
            if (err) console.log(err)
            else {
                if (res.data.length === 0) return
                // console.log(res);
                var allData = res.data.sort((a, b) => a.updated_at < b.updated_at ? 1 : -1);
                // console.log("AdminDuonut", res.data.sort((a, b) => a.updated_at < b.updated_at ? 1 : -1))
                allData.map((itemData, i) => {
                    let itemDataName = itemData.name.replace(/[^\w\s]/gi, '').toLowerCase()
                    if (duonut_category === itemData.duonut_category_id.name.toLowerCase().replace(/ /g, "_") &&
                        (searchRegexes.every((regex) => itemDataName?.match(regex)) || searchRegexes.every((regex) => itemData.keywords?.some(keyword => keyword.match(regex))))) {
                        // console.log("inside", itemData);
                        setselectedData(itemData);
                    }
                    return null;
                });
            }
        })
        // console.log("inside");
    }, []);

    // useEffect(() => {
    //     if (width <= 768) {
    //         setselectedItem(2);
    //     }
    // }, [width]);

    const onClickTemplateButton = () => {
        dispatch(updateDuonutDefaultName(selectedData.name));
        dispatch(updateDuonutNavigateUrl(`/design?selectedDuonutId=${selectedData.id}`));
        dispatch(toggleChangeDuonutNameModal(true));
        dispatch(toggleTemplateModal(false));
        // window.open(`/design?selectedDuonutId=${selectedData.id}`);
    }
    const onClickIcon = (num) => {
        setselectedItem(num);
    }
    const handleBack = () => {
        setcategoryNameCopy("");
        setIdName("");
    }
    const handleThumbnailClick = (index) => {
        setSelectedThumbnailIndex(index); // Update the selected thumbnail index
    };


    return (
        <>
            <div className='ADP1iframe' style={{ margin: "0", width: "100%" }}>
                <div className='ADP1backBtn' onClick={() => { handleBack(); }}>
                    <i style={{ fontSize: "1.5rem", paddingRight: "5px" }} className="fa fa-angle-left" aria-hidden="true"></i>Back to templates
                </div>
                {selectedData ? (
                    <div style={{
                        marginTop: '2%',
                        display: 'flex',
                        borderTop: '2px solid #ccc', flexDirection: 'column',
                    }}>
                        {/* Left bar with thumbnails */}
                        {/* <div className='' style={{margin:"0",width:"auto"}}> */}
                        {/* <div className='ADP1templatetext'>TEMPLATES</div> */}
                        {/* <h1 style={{ fontSize: width >= 768 ? "3.5rem" : "" }}>{selectedData.name}</h1> */}
                        {/* {selectedData.description ? <div style={{ padding: "4vh", fontSize: "1.4rem" }}>{selectedData.description}</div>
                            : <div style={{ padding: "4vh", fontSize: "1.4rem" }}>Customise this template to create your own workflow in minutes and start using in your app/website or directly share.
                                {/* <br></br><br></br>DIY no-code platform. No-design experience required. Feedback: hello@duonut.com 
                            </div>} */}
                        <div style={{
                            width: "100%", // Full width for mobile
                            // width: "20%",
                            padding: "1rem",
                            overflowY: "auto", // Allow vertical scrolling
                            maxHeight: "80vh", // Set a maximum height
                            boxSizing: "border-box",
                            order: 2
                        }}> {/* Added overflowY and maxHeight */}
                            {selectedData.user_activity.map((activity, index) => (
                                <div
                                    key={index}
                                    style={{
                                        marginBottom: "1rem",
                                        cursor: "pointer",
                                        width: "100%", // Ensure the div takes full width
                                        boxSizing: "border-box" // Include padding and border in the element's dimensions
                                    }}
                                    onClick={() => handleThumbnailClick(index)}
                                >
                                    <img
                                        src={activity.thumbnail}
                                        alt={`Thumbnail ${index + 1}`}
                                        style={{
                                            width: "100%", // Ensure the image takes full width
                                            height: "auto", // Maintain aspect ratio
                                            border: selectedThumbnailIndex === index ? "2px solid #337ab7" : "2px solid transparent", // Transparent border by default
                                            boxSizing: "border-box", // Include border in the element's dimensions
                                            transition: "border 0.3s ease" // Smooth transition for hover effect
                                        }}
                                        onMouseEnter={(e) => e.currentTarget.style.border = "2px solid #ccc"} // Add border on hover
                                        onMouseLeave={(e) => {
                                            // Revert to original border on mouse leave
                                            e.currentTarget.style.border = selectedThumbnailIndex === index ? "2px solid #337ab7" : "2px solid transparent";
                                        }}
                                    />
                                </div>
                            ))}
                        </div>

                        {/* Right side with Swiper and other content */}
                        <div style={{
                            // width: '100%', // Full width for mobile
                            padding: '1rem',
                            display: 'flex',
                            flexDirection: 'column',
                            // justifyContent: 'space-between',
                            overflowY: "auto",
                            order: 1
                        }}>
                            {selectedItem === 1 && (

                                <div style={{ padding: "2rem", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    {/* <iframe
                                        className='ADP1iframe'
                                        style={{ height: "60vh", minHeight: "55vh" }}
                                        id="iframeid"
                                        // src={`${frontendUrl}/duonut/${selectedData.display_id}`}
                                        src={selectedData.user_activity[selectedThumbnailIndex].thumbnail}
                                        title="Duonut Iframe"
                                    ></iframe> */}
                                    <img
                                        src={selectedData.user_activity[selectedThumbnailIndex].thumbnail} // Use the selected thumbnail's image
                                        className="card-img-top ADP1movingImg"
                                        style={{
                                            width: '100%', // Ensure the image takes up the full width of the container
                                            height: '100%', // Ensure the image takes up the full height of the container
                                            objectFit: 'cover', // Crop the image to fit the container while maintaining aspect ratio
                                            borderRadius: "5%",
                                        }}
                                        loading="lazy"
                                        alt={`Selected Thumbnail`}
                                    />
                                </div>
                            )}

                            {/* {selectedItem === 2 && (
                                <div style={{ padding: "2rem", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    {/* <img src="../assets/glow-left.png" className="ADP1glow" /> 
                                    <div className='ADP1mobileScreen'></div>
                                    <iframe
                                        className='ADP1iframeMob'
                                        style={{ height: "60vh", minHeight: "55vh" }}
                                        id="iframeid"
                                        src={`${frontendUrl}/duonut/${selectedData.display_id}`}
                                        // src={selectedData.user_activity[selectedThumbnailIndex].thumbnail} 
                                        title="Duonut Iframe"
                                    ></iframe>
                                </div>
                            )} */}

                            {(selectedItem === 3 && selectedData.user_activity[selectedThumbnailIndex]?.thumbnail) && (
                                <div className='ADP1movingThumbnail' style={{ overflow: 'hidden', width: '100%', height: '500px' }}>
                                    {/* Set a fixed height for the swiper container */}
                                    <Swiper
                                        key={selectedThumbnailIndex} // Force re-render Swiper when selectedThumbnailIndex changes
                                        id={selectedData.id}
                                        className=""
                                        style={{ margin: "0", width: '100%', height: '100%' }}
                                        virtual
                                        autoplay
                                        onSlideChange={(swiperCore) => console.log(swiperCore)}
                                    >
                                        {/* Render the selected thumbnail in the swiper */}
                                        <SwiperSlide
                                            style={{
                                                listStyle: "none",
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '100%', // Fixed width for the slide
                                                height: '500px', // Fixed height for the slide
                                            }}
                                        >
                                            <img
                                                src={selectedData.user_activity[selectedThumbnailIndex].thumbnail} // Use the selected thumbnail's image
                                                className="card-img-top ADP1movingImg"
                                                style={{
                                                    width: '100%', // Ensure the image takes up the full width of the container
                                                    height: '100%', // Ensure the image takes up the full height of the container
                                                    objectFit: 'cover', // Crop the image to fit the container while maintaining aspect ratio
                                                }}
                                                loading="lazy"
                                                alt={`Selected Thumbnail`}
                                            />
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            )}

                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                {/* <div style={{ display: "flex", justifyContent: "center" }}>
                                    {width >= 768 && (
                                        <div
                                            className='ADP1icon'
                                            style={{ backgroundColor: selectedItem === 1 ? "rgba(186, 193, 196, 0.7)" : "", padding: "10px 13px 15px 13px" }}
                                            onClick={() => onClickIcon(1)}
                                        >
                                            <i className="fa fa-desktop" aria-hidden="true"></i>
                                        </div>
                                    )}
                                    <div
                                        className='ADP1icon'
                                        style={{ backgroundColor: selectedItem === 2 ? "rgba(186, 193, 196, 0.7)" : "" }}
                                        onClick={() => onClickIcon(2)}
                                    >
                                        <i className="fa fa-mobile" style={{ fontSize: "2rem" }} aria-hidden="true"></i>
                                    </div>
                                    {/* <i className="fa fa-link ADP1icon" aria-hidden="true" style={{ backgroundColor: selectedItem === 3 ? "#0B1350" : "" }} onClick={() => onClickIcon(3)}></i> 
                                </div> */}

                                <button className='ADP1Button' style={{ margin: "0" }} onClick={() => onClickTemplateButton()}>
                                    Start with this template
                                </button>
                            </div>

                            {/* 
                <div className='ADP1getStart' onClick={() => onClickTemplateButton()}>
                    <span className='ADP1getStartBtn'>Get Started</span>
                </div> */}
                        </div>
                    </div>
                ) : (
                    <div style={{ padding: "5rem" }}>
                        <div className='ADP1skeleton'></div>
                        {/* <img alt="loader" src={Loader} height={50} width={50} /> */}
                    </div>
                )}

                {/* <HomePageFooter /> */}
            </div>

        </>
    );

}

export default TemplateDetailsPageModal;