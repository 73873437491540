import React, { useState, useEffect } from 'react';
import './Element.css';
// import { EditText, EditTextarea } from "react-edit-text";
import "react-edit-text/dist/index.css";
import { useSelector, useDispatch } from "react-redux";
// import { updateUserActivityElements, updateUserActivity, selectSlide, updateGoToSlide, updateElementInputFocus } from '../../redux/slices/userActivity';
import { updateGoToSlide, updateElementInputFocus } from '../../redux/slices/userActivity';
import { useLocation, } from "react-router-dom";
import { showDuonutresponse, saveQuizAnsAsync, saveQuizResponse, showduonutData } from '../../redux/slices/DuonutReducer';
import { getOpenEndedQuestionAsync } from '../../redux/slices/AiDuonutNewReducer';
import axios from "axios";
import { domain } from "../../services/constants";
import useWindowDimensions from '../../hooks/useWindowDimensions';

var aiCount = 0;
var aiConversation = [];
const RadioGroup = ({
    slideId,
    id,
    elementAttr: {
        text, color, fontFamily, fontSize, fontWeight, options, fontStyle, textDecoration, textTransform, textAlign,
        lineHeight, letterSpacing, backgroundColor, border, margin, padding, borderRadius, other, gap,
        labelFontSize, labelFontWeight, labelColor, required, fieldPosition,
        openEnded, openEndedObjective, openEndedFeedbackDesired,
    },
    elementHoverAttr,
    // conditions, 
    language,
}) => {
    const dispatch = useDispatch();
    // const { userActivities, selectedUserActivity, elements, s,electedComponent, selectedSlide } = useSelector((state) => state.userActivity);
    const { selectedSlide } = useSelector((state) => state.userActivity);
    const location = useLocation();
    const save_ans_response = useSelector(showDuonutresponse);
    const { viewer_display_id, viewer_nick_name, viewer_url_params } = useSelector((state) => state.duonut);
    const { viewType } = useSelector((state) => state.duonutSettings);
    const [otherText, setOtherText] = useState('');
    const [isOther, setisOther] = useState(false);
    const [hover, setHover] = useState(-1);
    const [inputText, setInputText] = useState("");
    const [optionsText, setoptionsText] = useState([]);
    const [loading, setloading] = useState(false);
    const [textField, setTextField] = useState('');
    const duonutDataMain = useSelector(showduonutData);
    const duonutData = duonutDataMain[0];
    const [selectedAnswer, setSelectedAnswer] = useState([]);
    const { width } = useWindowDimensions();

    useEffect(() => {
        setisOther(false);
        aiCount = 0;
        aiConversation = [];
        if (location.pathname.includes("/duonut/") && slideId === `slide-${selectedSlide}` && language !== "en") {
            fetchText(text);
            fetchOptionsText(options);
        } else {
            setInputText(text);
            setoptionsText(options);
        }
    }, [text, options]);

    const fetchOptionsText = async (texts) => {
        const translatedTexts = []; // Initialize an array to hold translated texts
        // const delimiter = "|;|"; // Delimiter to separate texts
        const delimiter = "[[SEP]]";
        const delimiterRegex = /\[\[SEP\]\]|\[SEP\]/;

        // Combine all texts into a single string separated by semicolons
        const textToTranslate = texts.join(delimiter);

        // Translate the combined text
        const translated = await translateText(textToTranslate, language);

        // Split the translated text back into individual parts using the semicolon delimiter
        const translatedArray = translated.split(delimiterRegex);

        // Push the translated texts into the array
        translatedTexts.push(...translatedArray); // Spread the array into the translatedTexts

        setoptionsText(translatedTexts); // Update the state with the translated texts
    };
    const fetchText = async (texts) => {
        const t_text = await translateText(texts, language);
        setInputText(t_text);
    };
    const translateText = async (texts, targetLanguage) => {
        try {
            const response = await axios({
                method: "POST",
                url: `${domain}/duonut/language_translator`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: {
                    source: "en",
                    target: targetLanguage,
                    query: texts,
                },
            });

            if (response.status === 200) {
                // console.log("Translation:", response);
                return response?.data.translation; // return the translated text
            } else {
                // Handle unsuccessful status codes
                return "Error: Translation failed. Please try again.";
            }
        } catch (error) {
            console.error('Error in translation API:', error);
            return "Error translating text. Please try again."; // Handle API errors gracefully
        }
    };

    const submitResponse = (item) => {
        if (location.pathname.includes("/duonut/") && !openEnded) {
            // console.log("ans", item)

            if (item === "other") {
                setisOther(true);
            } else {
                setisOther(false);
            }

            var save_ans = {
                display_id: viewer_display_id,
                watch_id: save_ans_response[0] ? save_ans_response[0].watch_id : "",
                result: 0,
                question: text ? text : "",
                answered_text: item,
                // end: lastQue ? "1" : "0",
                nickname: viewer_nick_name,
                step: selectedSlide + 1,
                type: "RadioGroup",
                // country: location[0] ? location[0].address?.country : "",
                // city: location[0] ? location[0].address?.state_district : "",
                // location: location[0] ? location[0].display_name : "",
                url_params: viewer_url_params,
            }
            // console.log(save_ans);
            const formData = new FormData();
            Object.keys(save_ans).forEach(key => {
                const value = typeof save_ans[key] === "object" ? JSON.stringify(save_ans[key]) : save_ans[key];
                formData.append(key, value);
            });

            dispatch(saveQuizAnsAsync(formData));

            var save_response = {
                id: id,
                type: "RadioGroup",
                step: selectedSlide + 1,
                question: text ? text : "",
                answered_text: item,
            }
            dispatch(saveQuizResponse(save_response));
        }
        else if (location.pathname.includes("/duonut/") && openEnded) {
            // console.log("question", inputText, textField);
            setSelectedAnswer(item); // Update selectedAnswer state

            var userIsPro = false;
            if (duonutData?.created_by.pro_user) {
                userIsPro = true;
            } else if (duonutData?.created_by.expire_date) {
                const currentDate = new Date();
                const dateToCompare = new Date(duonutData?.created_by.expire_date);
                const comparisonResult = currentDate < dateToCompare;
                if (comparisonResult) {
                    userIsPro = true;
                } else {
                    userIsPro = false;
                }
            }
            if ((!userIsPro && aiCount < parseInt(duonutData?.setting?.open_ended_conversation_limit)) || (userIsPro && aiCount < parseInt(duonutData?.setting?.open_ended_conversation_limit))) {

                setloading(true);

                aiConversation = [...aiConversation,
                { question: inputText, answer: textField },
                ];

                var get_ans = {
                    question: inputText,
                    answer: item,
                    objective: openEndedObjective,
                    feedbackDesired: openEndedFeedbackDesired,
                    aiConversation: aiConversation,
                    option_type: 1,
                }

                dispatch(getOpenEndedQuestionAsync(get_ans)).then((res) => {
                    // console.log(res);
                    if (res?.question) {
                        // console.log(res?.question);
                        setInputText(res?.question);
                        if (document.getElementById(`qainput-${selectedSlide + 1}-ai${aiCount}`)) {
                            document.getElementById(`qainput-${selectedSlide + 1}-ai${aiCount}`).value = "";
                        }
                        setloading(false);

                        var save_ans = {
                            display_id: viewer_display_id,
                            watch_id: save_ans_response[0] ? save_ans_response[0].watch_id : "",
                            result: 1,
                            question: inputText ? inputText : "",
                            answered_text: item,
                            // end: lastQue ? "1" : "0",
                            nickname: viewer_nick_name,
                            step: aiCount === 0 ? selectedSlide + 1 : `${selectedSlide + 1}-ai${aiCount}`,
                            type: aiCount === 0 ? "RadioGroup" : "QuestionAnswer",
                            // country: location[0] ? location[0].address?.country : "",
                            // city: location[0] ? location[0].address?.state_district : "",
                            // location: location[0] ? location[0].display_name : "",
                            url_params: viewer_url_params,
                        }
                        // console.log(save_ans);
                        const formData = new FormData();
                        Object.keys(save_ans).forEach(key => {
                            const value = typeof save_ans[key] === "object" ? JSON.stringify(save_ans[key]) : save_ans[key];
                            formData.append(key, value);
                        });

                        dispatch(saveQuizAnsAsync(formData));

                        var save_response = {
                            type: aiCount === 0 ? "RadioGroup" : "QuestionAnswer",
                            step: aiCount === 0 ? selectedSlide + 1 : `${selectedSlide + 1}-ai${aiCount}`,
                            question: inputText ? inputText : "",
                            answered_text: item,
                        }
                        dispatch(saveQuizResponse(save_response));

                        aiCount++;

                    }
                }).catch((e) => {
                    console.log(e.message, e);
                });

            } else {
                var save_ans = {
                    display_id: viewer_display_id,
                    watch_id: save_ans_response[0] ? save_ans_response[0].watch_id : "",
                    result: 1,
                    question: inputText ? inputText : "",
                    answered_text: item,
                    // end: lastQue ? "1" : "0",
                    nickname: viewer_nick_name,
                    step: aiCount === 0 ? selectedSlide + 1 : `${selectedSlide + 1}-ai${aiCount}`,
                    type: aiCount === 0 ? "RadioGroup" : "QuestionAnswer",
                    // country: location[0] ? location[0].address?.country : "",
                    // city: location[0] ? location[0].address?.state_district : "",
                    // location: location[0] ? location[0].display_name : "",
                    url_params: viewer_url_params,
                }
                // console.log(save_ans);
                const formData = new FormData();
                Object.keys(save_ans).forEach(key => {
                    const value = typeof save_ans[key] === "object" ? JSON.stringify(save_ans[key]) : save_ans[key];
                    formData.append(key, value);
                });

                dispatch(saveQuizAnsAsync(formData));

                var save_response = {
                    type: aiCount === 0 ? "RadioGroup" : "QuestionAnswer",
                    step: aiCount === 0 ? selectedSlide + 1 : `${selectedSlide + 1}-ai${aiCount}`,
                    question: inputText ? inputText : "",
                    answered_text: item,
                }
                dispatch(saveQuizResponse(save_response));

                var nextPage = selectedSlide + 1;

                setTimeout(() => {
                    dispatch(updateGoToSlide(nextPage));
                }, 1000);
            }

        }
    }

    const submitTextResponse = (item) => {
        if (location.pathname.includes("/duonut/") && !openEnded) {
            // console.log("ans", item)

            var save_ans = {
                display_id: viewer_display_id,
                watch_id: save_ans_response[0] ? save_ans_response[0].watch_id : "",
                result: 0,
                question: text ? text : "",
                answered_text: item + " - " + otherText,
                // end: lastQue ? "1" : "0",
                nickname: viewer_nick_name,
                step: selectedSlide + 1,
                type: "RadioGroup",
                // country: location[0] ? location[0].address?.country : "",
                // city: location[0] ? location[0].address?.state_district : "",
                // location: location[0] ? location[0].display_name : "",
                url_params: viewer_url_params,
            }
            // console.log(save_ans);
            const formData = new FormData();
            Object.keys(save_ans).forEach(key => {
                const value = typeof save_ans[key] === "object" ? JSON.stringify(save_ans[key]) : save_ans[key];
                formData.append(key, value);
            });

            dispatch(saveQuizAnsAsync(formData));

            var save_response = {
                id: id,
                type: "RadioGroup",
                step: selectedSlide + 1,
                question: text ? text : "",
                answered_text: item + " - " + otherText,
            }
            dispatch(saveQuizResponse(save_response));
        }
        else if (location.pathname.includes("/duonut/") && openEnded) {
            // console.log("question", inputText, textField);
            setSelectedAnswer(item); // Update selectedAnswer state

            var userIsPro = false;
            if (duonutData?.created_by.pro_user) {
                userIsPro = true;
            } else if (duonutData?.created_by.expire_date) {
                const currentDate = new Date();
                const dateToCompare = new Date(duonutData?.created_by.expire_date);
                const comparisonResult = currentDate < dateToCompare;
                if (comparisonResult) {
                    userIsPro = true;
                } else {
                    userIsPro = false;
                }
            }
            if ((!userIsPro && aiCount < parseInt(duonutData?.setting?.open_ended_conversation_limit)) || (userIsPro && aiCount < parseInt(duonutData?.setting?.open_ended_conversation_limit))) {

                setloading(true);

                aiConversation = [...aiConversation,
                { question: inputText, answer: textField },
                ];

                var get_ans = {
                    question: inputText,
                    answer: item,
                    objective: openEndedObjective,
                    feedbackDesired: openEndedFeedbackDesired,
                    aiConversation: aiConversation,
                    option_type: 1,
                }

                dispatch(getOpenEndedQuestionAsync(get_ans)).then((res) => {
                    // console.log(res);
                    if (res?.question) {
                        // console.log(res?.question);
                        setInputText(res?.question);
                        if (document.getElementById(`qainput-${selectedSlide + 1}-ai${aiCount}`)) {
                            document.getElementById(`qainput-${selectedSlide + 1}-ai${aiCount}`).value = "";
                        }
                        setloading(false);

                        var save_ans = {
                            display_id: viewer_display_id,
                            watch_id: save_ans_response[0] ? save_ans_response[0].watch_id : "",
                            result: 1,
                            question: inputText ? inputText : "",
                            answered_text: item,
                            // end: lastQue ? "1" : "0",
                            nickname: viewer_nick_name,
                            step: aiCount === 0 ? selectedSlide + 1 : `${selectedSlide + 1}-ai${aiCount}`,
                            type: "QuestionAnswer",
                            // country: location[0] ? location[0].address?.country : "",
                            // city: location[0] ? location[0].address?.state_district : "",
                            // location: location[0] ? location[0].display_name : "",
                            url_params: viewer_url_params,
                        }
                        // console.log(save_ans);
                        const formData = new FormData();
                        Object.keys(save_ans).forEach(key => {
                            const value = typeof save_ans[key] === "object" ? JSON.stringify(save_ans[key]) : save_ans[key];
                            formData.append(key, value);
                        });

                        dispatch(saveQuizAnsAsync(formData));

                        var save_response = {
                            type: "QuestionAnswer",
                            step: aiCount === 0 ? selectedSlide + 1 : `${selectedSlide + 1}-ai${aiCount}`,
                            question: inputText ? inputText : "",
                            answered_text: item,
                        }
                        dispatch(saveQuizResponse(save_response));

                        aiCount++;

                    }
                }).catch((e) => {
                    console.log(e.message, e);
                });

            } else {
                var save_ans = {
                    display_id: viewer_display_id,
                    watch_id: save_ans_response[0] ? save_ans_response[0].watch_id : "",
                    result: 1,
                    question: inputText ? inputText : "",
                    answered_text: item,
                    // end: lastQue ? "1" : "0",
                    nickname: viewer_nick_name,
                    step: aiCount === 0 ? selectedSlide + 1 : `${selectedSlide + 1}-ai${aiCount}`,
                    type: "QuestionAnswer",
                    // country: location[0] ? location[0].address?.country : "",
                    // city: location[0] ? location[0].address?.state_district : "",
                    // location: location[0] ? location[0].display_name : "",
                    url_params: viewer_url_params,
                }
                // console.log(save_ans);
                const formData = new FormData();
                Object.keys(save_ans).forEach(key => {
                    const value = typeof save_ans[key] === "object" ? JSON.stringify(save_ans[key]) : save_ans[key];
                    formData.append(key, value);
                });

                dispatch(saveQuizAnsAsync(formData));

                var save_response = {
                    type: "QuestionAnswer",
                    step: aiCount === 0 ? selectedSlide + 1 : `${selectedSlide + 1}-ai${aiCount}`,
                    question: inputText ? inputText : "",
                    answered_text: item,
                }
                dispatch(saveQuizResponse(save_response));

                var nextPage = selectedSlide + 1;

                setTimeout(() => {
                    dispatch(updateGoToSlide(nextPage));
                }, 1000);
            }

        }
    }

    const handleOtherInputChange = (e) => {
        if (location.pathname.includes("/duonut/")) {
            setOtherText(e.target.value);
        }
    }

    const handleInputFocus = () => {
        dispatch(updateElementInputFocus(true));
    }
    const handleInputBlur = () => {
        dispatch(updateElementInputFocus(false));
    }
    const handleQuestionAnswerInputChange = (e) => {
        // console.log(e)
        if (location.pathname.includes("/duonut/")) {
            setTextField(e.target.value);
            if (e.keyCode === 13) {
                document.getElementById("AiSubmitBtn").click();
            }
        }
    }
    // useEffect(() => {
    //     const element = document.getElementById(`element-${id}-${slideId}`);
    //     if (element && aiCount > 0) {
    //         console.log("okay");
    //         element.style.top = "120px";
    //         element.style.left = "200px";
    //     }
    // }, [aiCount]);
    return (
        <>{aiCount === 0 &&
            <div style={{
                display: "flex", flexDirection: "column", gap: viewType === 3 || width < 800 ? "80px" : gap
            }}>
                <div style={{
                    width: "100%", paddingBottom: '10px',
                    color: labelColor, fontFamily, fontSize: labelFontSize, fontWeight: labelFontWeight, fontStyle, textDecoration, textTransform,
                    textAlign, lineHeight, letterSpacing
                }}>{inputText}</div>
                <div style={{
                    display: "flex", justifyContent:
                        fieldPosition === "row"
                            ? "space-around"
                            : textAlign === "justify"
                                ? "flex-start"
                                : textAlign,
                    // alignItems: 'center', 
                    alignItems: 'flex-start',
                    flexDirection: (viewType === 3 || width < 800) ? "column" : fieldPosition
                }}>
                    {options.map((item, i) => {
                        const radioId = `radio_option_${slideId}_${i}`;
                        return <div style={{
                            display: "flex", justifyContent: "flex-start", alignItems: "center", lineHeight, width: fieldPosition === "column" ? "100%" : "100%",
                        }} key={i} >
                            <input type="radio" id={radioId} name={`radio_option_${slideId}`}
                                disabled={location.pathname.includes("/duonut/") ? false : true}
                                style={{ width: fontSize, height: fontSize, minWidth: fontSize, minHeight: fontSize, margin: "2px" }}
                                onChange={() => submitResponse(item)} />
                            <label for={radioId} style={{
                                backgroundColor: hover === i ? elementHoverAttr?.backgroundColor : backgroundColor,
                                border: hover === i ? elementHoverAttr?.border : border ? border : "0",
                                color: hover === i ? elementHoverAttr?.color : color,
                                borderRadius, fontFamily, fontSize, fontWeight, fontStyle, textDecoration,
                                textTransform, textAlign, letterSpacing, padding, margin,
                                cursor: (location.pathname).includes("/duonut/") ? "pointer" : "default",
                                width: "100%", height: "100%"
                            }}
                                onMouseEnter={() => setHover(i)}
                                onMouseLeave={() => setHover(-1)}>
                                <div style={{}}>{optionsText[i]}</div>
                            </label>
                        </div>
                    })}
                    {other && <div style={{
                        display: "flex", justifyContent: "flex-start", alignItems: "center", lineHeight, width: fieldPosition === "column" ? "100%" : "100%"
                    }} >
                        <input type="radio" id={"radio_option_other" + slideId} name="radio_option"
                            disabled={location.pathname.includes("/duonut/") ? false : true}
                            style={{ width: fontSize, height: fontSize, minWidth: fontSize, minHeight: fontSize, margin: "2px" }}
                            onChange={() => {
                                setisOther(true);
                                // submitResponse("other")
                            }} />
                        <label for={"radio_option_other" + slideId} style={{
                            backgroundColor: hover === options.length ? elementHoverAttr?.backgroundColor : backgroundColor,
                            border: hover === options.length ? elementHoverAttr?.border : border ? border : "0",
                            color: hover === options.length ? elementHoverAttr?.color : color,
                            borderRadius, fontFamily, fontSize, fontWeight, fontStyle, textDecoration,
                            textTransform, textAlign, letterSpacing, padding, margin,
                            cursor: (location.pathname).includes("/duonut/") ? "pointer" : "default",
                            width: "100%", height: "100%"
                        }}
                            onMouseEnter={() => setHover(options.length)}
                            onMouseLeave={() => setHover(-1)} >
                            <div style={{}}>Other</div>
                        </label>
                        {(location.pathname.includes("/duonut/") && isOther) &&
                            <>
                                <input type='text' style={{
                                    minWidth: "2rem", margin: "0px 6px", padding: "10px", width: "100%",
                                    color, fontFamily, fontSize: fontSize, fontWeight, fontStyle, textDecoration, textTransform,
                                    textAlign, lineHeight, letterSpacing,
                                    backgroundColor, borderBottom: "1px solid gray", borderRadius,
                                }}
                                    onChange={(e) => handleOtherInputChange(e)}
                                    // onBlur={() => {
                                    //     submitResponse("other");
                                    //     // submitTextResponse("other");
                                    // }}
                                    readOnly={(location.pathname.includes("/duonut/") && isOther) ? false : true}
                                />
                                <button onClick={() => { submitTextResponse("other"); }}
                                    style={{ backgroundColor: "#4299e1", color: "white", borderRadius: "6px", padding: '1rem', cursor: "pointer" }}>
                                    <i class="fa fa-arrow-right" aria-hidden="true"></i></button>
                            </>
                        }
                    </div>}
                </div>
            </div>
        }
            {openEnded && aiCount > 0 && <div style={{
                display: "flex", justifyContent: 'space-between', flexDirection: "column", height: width > 800 ? "200px" : "400px",
                // width: "600px"
            }}>
                <div
                    style={{
                        backgroundColor: "transparent",
                        border: "0",
                        outline: "none",
                        // display: "flex",
                        // alignItems: "center",
                        width: "100%",
                        color: labelColor, fontFamily, fontSize: fontSize, fontWeight, fontStyle, textDecoration, textTransform,
                        textAlign, lineHeight, letterSpacing
                    }}
                ><b>Followup question:</b> {inputText}</div>
                <div style={{ display: "flex", alignItems:  viewType === 3 || width < 800 ? "center": "unset", flexDirection: viewType === 3 || width < 800 ? "column" : "unset" }}>
                    <input type='text' key={`qainput-${selectedSlide + 1}-ai${aiCount}`}
                        id={`qainput-${selectedSlide + 1}-ai${aiCount}`}
                        style={{
                            width: "100%", minHeight: "40px",
                            color, fontFamily, fontSize: fontSize, fontStyle, textDecoration, textTransform,
                            textAlign: "left", lineHeight, letterSpacing, padding: "4px 10px", boxSizing: "border-box",
                            backgroundColor, border: '1px solid gray', margin: "0px 5px", borderRadius
                        }}
                        onKeyUp={(e) => handleQuestionAnswerInputChange(e)}
                        readOnly={location.pathname.includes("/duonut/") ? false : true}
                        required={required}
                        placeholder={"Type your answer here.."}
                    />
                    <button id="AiSubmitBtn" onClick={() => submitResponse(textField)}
                        style={{
                            backgroundColor: "#4299e1", color: "white", borderRadius: "6px", padding: '1rem',
                            cursor: "pointer", margin: viewType === 3 || width < 800 ? "1rem 0px" : "0px"
                        }}>
                        {loading ? <img alt="loader" src={require(`../../assets/Images/loader.gif`)} style={{ margin: "auto", height: "1rem" }} />
                            : viewType === 3 || width < 800 ? <span style={{ fontSize: "1rem" }}> Next</span> : <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" aria-hidden="true" focusable="false" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                            </svg>}
                    </button>
                </div>
            </div>}

        </>
    );
}

export default RadioGroup;
