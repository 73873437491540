import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./navbar.css";

import { Download } from "../../assets/icons/download2";
import NavMenu from "../Menu/navMenu";
// import SearchItems from "../Modals/SearchItems";
// import Searchbar from "../Searchbar";
import DotMenuItems from "./DotMenuItems";
import NavButtons from "./navButtons";
import NavItems from "./NavItems";

import useWindowDimensions from "../../hooks/useWindowDimensions"

import { openModal, showSignInForm, showSignUpForm } from "../../redux/slices/formDetails";
import { updateIsLoggedIn } from "../../redux/slices/user";

// import { setUserActivity, updateDiscardSaveModal, updateIsEditing } from "../../redux/slices/userActivity";
import { editActivities, publishActivities } from "../../services/activity";
import { isUserActivityUpdated } from "../../utils";
import SuccessModal from "../Modals/SuccessModal/SuccessModal";
// import PublishButton from "../PublishButton/publishButton";
import { frontendUrl, backendUrl } from "../../services/constants";

export default function Navbar() {
  const navigate = useNavigate();

  const [scrollPosition, setScrollPosition] = useState(0);
  const [searchItemsActive, setSearchItemsActive] = useState(false);
  const [dotMenuItemsActive, setDotMenuItemsActive] = useState(false);
  const [bottomProBar, setbottomProBar] = useState(true);

  const dispatch = useDispatch();
  const location = useLocation();

  const [navMenuActive, setNavMenuActive] = useState(false);
  const { width } = useWindowDimensions();

  const handleClick = () => dispatch(openModal());


  const { userActivities, selectedUserActivity, isEditing } = useSelector((state) => state.userActivity);
  const { isLoggedIn, userTrialDays, userProAccess, userAccountType } = useSelector((state) => state.user);
  const [successModalActive, setSuccessModalActive] = useState(false);
  const [subDomain, setSubDomain] = useState(null);
  const [channelLogo, setchannelLogo] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      const subDomainFromStorage = localStorage.getItem('subDomain');
      setSubDomain(subDomainFromStorage);
      const channelLogoStorage = localStorage.getItem('channelLogo');
      setchannelLogo(channelLogoStorage);
    }, 2000);

    return () => clearTimeout(timer); // Cleanup timeout if component unmounts
  }, []);


  const resetUserActivities = () => {
    // dispatch(updateSettings({ settings }))
    // dispatch(updateIsEditing({ isEditing: false }))
    localStorage.removeItem("userActivities");
    localStorage.removeItem("duonutSettings");
    // dispatch(setUserActivity({ activities: [{}], defaultActivities: [{}] }))
  };

  const handleNavigate = (navigateTo) => {
    if (navigateTo === "/") {
      // if (!isLoggedIn && isUserActivityUpdated(userActivities)) return dispatch(updateDiscardSaveModal(true));
      if (isLoggedIn) {
        navigate("/home");
        // window.location = "https://duonut.com";
      }
      else {
        navigate("/home");
        // window.location = "https://duonut.com";
      }
      window.scrollTo(0, 0);
    } else if (navigateTo === "/pdf") {
      if (isLoggedIn) return navigate("/pdf");
      // dispatch(updateDiscardSaveModal(true));
    } else if (navigateTo === "/workflow") {
      if (isLoggedIn) return navigate("/workflow");
      window.location = "/";
    } else if (navigateTo === "/myduonuts") {
      if (isLoggedIn) return navigate("/myduonuts");
      window.location = "/";
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      // setSuccessModalActive(true);
      setTimeout(() => {
        setSuccessModalActive(false);
      }, 2000);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    var barstatus = sessionStorage.getItem('bottomProBar');
    if (barstatus) {
      setbottomProBar(false);
    }
  }, []);

  const closeBottonBar = () => {
    setbottomProBar(false);
    sessionStorage.setItem("bottomProBar", true);
  }
  // console.log(userProAccess, userTrialDays)

  return (
    <>
      {userTrialDays > 0 && userTrialDays < 4 && (userProAccess === true && userAccountType === "free") && bottomProBar &&
        location.pathname !== "/" && !location.pathname.includes("/duonut/") && !location.pathname.includes("/survey/") && !location.pathname.includes("/home") && !location.pathname.includes("/aidata") && !location.pathname.includes("/duonutai") &&
        <div style={{ position: "fixed", bottom: "0", padding: "5px", background: "#3184c9", color: 'white', zIndex: "9999", width: "100%", textAlign: "center" }}>
          Your trial is expired in {userTrialDays} days. please upgrade to pro.
          <button style={{ padding: "8px 14px", marginLeft: "1rem", borderRadius: "5px", cursor: "pointer", fontWeight: "600" }}
            onClick={() => navigate('/payment/pro')} >Upgrade</button>
          <label onClick={() => closeBottonBar()}
            style={{ position: "absolute", right: "20px", top: '14px', cursor: "pointer" }}>
            <img style={{ width: '14px' }} src="/assets/icons/close-white.svg" /></label>
        </div>}
      {userTrialDays === 0 && !userProAccess && bottomProBar && isLoggedIn &&
        location.pathname !== "/" && !location.pathname.includes("/duonut/") && !location.pathname.includes("/survey/") && !location.pathname.includes("/home") && !location.pathname.includes("/aidata") && !location.pathname.includes("/duonutai") &&
        <div style={{ position: "fixed", bottom: "0", padding: "5px", background: "#3184c9", color: 'white', zIndex: "9999", width: "100%", textAlign: "center" }}>
          Your trial has ended. please upgrade to pro.
          <button style={{ padding: "8px 14px", marginLeft: "1rem", borderRadius: "5px", cursor: "pointer", fontWeight: "600" }}
            onClick={() => navigate('/payment/pro')} >Upgrade</button>
          <label onClick={() => closeBottonBar()}
            style={{ position: "absolute", right: "20px", top: '14px', cursor: "pointer" }}>
            <img style={{ width: '14px' }} src="/assets/icons/close-white.svg" /></label>
        </div>}
      <div
        className={((location.pathname).includes("/duonut/") || (location.pathname).includes("/survey/") || (location.pathname).includes("/aidata") || (location.pathname).includes("/home") ||(location.pathname).includes("/duonutai") || (location.pathname).includes("/pagemapping/")) ? "" : scrollPosition < 1 ? "navbar-container" : "navbar-container navbar-container-collapse"}
        id={(location.pathname === "/" || location.pathname === "/usergrowth") ? "" : "navbar-short"}
        style={((location.pathname).includes("/login") || (location.pathname).includes("/duonut/") || (location.pathname).includes("/survey/") || (location.pathname).includes("/aidata") || (location.pathname).includes("/home") ||(location.pathname).includes("/duonutai")  || (location.pathname).includes("/pagemapping/")) || ((location.pathname).includes("/design") && width < 800) ?
          { display: "none" } :
          {
            boxShadow: (location.pathname !== "/") || location.pathname.substring(0, 7) !== "/design" ? "0px 2px 10px rgba(61, 59, 59, 0.1)" : "",
            backgroundColor: width < 1100 ? (location.pathname.substring(0, 7) === "/design") ? "#c1bcbc" : "rgb(235, 236, 240)" : (location.pathname.substring(0, 7) === "/design") ? "#3184C9" : "white"
          }}
      >
        <div className="navbar__wrapper" id={location.pathname.substring(0, 7) === "/design" ? "navbar-short" : ""}>
          <div
            className="d-flex align-items-center navbar__wrapper__left navbar__wrapper__top"
            style={{ justifyContent: location.pathname.substring(0, 7) === "/design" && width <= 1275 ? "left" : "center" }}
          >
            {/* hamburger menu for mobile- hidden on pc */}

            <div className="navbar_wrapper__hamberger_menu icon-button" onClick={() => setNavMenuActive(true)} id={(location.pathname.substring(0, 7) === "/" || location.pathname === "/usergrowth") ? "" : "hidden"}>
              <img src="./assets/icons/hamburger-menu.svg" alt="duonut" loading="lazy" />
            </div>

            {location.pathname === "/" || location.pathname.substring(0, 7) === "/design" || location.pathname === "/login" ? (
              <></>
            ) : (
              <div className="icon-button mobile-only back-button" onClick={() => window.location = "/"}>
                <img src="/assets/icons/angle-left.svg" alt="duonut" loading="lazy" /> <label style={{ color: "black", paddingLeft: "10px" }}>Back</label>
              </div>
            )}

            <div className="navbar__logo" style={{
              display: 'flex',
              alignItems: 'center'
            }}>
              {location.pathname.substring(0, 7) === "/design" ?
                <svg onClick={() => handleNavigate("/myduonuts")} style={{ display: "flex", marginRight: "10px", cursor: "pointer" }} width="24" height="24" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" stroke="white" fill="none"><path d="M12.7715 24.8389L4.00001 16.0517L12.7715 7.2644" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path><path d="M28 16.0517L4.0001 16.0517" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                // <img src={width >= 1100 ? "/assets/logo4.png" : "./assets/logo-white.png"} style={width >= 1100 ? { marginRight: "0.8rem" } : {}} className={`${width <= 1275 ? "logo-small" : ""}`} id={width <= 1100 ? "hidden" : ""} alt="duonut" loading="lazy" />
                : <div style={{
                  margin: '0rem 2vw 0rem 0vw',
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center'
                }}>
                  {/* {location.pathname.substring(0, 15) !== "/viewworkflow/@" && location.pathname.substring(0, 14) !== "/viewworkflows" ? */}
                  <img onClick={() => handleNavigate("/")}
                    style={{ width: "auto", height: "2rem", cursor: "pointer", paddingRight: "1.5rem" }}
                    src={"/assets/duonut_icon.png"}
                    className={`${width <= 1275 ? "logo-small" : ""}`} id={width <= 1100 ? "hidden" : ""} alt="duonut"
                    loading="lazy" />
                  {/* : channelLogo !== "undefined" && channelLogo !== "null" ? <img
                      style={{ width: "auto", height: "2rem", paddingRight: "1.5rem" }}
                      src={`${backendUrl}${channelLogo}`}
                      className={`${width <= 1275 ? "logo-small" : ""}`} id={width <= 1100 ? "hidden" : ""} alt="duonut"
                      loading="lazy" />
                      : <img
                        style={{ width: "auto", height: "2.2rem", paddingRight: "1.5rem" }}
                        src={'/assets/user.png'}
                        className={`${width <= 1275 ? "logo-small" : ""}`} id={width <= 1100 ? "hidden" : ""} alt="duonut"
                        loading="lazy" />} */}
                  {/* {location.pathname.substring(0, 9) !== "/workflow" && location.pathname.substring(0, 9) !== "/workflow" && location.pathname.substring(0, 14) !== "/viewworkflows" ? */}
                  <img onClick={() => handleNavigate("/")}
                    style={{ width: "auto", height: "2rem", cursor: "pointer" }}
                    src={width >= 1100 ? "/assets/duonut_logo.png" : "./assets/logo-white.png"}
                    className={`${width <= 1275 ? "logo-small" : ""}`} id={width <= 1100 ? "hidden" : ""} alt="duonut"
                    loading="lazy" />
                  <p style={{
                    padding: '5px 10px',
                    borderRadius: '10px',
                    background: 'azure',
                    marginLeft: '10px',
                    fontSize: '12px'
                  }}>Beta</p>
                  {/* : <label style={{ fontSize: "1.6rem", fontWeight: "800" }}>{subDomain ? subDomain : ""}</label>} */}
                </div>}
            </div>

            <div className="input-box" onClick={() => setSearchItemsActive(true)} id={location.pathname === "/" || location.pathname.substring(0, 7) === "/design" ? "" : "hidden"}>
              {/* <input /> */}
              {/* <Searchbar iconColor="black" placeholder="Search any activity" /> */}
            </div>

            {location.pathname === "/" || location.pathname.substring(0, 7) === "/design" ? (
              <></>
            ) : (
              <div className="nav-header mobile-only">
                {/* {location.pathname === "/allduonuts" && "All Duonuts"}
                {location.pathname === "/myduonuts" && "My Duonuts"} */}
              </div>
            )}
            <NavItems />

            {/* for mobile */}
            {/* {
              width < 1100 &&
              <PublishButton className="btn-secondary  navbar__user_icon mobile-navbar" setError={setError} />
            } */}

            <div className="navbar__user_icon icon-button dot-menu" onClick={() => setDotMenuItemsActive(!dotMenuItemsActive)}>
              <img src="/assets/icons/dotMenu.svg" alt="duonut" loading="lazy" />
            </div>
          </div>

          <NavButtons width={width} setSuccessModalActive={setSuccessModalActive} handleNavigate={handleNavigate} handleClick={handleClick} />

        </div>
        <NavMenu setNavMenuActive={setNavMenuActive} navMenuActive={navMenuActive} />
        {/* {searchItemsActive && <SearchItems setSearchItemsActive={setSearchItemsActive} searchItemsActive={searchItemsActive} />} */}
        {dotMenuItemsActive && <DotMenuItems setDotMenuItemsActive={setDotMenuItemsActive} handleNavigate={handleNavigate} />}
      </div >
      {
        successModalActive && (
          <SuccessModal
            hideHeader={true}
            hideButtons={true}
            message={
              <>
                <img src="/assets/icons/tick.svg" alt="duonut" loading="lazy" />
                <p style={{ marginTop: "24px" }}>Login Successful</p>
              </>
            }
          />
        )
      }
    </>
  );
}
